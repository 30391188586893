import React,{useEffect ,useState} from 'react'
import style from './network.module.scss';
import { usePopperTooltip } from 'react-popper-tooltip';
import {ToolTips} from './'
import { BASE } from '../../../configuration/apiconfig';
import { useSelector} from 'react-redux';
import proPic from '../../../assets/images/nophoto/no_photo.jpg';
import up from '../../../assets/icons/up.png';
import down from '../../../assets/icons/down.png'
function SponsorTreeNode(props) {
    //check the children property
    const userName = useSelector(state=>state.ProjectConfig.userName);
    const hasChildren = (children) => {
        return children && children.length
    };

    const [isExpand,setIsExpand]= useState(false);
    const [rootLeg,setRootLeg] = useState('');
    const [level] = useState("3");
    const [lineWidth,setLineWidth] =useState(0);
    const [transform, setTranform] = useState(false);
    //tooltips
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
      } = usePopperTooltip();
      useEffect(()=>{
        if(props.depth=="1"){
            setRootLeg(props.position)
        }else{
            setRootLeg(prevState => ({
              ...prevState,
              rootLeg: props.rootLeg

            }))
            //setRootLeg(props.rootLeg)
        }
    //   },[props,level])
    },[props.depth,props.position,props.rootLeg,level,isExpand])
    //expand button click
    const showExpand = (data) =>{
      
        props.expand(
            data,
            setIsExpand,
            props.user_id,
            props.depthLevel,
            props.user_name,
        );
    }
    let line={
        line1 : 0,
        line2 : 0
    }
    // const getReference = (ref,index) =>{
    //     line[index] = ref.getBoundingClientRect().left;
    //     if(line.line1>0 && line.line2>0){
    //         const temp=Math.abs(line.line1-line.line2)
    //         setLineWidth(temp)
    //         line.line2=0
    //         line.line1=0
    //     }
    // }

    const getReference = (ref, index) => {
        line[index] = ref.getBoundingClientRect().left;
        if (line.line1 > 0 && line.line2 > 0) {
            
            if (line.line1 > line.line2) {
              
                setTranform(true)
            }
            if (line.line1 < line.line2) {
                setTranform(false)
            }
            const temp = Math.abs(line.line1 - line.line2)
            setLineWidth(temp)
            line.line2 = 0
            line.line1 = 0
        }
        else {

            
            if (line.line1 < 0 || line.line2 < 0) {
                const temp = Math.abs(line.line1 - line.line2)
                setLineWidth(temp)
                setTranform(true)
                line.line2 = 0
                line.line1 = 0
            }
        }
    }
    const recursiveComponent = (data)=>{
        return data.children.map((children,index)=>{
            return <td className={style.nodeContainer} colSpan="2" key={index}> 
                        <SponsorTreeNode
                            {...children}
                            expand={props.expand}
                            closeExpand={closeExpand}
                            expndLevel={data.expndLevel}
                            setSearchExpand={props.setSearchExpand}
                            rootLeg={rootLeg}
                            depthLevel={props.depthLevel}
                            callParent={props.callParent}
                            toolTipConfig={props.toolTipConfig}
                            setTriggerExpand={props.setTriggerExpand}
                        />
                    </td>
        })
    }
    //close expand 
    const closeExpand = () =>{
        setIsExpand(false)
        props.closeExpand(props.depthLevel) 
    }     
    
    const callParent = (id)=>{
        props.callParent(id)
        props.closeExpand(props.depthLevel) 
        setIsExpand(false)
        props.setSearchExpand(true)
    }
    return (
      <>
        {props.firstLevel && props.user_name != userName && (
          <div className={`${style.upIcon} ${style.positionRelative}`}>
            <img
              src={up}
              alt=""
              onClick={() => props.callParent(props.sponsor_id)}
            />
          </div>
        )}

        
        <table cellPadding={0} cellSpacing={0} className={style.mainTable}>
          <tbody>
            {!isExpand && (
              <tr className={style.rootNode}>
                <td colSpan={props.children ? props.children.length * 2 : 4}>
                  <div className={style.node}>
                    <img
                      src={props.photo ? props.photo :proPic}
                      alt=""
                      className={style.profileImage}
                      ref={setTriggerRef}
                      onDoubleClick={() => callParent(props.user_id)}
                    />
                    {visible && (
                      <div
                        ref={setTooltipRef}
                        {...getTooltipProps({
                          className: `tooltip-container ${style.tooltipContainer}`,
                        })}
                      >
                        <div
                          {...getArrowProps({ className: ` ${style.arrow}` })}
                        />
                        <ToolTips config={props.toolTipConfig} {...props} />
                      </div>
                    )}
                   { props.active == '1' ?
                  <p className={style.dmeoName}>{props.user_name}</p>
                  :
                  <p className={style.dmeoNameBlocked}>{props.user_name}</p>
                }
                    {props.depth == level && !props.expndLevel &&(
                      <div>
                        <img
                          src={down}
                          alt=""
                          className={style.downIcon}
                          onClick={() => {
                            showExpand(props);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            )}
            {props.depth != level && hasChildren(props.children) ? (
              <>
                <tr>
                  <td colSpan={props.children ? props.children.length * 2 : 4}>
                    <div className={`${style.line} ${style.down}`}></div>
                  </td>
                </tr>
                <tr>
                  {props.children && (
                    <>
                      <td className={`${style.line} ${style.left}`}>&nbsp;</td>
                      {props.children.map((items, index) => {
                        if (index < props.children.length - 1) {
                          return (
                            <React.Fragment key={index}>
                              <td
                                className={`${style.line} ${style.right} ${style.top}`}
                              >
                                &nbsp;
                              </td>
                              <td
                                className={`${style.line} ${style.left} ${style.top}`}
                              >
                                &nbsp;
                              </td>
                            </React.Fragment>
                          );
                        }
                        return true;
                      })}
                      <td className={`${style.line} ${style.right}`}>&nbsp;</td>
                    </>
                  )}
                </tr>
                <tr>
                  {hasChildren(props.children) && recursiveComponent(props)}
                </tr>
              </>
            ) : (
              [
                isExpand && (
                  <tr key={props.user_name}>
                    <td colSpan="4">
                      <div
                        className={`${style.line} ${style.left} ${style.expand}`}
                        ref={(el) => {
                          // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
                          if (!el) return;
                          getReference(el, "line1");
                        }}
                      ></div>
                      {props.rootLeg == "R" ? (
                            
                        <div
                          className={` ${style.right} ${style.top} ${style.expandRight}`}
                          style={{
                            width: lineWidth,
                            transform: 'none'
                
                        
                            
                          }}
                        ></div>
                      ) : (
                     
                        // <div className={` ${style.right} ${style.top} ${style.expandLeft}`}
                        //     style={{
                        //         width:lineWidth
                        //     }}
                        // ></div>
                        <div>
                          
                          <div
                            className={` ${style.right} ${style.top} ${style.expandLeft}`}
                            style={{
                              width: lineWidth,
                              transform: transform
                                ? `translateX(${100}%)`
                                : "none",
                            }}
                          ></div>
                        </div>
                      )}
                      <div
                        className={`${style.line} ${style.left} ${style.expandDown} `}
                        ref={(el) => {
                          // el can be null - see https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
                          if (!el) return;
                          getReference(el, "line2");
                        }}
                      ></div>
                      <div className={`${style.upIcon}`}>
                        <img
                          src={up}
                          alt=""
                          onClick={closeExpand}
                        />
                      </div>
                    </td>
                  </tr>
                ),
              ]
            )}
          </tbody>
        </table>
      </>
    );
}

export default SponsorTreeNode
