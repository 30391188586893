import React, { useEffect, useMemo, useState } from "react";
import style from "./tree.module.scss";
import { TitleHeaders } from "./";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import ReferralService from "../../../service/tree/referralCount";
import ReactPaginate from "react-paginate";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";
import Image from "../../../assets/images/nophoto/no_photo.jpg";

//data
import referralData from "./downlinedata.json";
import { MaterialReactTable } from "material-react-table";
import Reddot from "../../../assets/images/reddot.png";
import GreenDot from "../../../assets/images/greendot.png";
import { Box } from "@mui/material";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import _ from "lodash";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";
import DetailPanelContent from "./DetailPanelContent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export const TableView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);
  const [state, setState] = useState({
    level: "all",
    offset: 0,
    data: [],
    count: 0,
    totalLevels: [],
    showNext: true,
    loading: true,
    searchloader: false,
  });

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // useEffect(() => {
  //   const service = new ReferralService();

  //   service.getReferral(state.level, state.offset).then((res) => {
  //     if (res.status) {
  //       setState((prev) => ({
  //         ...prev,
  //         data: res.data.tableData,
  //         totalLevels: new Array(res.data.total_levels)
  //           .fill()
  //           .map((_, idx) => idx + 1),
  //         count: res.data.total_referral_count,
  //         loading: false,
  //         // level : res.data.total_levels
  //       }));
  //     } else {
  //       history.push("/login");
  //     }
  //   });
  // }, [state.level, state.offset, history]);
  //onreset button click
  const reset = () => {
    setState((prev) => ({
      ...prev,
      level: "",
      offset: 0,
    }));
  };
  //serch the level wise list
  const searchLevel = (level) => {
    setState((prev) => ({
      ...prev,
      searchloader: true,
    }));

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        level: level,
        searchloader: false,
      }));
    }, 3000);
  };
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: currentTheme=="theme-light"?"light":"dark", //let's use the same dark/light mode as the global theme
          // primary: currentTheme=="theme-light"?"light":"dark", //swap in the secondary color as the primary for the table
          info: {
            main: "#14243a", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default: currentTheme === "theme-dark" ? "#14243a99" : "#fff",
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [currentTheme]
  );
  useEffect(() => {
    const service = new ReferralService();

    const fetchData = async () => {
      const offset = pagination.pageIndex * pagination.pageSize;
      const limit = pagination.pageSize;
      const level = "all";
      const columnFilter = JSON.stringify(columnFilters) ?? "[]";
      const sortFilter = JSON.stringify(sorting) ?? "[]";
      service
        .getReferral(level, offset, limit, columnFilter, sortFilter)
        .then((res) => {
          setState((prev) => ({
            ...prev,
            data: res.data.result,
            count: res.data.count,
            loading: false,
            // level : res.data.total_levels
          }));
        });
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * 20);
    let showNext = true;
    if (offset > 0) {
      showNext = Math.floor(state.count / offset) === 1 ? false : true;
    }
    setState((prev) => ({
      ...prev,
      offset: offset,
      showNext: showNext,
    }));
  };

  const columns = useMemo(() => [
    {
      accessorKey: "customer_id", //access nested data with dot notation
      header: "ID",
      enableColumnFilter: false,

      // enableSorting:false,
      size: 150,
      Cell: ({ renderedCellValue, row }) => <p>{row?.original?.id}</p>,
    },
    {
      accessorKey: "partner_id", //normal accessorKey
      header: "PARTNER ID",
      size: 200,
    },

    {
      accessorKey: "first_name", //normal accessorKey
      header: "FIRST NAME",
      size: 200,
      Cell: ({ renderedCellValue, row }) => (
        <p>{row?.original?.["first_name"]}</p>
      ),
    },

    {
      accessorKey: "last_name", //normal accessorKey
      header: "LAST NAME",
      size: 200,
      Cell: ({ renderedCellValue, row }) => (
        <p>{row?.original?.["last_name"]}</p>
      ),
    },
    {
      accessorKey: "email", //normal accessorKey
      header: "EMAIL",
      size: 200,
      Cell: ({ renderedCellValue, row }) => <p>{row?.original?.["email"]}</p>,
    },
    {
      accessorKey: "service", //normal accessorKey
      header: "PRODUCT NAME",
      size: 200,
    },
    {
      accessorKey: "active_bv", //normal accessorKey
      header: "WEEKLY BV",
      size: 200,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: "date_of_joinings", //normal accessorKey
      header: "DATE",
      size: 200,

      enableSorting: false,
      filterVariant: "date-range",
      Cell: ({ renderedCellValue, row }) => (
        <p>
          {" "}
          {moment(row?.original?.["date_of_joinings"]).format(
            "MMM DD, YYYY hh:mm A"
          )}
        </p>
      ),
    },
    {
      accessorKey: "last_order_date", //normal accessorKey
      header: "LAST ORDERD DATE",
      size: 200,
      filterVariant: "date-range",

      Cell: ({ renderedCellValue, row }) => (
        <p>
          {row?.original?.["last_order_date"]
            ? moment(row?.original?.["last_order_date"]).format(
                "MMM DD, YYYY hh:mm A"
              )
            : "NA"}
        </p>
      ),
    },
  ]);
  const handleExportData = (type) => {
    if (type == "excel") {
      const worksheet = XLSX.utils.json_to_sheet(state.data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "FIRST NAME",
            "LAST NAME",
            "EMAIL",
            "PHONE",
            "ID",
            "DATE",
            "TOTAL BV",
            "PRODUCT NAME",
            "WEEKLY BV",
            "LAST ORDERD DATE",
            "PARTNER ID",
          ],
        ],
        { origin: "A1" }
      );
      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "DownlineCustomers.xlsx");
    } else {
      var copiedData = _.cloneDeep(state.data);

      const fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(copiedData);

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            "FIRST NAME",
            "LAST NAME",
            "EMAIL",
            "PHONE",
            "ID",
            "DATE",
            "TOTAL BV",
            "PRODUCT NAME",
            "WEEKLY BV",
            "LAST ORDERD DATE",
            "PARTNER ID",
          ],
        ],
        { origin: "A1" }
      );
      ws["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      const csvOutput = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, "DownlineCustomers" + fileExtension);
    }
  };

  return (
    <div className={style.ReferralMembers}>
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          {/* <TitleHeaders
            tilesvalue="referralDownline"
            count={state.count}
            level={state.totalLevels}
            searchLevel={searchLevel}
            reset={reset}
            searchloader={state.searchloader}
          /> */}
          <div>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                // enableHiding={false}
                columns={columns}
                data={state.data}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={state.count}
                manualPagination={true}
                manualFiltering={true}
                manualSorting={true}
                // enableExpandAll={false} //disable expand all button
                muiDetailPanelProps={() => ({
                  sx: (theme) => ({
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? "rgba(255,210,244,0.1)"
                        : "rgba(0,0,0,0.1)",
                  }),
                })}
                enableGlobalFilter={false}
                state={{
                  columnFilters,
                  globalFilter,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching,
                  sorting,
                  // expanded: true
                }}
                muiTableHeadCellColumnActionsButtonProps={{
                  sx: {
                    color: currentTheme === "theme-dark" ? "#fff " : "#14243a",
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    background:
                      currentTheme === "theme-dark" ? "#14243a" : "#fff80",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    color: currentTheme === "theme-dark" ? "#fff " : "#14243a",
                    borderBottom:
                      currentTheme === "theme-dark"
                        ? ".5px solid #47556560"
                        : "#00090",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    color: currentTheme === "theme-dark" ? "#fff " : "#14243a",
                  },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      p: "0.5rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className={style.exportButton}>
                      <Button
                        onClick={() => {
                          handleExportData("excel");
                        }}
                      >
                        <i className="fa fa-file-excel-o"></i>
                        Excel
                      </Button>

                      <Button
                        onClick={() => {
                          handleExportData("csv");
                        }}
                      >
                        <i className="fa fa-file-text-o"></i>
                        Csv
                      </Button>
                    </div>
                  </Box>
                )}
              />
            </ThemeProvider>
          </div>
        </>
      )}
    </div>
  );
};

const ReferralContent = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TableView />
  </LocalizationProvider>
);
export default ReferralContent;
