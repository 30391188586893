import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./ewallet.module.scss";
import { Filter } from ".";
import moment from "moment";
import Card from "react-bootstrap/Card";

import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import Table from "react-bootstrap/Table";
import { CommissionTiles } from ".";

function EarningsTable({
  ContentData,
  paginationchange,
  exportData,
  loader,
  filterDates,
  tableModalData,
  serachLoder,
}) {
  const { currentTheme } = useSelector((state) => state.Theme);

  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);

  const [state, setState] = useState({ data: [] });
  const [date, SetDate] = useState({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    selectedValues: [],
  });
  const [datas, setData] = useState();

  const { data } = state;

  const handlePerRowsChange = (perPage, inPage) => {
    let keys = [];
    date.selectedValues.map((key, idx) => {
      keys.push(key.value);
    });
    // let keys = date.selectedValues
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");
    paginationchange(inPage, perPage, date.start, date.end, "", "", keys);
  };

  const filterHandlet = (start, end, type = "") => {
    SetDate((prev) => ({
      ...prev,
      start: start,
      end: end,
      selectedValues: type,
    }));
    let keys = [];
    type.map((key, idx) => {
      keys.push(key.value);
    });

    // let keys = type
    //   .map((key, idx) => {
    //     return "categories[" + idx + "]=" + key.value;
    //   })
    //   .join("&");
    loader.EarningsTable.search = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      start,
      end,
      "",
      "",
      keys
    );
  };

  //reset handler
  const resetData = () => {
    SetDate((prev) => ({
      ...prev,
      start: moment().startOf("month").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    }));

    loader.EarningsTable.reset = true;

    paginationchange(
      ContentData.inPage,
      ContentData.perPage,
      moment().startOf("month").format("MMMM D, YYYY"),
      moment().format("MMMM D, YYYY"),
      "",
      "",
      ""
    );
  };
  const handleArrowClick = (index) => {
    setData(index);
  };

  const showArrow = (index) => {
    return (
      <i
        className={`${
          index == datas ? `fa fa-angle-down` : `fa fa-angle-right`
        }`}
        onClick={() => handleArrowClick(index)}
      ></i>
    );
  };

  const getToatalIncom = (data) => {
    const totalAmount = data.reduce((accumulator, currentItem) => {
      return accumulator + parseFloat(currentItem.amount);
    }, 0);

    return totalAmount;
  };

  let columns = [
    {
      name: "",
      selector: (row) => t("Common." + row),
    },
    {
      name: "",
      selector: (row) => "",
    },
    {
      name: "",
      selector: (row) => "",
    },
    {
      name: "",
      selector: (row) => "",
    },
    {
      name: "",
      selector: (row) => "",
    },
    {
      name: "",
      selector: (row) => {
        if (ContentData.data[row]) {
          let totalAmount = getToatalIncom(ContentData.data[row]);
          let multipliedAmount = totalAmount * Currency.value;
          let formattedAmount = CurrencyDecimalFormat(
            multipliedAmount,
            Currency.precision
          );
          return Currency.currentCurr + formattedAmount;
        }
        return null; // Adjust the return value if necessary
      },
    },
  ];
  let rowscolumns = [
    "#",
    "FROM USER",
    "RANK",
    "SOURCE",
    "%",
    "UNCAPPED AMOUNT",
    "EARNED",
  ];
  const renderAmount = (amount) => {
    
    if(amount){
return amount / 4;
    }
    return 0
    
  };
  const ExpandedComponent = ({ data }) => {
    console.log(data);
    return (
      <Table striped bordered hover>
        <thead>
          {rowscolumns
            .filter((col) =>
              data === "overridingBonus"
                ? col !== "UNCAPPED AMOUNT" && col !== "SOURCE" && col !== "%"
                : data != "unilevelMatchingBonus"
                ? col !== "UNCAPPED AMOUNT"
                : true
            )
            .map((col) => (
              <th key={col}>{col}</th>
            ))}
        </thead>
        {ContentData.data[data].map((arrObj, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{arrObj.from_id}</td>
            <td>{arrObj.rank_id}</td>
            {data != "overridingBonus" && (
              <>
                {data === "unilevelMatchingBonus" ? (
                  <td>
                    {arrObj.personal_pv}
                    <span>
                      {"(" + renderAmount(arrObj.personal_pv) + "*" + 4 + ")"}
                    </span>
                  </td>
                ) : (
                  <td>{arrObj.personal_pv}</td>
                )}
                <td>{arrObj.percentage}</td>
              </>
            )}
            {data === "unilevelMatchingBonus" && (
              <td>
                {Currency.currentCurr}

                {CurrencyDecimalFormat(
                  arrObj.earned_amount * Currency.value,
                  Currency.precision
                )}
              </td>
            )}

            <td>
              {Currency.currentCurr}

              {CurrencyDecimalFormat(
                arrObj.amount * Currency.value,
                Currency.precision
              )}
            </td>
          </tr>
        ))}
      </Table>
    );
  };
  return (
    <>
      <Card style={{ padding: "10px", border: "1px solid lightgrey" }}>
        <DataTable
          columns={columns}
          data={Object.keys(ContentData.data).map((ob) => ob)}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </Card>
    </>
  );
}

export default EarningsTable;
