import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import style from "./payment.module.scss";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { SafeChargeCC } from "./SafeCharge";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement("script"), {
      type: "text/javascript",
      defer: true,
      src,
      onerror: (e) => {
        reject(e);
      },
    });
    scriptElem.onload = () => {
      resolve();
    };
    document.body.appendChild(scriptElem);
  });

function Nuvei(props) {

  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [state, setState] = useState({ loader: false });
  const [safeCharge, setSafeCharge] = useState(null);

  useEffect(() => {
    loadScript(
      "https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js" //production
    ).then(() => {
      setSafeCharge(
       window.SafeCharge({
          env: process.env.REACT_APP_env,
          merchantId: process.env.REACT_APP_merchantID,
          merchantSiteId: process.env.REACT_APP_merchantSiteId, 
        })
      );
    });
  }, []);

  return (
    <div className={style.MianContent}>
      <div>
        <SafeChargeCC
          safeCharge={safeCharge}
          paymentData={props.paymentData}
          product={props.product}
          cardDetails={props.cardDetails}
          userDetails={props.userDetails}
        />
      </div>
      {/* </Form> */}
    </div>
  );
}

export default Nuvei;
