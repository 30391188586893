import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import { BASE, IMG_URL } from "../../../configuration/apiconfig";
import { CurrencyDecimalFormat } from "../../helper";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import Image from "../../../assets/images/nophoto/no_photo.jpg";
import Mail from "../../../assets/images/dashboard/mail.png";
import Tele from "../../../assets/images/dashboard/telephone.png";
import NewmembersModal from "./NewmembersModal";

import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Members({ memberList, lodervalue }) {
  const curr = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState("");

  const navigation = useHistory();

  const onclick = (e) => {
    setOpen(true);
    setId(e);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const contactMembers = (type, recipient) => {
    if (type == "email") {
      // const recipientEmail = "recipient@example.com";
      const subject = "Hello World";
      const body =
        "Hi there,\nI hope you are doing well.\n\nBest regards,\nYour Name";

      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      // Redirect the user to the email link
      window.location.href = mailtoLink;
    } else {
      window.location.href = `tel:${recipient}`;
    }
  };
  return (
    <div className={style.dashboard_new_member_sec}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? style.dark_mode_dashboard_pannel_box
            : style.dashboard_pannel_box
        }`}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className={`${
            currentTheme == "theme-dark"
              ? style.dark_mode_dashboard_new_member_head
              : style.dashboard_new_member_head
          }`}
        >
          {t("Dashboard.newMembers")}

          <button
            className={style.readmore_btn}
            style={{ background: "none" }}
            onClick={() => {
              navigation.push("/new-ibo-list");
            }}
          >
            <span>View all </span>
          </button>
        </div>

        <NewmembersModal show={open} onclose={handleClose} id={id} />

        <div>
          {memberList?.length === 0 ? (
            <div
              className={`${style.NodataImage}`}
              style={{ textAlign: "center" }}
            >
              <img src={nodata} alt="nodata" style={{ width: "50%" }} />
            </div>
          ) : (
            memberList?.map((item) => (
              <div className={style.dash_order} key={item.id}>
                <div className={style.iconorder}>
                  <img
                    src={Image}
                    alt="No Image"
                    style={{
                      width: "35px",
                      height: "35px",
                    }}
                    className={style.imgpointer}
                    onClick={() => {
                      onclick(item.user_name);
                    }}
                  />
                  {/* <i className="fa fa-user" aria-hidden="true" style={{ color: "#89CFF0" }}></i> */}
                </div>
                <div className={style.orderContent}>
                  <h3>{item.user_full_name}</h3>
                  <span>
                    {item.user_name}
                    {/* {moment(item.date_of_joining).format(
                    "MMMM DD, YYYY, hh:mm a"
                  )} */}
                  </span>{" "}
                  {item.user_type == "IBO" ? (
                    <p>{"(" + "IBI" + ")"}</p>
                  ) : (
                    <p>{"(" + item.user_type + ")"}</p>
                  )}
                </div>
                <div className={style.orderprice}>
                  <h3 style={{ color: "#006A4E" }}>
                    {" "}
                    {curr.currentCurr}
                    {CurrencyDecimalFormat(
                      item.product_amount * curr.value,
                      curr.precision
                    )}{" "}
                  </h3>
                  <span style={{ color: "#006A4E" }}>
                    {item.date_of_joining}
                  </span>
                </div>
                <div style={{ 
                  marginLeft: '10',
                  display:'flex',
                  gap:'5px',
                  marginLeft: 'auto',
                }}>
                  <span>
                    {/* <img src={Mail} alt=""
        style={{width:'30px',  
        height: '30px'}}
        ></img> */}
                  </span>

                  <span className={style.iconset}>
                    <i
                      class="fa fa-phone"
                      aria-hidden="true"
                      style={{ fontSize: 18 }}
                      onClick={() => {
                        contactMembers("phone", item.phone_number);
                      }}
                    ></i>
                  </span>

                  <div>
                    {/* <img src={Tele} alt=""
        style={{width:'40px',  
        height: '30px'}}
        ></img> */}

                    <span className={style.iconset}>
                      <i
                        class="fa fa-envelope-o"
                        aria-hidden="true"
                        style={{ fontSize: 18 }}
                        onClick={() => {
                          contactMembers("email", item.email);
                        }}
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        {/* <button
        style={{
          borderRadius: "0 0 20px 20px",
          color: currentTheme == "theme-dark" ? "#ffffff" : "#3B444B",

          width: "100%",
          height: "auto",
          float: "left",
          position: "absolute",
          left: "0",
          bottom: "0",
          borderRadius: "10px",
          padding: "5px",
          transition: "background 0.3s ease",
          cursor: "pointer",
          border: "none",
          background: currentTheme == "theme-dark" ? "#112237" : "#ffff",
          boxShadow: "0px 9px 21px rgba(0, 0, 0, 0.03)",
        }}
        onClick={() => {
          navigation.push("/my-customer-wall");
        }}
      >
        <i
          style={{ fontSize: 20 }}
          class="fa fa-angle-double-down"
          aria-hidden="true"
        ></i>
      </button> */}
      </div>
    </div>
  );
}
export default Members;
