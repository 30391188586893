import React, { useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "../../components/tree/tree.module.scss";
import { useSelector } from "react-redux";

function CommisionsTitle(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [level, setLevel] = useState("all");
  const [loaders, setLoaders] = useState({
    resetloader: false,
    searchloader: false,
  });

  //change level
  const changeLevel = (level) => {
    setLevel(level.target.value);
  };

  const reset = () => {
    setLoaders((prev) => ({
      ...prev,
      resetloader: true,
    }));
    setTimeout(() => {
      setLevel("all");
      props?.reset();
      setLoaders((prev) => ({
        ...prev,
        resetloader: false,
      }));
    }, 1000);
  };
  return (
    <Card className={style.titlepanel}>
      <Card.Body className={style.panelBody}>
        <Row>
        <Col md={6} sm={12}>
            <div
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.count} ${style.dark_mode_colorPurple}`
                  : `${style.count} ${style.colorPurple}`
              }`}
            >
              <div className={`${style.text} ${style.textPurple}`}>
                {t( props?.tilesvalue)}
              </div>
              
              <div className={`${style.textPurple} ${style.countText}`}>
              <span className={style.tooltiptext}>
              {props ? Math.abs(props?.count) > 999 ? Math.sign(props?.count)*((Math.abs(props?.count)/1000).toFixed(1)) + 'k' : Math.sign(props?.count)*Math.abs(props?.count): 0}
                </span>
                
              </div>
            </div>
            {props?.level && 
            
            <div className={`${
              currentTheme == "theme-dark"
                ? `${style.count} ${style.dark_mode_colorPurple}`
                : `${style.count} ${style.colorPurple}`
            }`}>
            <div className={`${style.text} ${style.textPurple}`}>
              {t("Tree.totalLevels")}
            </div>
            <div className={`${style.textPurple} ${style.countText}`}>
              {props ? props?.level?.length : 0}
              
            </div>
          </div>
            }
            
          </Col>
          {props.level && 
           <Col md={6} sm={12}>
           
           <div className={style.searchRight}>
             <div className={style.paddingSmall}>
               <Form.Group className={style.formGroup}>
                 <Form.Label>Time period</Form.Label>
                 <Form.Control
                   as="select"
                   className={style.dropDownSelect}
                   onChange={changeLevel}
                   value={level}
                 >
                   <option value="all">{t("Common.all")}</option>
                   {props &&
                     props?.level?.map((items) => (
                       <option value={items} key={items}>
                         {items}
                       </option>
                     ))}
                 </Form.Control>
               </Form.Group>
             </div>
             <div className={style.paddingSmall}>
               <Form.Group className={style.formGroup}>
                 <Button
                   className={`${style.Button} ${style.BtnPrimary}`}
                   disabled={props?.searchloader}
                   onClick={() => props?.searchLevel(level)}
                 >
                   {props?.searchloader ? (
                     <i
                       className="fa fa-refresh fa-spin"
                       style={{ marginRight: "5px" }}
                     />
                   ) : (
                     ""
                   )}
                   {t("Button.search")}
                 </Button>
                 <Button
                   className={`${style.Button} ${style.BtnInfo}`}
                   disabled={loaders.resetloader}
                   onClick={reset}
                 >
                   {loaders?.resetloader ? (
                     <i
                       className="fa fa-refresh fa-spin"
                       style={{ marginRight: "5px" }}
                     />
                   ) : (
                     ""
                   )}

                   {t("Button.reset")}
                 </Button>
               </Form.Group>
             </div>
           </div>
         </Col>
          
          }
         
        </Row>
      </Card.Body>
    </Card>
  );
}

export default CommisionsTitle;
