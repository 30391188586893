import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
// import { withRouter } from 'react-router';
// import Language from "../components/common/Language";
// import Currency from "../components/common/Currency";

import style from "../../../shared/commonstyle.module.scss";
import { connect } from "react-redux";
// import logo from "../../assets/images/dashboard/logo.svg";
import Logo from '../../../../assets/images/logos/NEX-logo-wht.svg';
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import profilePic from "../../../../assets/images/nophoto/no_photo.jpg";
//notification materialUI
// import NewspaperIcon from '@mui/icons-material/Newspaper';
// import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
// import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
// import PaymentIcon from '@mui/icons-material/Payment';

// import NavNotification from './NavNotification';
//custum lang dropsown lang button
const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ cursor: "pointer" }}
  >
    {/* Render custom icon here */}
    {children}
  </span>
));
function mapStateToProps(state) {
  const { Mail } = state;
  const { Notification } = state;
  const { Menu } = state;

  return { Mail: Mail, Notification: Notification, Menu: Menu };
}
const MainNavbar = (props) => {
  
 return (
    <div className={style.main_contant_sec}>
     
      <div className={style.topbar_section}>
     
  
        <div className={style.logo_sec}>
        <div className={style.logo_cn}>
              <img style={{width : "173%"}}src={Logo} alt=""></img>
        </div>

     </div>
      </div>
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(MainNavbar))
);
