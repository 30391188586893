import React, { useEffect, useMemo, useState } from "react";
import style from "./tree.module.scss";
import { TitleHeaders } from "./";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import DownLineService from "../../../service/tree/downLine";
import ReactPaginate from "react-paginate";
import Spinner from "../../shared/Spinner";
import Image from "../../../assets/images/nophoto/no_photo.jpg";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";

//data
import downlineData from "./downlinedata.json";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import Reddot from "../../../assets/images/reddot.png";
import GreenDot from "../../../assets/images/greendot.png";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import Filter from "../../view/Datatables/Filter";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export const TableView = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);

  const [state, setState] = useState({
    level: "all",
    offset: 0,
    data: [],
    count: 0,
    totalLevels: [],
    showNext: true,
    loading: true,
    searchloader: false,
    page_count: 0,
  });

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const service = new DownLineService();
  const [type, setType] = useState({
    value: "customer",
    label: "Cutsomer",
  });
  const [date, setDate] = useState({
    start: moment().startOf("month"),
    end: moment(),
  });

  const submitHandler = () => {
    fetchData();
  };

  const setDateOptions = (start, end) => {
    setDate((prev) => ({
      ...prev,
      start: start,
      end: end,
    }));
  };
  const resetHandler = (start_date, end_date) => {};
  const options = [
    { value: "user", label: "IBI" },
    { value: "customer", label: "Customer" },
  ];

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: currentTheme=="theme-light"?"light":"dark", //let's use the same dark/light mode as the global theme
          // primary: currentTheme=="theme-light"?"light":"dark", //swap in the secondary color as the primary for the table
          info: {
            main: "#14243a", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default: currentTheme === "theme-dark" ? "#14243a99" : "#fff",
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [currentTheme]
  );
  const fetchData = async () => {
    const offset = pagination.pageIndex * pagination.pageSize;
    const limit = pagination.pageSize;
    const level = "all";
    const columnFilter = JSON.stringify(columnFilters) ?? [];
    const sortFilter = JSON.stringify(sorting) ?? [];

    console.log(sortFilter);
    //,date.start, date.end
    service
      .getDownline(level, offset, limit, columnFilter, sortFilter)
      .then((res) => {
        // setUserData(res.data)
        setState((prev) => ({
          ...prev,
          data: res.data.tableData,
          totalLevels: new Array(res.data.total_levels)
            .fill()
            .map((_, idx) => idx + 1),
          count: res.data.total_downline_count,
          loading: false,
          // level : res.data.total_levels
        }));
      });
  };
  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const goToPreviousPath = () => {
    history.goBack();
  };

  //onreset button click
  const reset = () => {
    setState((prev) => ({
      ...prev,
      level: "",
      offset: 0,
    }));
  };
  //serch the level wise list
  const searchLevel = (level) => {
    setState((prev) => ({
      ...prev,
      searchloader: true,
    }));

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        level: level,
        searchloader: false,
      }));
    }, 1000);
  };
  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * 20);
    let showNext = true;
    if (offset > 0) {
      showNext = Math.floor(state.count / offset) === 1 ? false : true;
    }
    setState((prev) => ({
      ...prev,
      offset: offset,
      showNext: showNext,
    }));
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "full_name", //access nested data with dot notation
        header: "MEMBER NAME",
        size: 300,
        enableSorting: false,

        Cell: ({ renderedCellValue, row }) => (
          <>
            <div
              style={{
                display: "grid",
                msGridColumns: "35px 1fr",
                gridTemplateColumns: "35px 1fr",
                WebkitBoxAlign: "center",
                msFlexAlign: "center",
                alignItems: "center",
                gridGap: "10px",
              }}
            >
              <div>
                <img
                  src={row.original.active == 1 ? GreenDot : Reddot}
                  style={{
                    position: "absolute",
                    zIndex: 999,
                    width: 11,
                    height: 11,
                  }}
                />
                <img
                  src={
                    row.original.user_photo ? row.original.user_photo : Image
                  }
                  alt="No Image"
                  style={{ width: "35px", height: "35px", borderRadius: "40%" }}
                />
              </div>
              <div>
                <div className={style.userName}>{row.original.full_name}</div>
                <span className={style.userid}>{row.original.username}</span>
              </div>
            </div>
          </>
        ),
      },
      {
        accessorKey: "username",
        header: "USER NAME",
        size: 150,
      },
      {
        accessorKey: "placement",
        header: "PLACEMENT",
        size: 150,
      },
      {
        accessorKey: "sponsor",
        header: "SPONSOR",
        size: 200,
      },
      {
        accessorKey: "current_package",
        header: "SERVICE",
        size: 150,
      },


     
      {
        accessorFn: (originalRow) => new Date(originalRow.date_of_joining),
        accessorKey: "date_of_joining",
        header: "DATE OF JOINING",
        size: 150,
        filterVariant: "date-range",
        Cell: ({ renderedCellValue, row }) => (
          <p>
            {" "}
            {moment(row?.original?.date_of_joining).format(
              "MMM DD, YYYY hh:mm A"
            )}
          </p>
        ),
      },
      {
        accessorKey: "ref_level",
        header: "LEVEL",
        size: 150,
      },
      {
        accessorKey: "action",
        header: "ACTION",
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Link
              to={`/genealogyTree/${row?.original?.username}`}
              style={{
                backgroundColor: "transparent",
                color: "black",
                padding: "1px 5px",
                fontSize: "12px",
                lineHeight: 1.5,
              }}
              title="Click to view genealogy tree"
            >
              <div
                style={{
                  Width: 224,
                  Padding: 0,
                  position: "relative",
                  display: "inline-block",
                }}
                className={style.tooltip}
              >
                <i className="fa fa-sitemap">
                  <span
                    style={{
                      visibility: "hidden",
                      width: "120px",
                      backgroundColor: "black",
                      color: "#fff",
                      textAlign: "center",
                      borderRadius: "6px",
                      fontFamily: "poppinslight",
                      fontSize: "small",
                      padding: "5px 0",
                      position: "absolute",
                      zIndex: "1",
                      top: "17px",
                      right: "-51%",
                    }}
                  >
                    {t("Common.viewgene")}
                  </span>
                </i>
              </div>
            </Link>
          </>
        ),
      },
    ],
    []
  );

  const handleExportData = (type) => {
    if (type == "excel") {
      let excelData = [];
      state.data.map((obj) => {
        excelData.push({
          full_name: obj.full_name,
          username: obj.username,
          placement: obj.placement,
          sponsor: obj.sponsor,
          current_package: obj.current_package,
          date_of_joining: obj.date_of_joining,
          ref_level: obj.ref_level,
        });
      });
      const headerStyle = {
        font: { bold: true, color: { rgb: "FFFFFF" } }, // Font color white
        fill: { fgColor: { rgb: "3498db" } }, // Background color light blue
      };

      const worksheet = XLSX.utils.json_to_sheet(excelData);
      worksheet["!cols"] = [
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
        { wch: 10 },
      ]; // Adjust column widths as needed
      worksheet["!rows"] = [
        { hpx: 10 }, // Set the height of the first row to 30 pixels
        { hpx: 10 }, // Set the height of the second row to 25 pixels
        { hpx: 10 }, // Set the height of the third row to 20 pixels
        { hpx: 10 }, // Set the height of the first row to 30 pixels
        { hpx: 10 }, // Set the height of the second row to 25 pixels
        { hpx: 10 }, // Set the height of the third row to 20 pixels
      ];

      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "Downline_members.xlsx");
    } else {
      var copiedData = _.cloneDeep(state.data);

      const fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(copiedData);
      XLSX.utils.sheet_add_aoa(ws, [
        [
          "MEMBER NAME",
          "USER NAME",
          "PLACEMENT",
          "SPONSOR",
          "SERVICE",
          "DATE",
          "LEVEL",
        ],
      ]);
      const csvOutput = XLSX.utils.sheet_to_csv(ws, [
        "MEMBER NAME",
        "USER NAME",
        "PLACEMENT",
        "SPONSOR",
        "SERVICE",
        "DATE",
        "LEVEL",
      ]);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, "Downlinemembers" + fileExtension);
    }
  };

  return (
    <div className={style.DownlineMembers}>
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          {history.location.state &&
          history.location.state.prevPath == "/genealogyTree" ? (
            <button className={style.back_btn} onClick={goToPreviousPath}>
              Back
              <i className=" btn_icon fa fa-backward"></i>
            </button>
          ) : (
            <></>
          )}

          <TitleHeaders
            tilesvalue="downlineMembers"
            count={state.count}
            level={state.totalLevels}
            searchLevel={searchLevel}
            reset={reset}
            searchloader={state.searchloader}
          />
          <div className="table-container">
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                // layoutMode="grid"
                columns={columns}
                data={state.data}
                enableHiding={false}
                manualPagination={true}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                manualFiltering={true}
                manualSorting={true}
                rowCount={state.count}
                enableGlobalFilter={false}
                style={{}}
                state={{
                  columnFilters,
                  globalFilter,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  showProgressBars: isRefetching,
                  sorting,
                }}
                muiTableHeadCellColumnActionsButtonProps={{
                  sx: {
                    color: currentTheme === "theme-dark" ? "#fff " : "#14243a",
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    background:
                      currentTheme === "theme-dark" ? "#14243a" : "#fff80",
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    color: currentTheme === "theme-dark" ? "#fff " : "#14243a",
                    borderBottom:
                      currentTheme === "theme-dark"
                        ? ".5px solid #47556560"
                        : "#00090",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    color: currentTheme === "theme-dark" ? "#fff " : "#14243a",
                  },
                }}
                renderTopToolbarCustomActions={({ table }) => (
                  <>
                    <div style={{ width: "100%", gap: 10, padding: "-3px" }}>
                      <Row>
                        <Col md={8} sm={12}>
                          <div
                            style={{
                              padding: 10,
                            }}
                          >
                            {/* <Filter
                                  onChange={(entry) => {
                                    setType(entry);
                                  }}
                                  selectOptions={options}
                                  fetchData={fetchData}
                                  filterHandler={submitHandler}
                                  setDateOptions={setDateOptions}
                                  resetHandler={resetHandler}
                                  type={type}
                                /> */}
                          </div>
                        </Col>

                        <Col md={4} sm={12}>
                          <div
                            className={style.exportButton}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Button
                              onClick={() => {
                                handleExportData("excel");
                              }}
                            >
                              <i className="fa fa-file-excel-o"></i>
                              Excel
                            </Button>

                            <Button
                              onClick={() => {
                                handleExportData("csv");
                              }}
                            >
                              <i className="fa fa-file-text-o"></i>
                              Csv
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <div style={{ padding: 15, gap: 10 }}></div>

                      {/* </Box> */}
                    </div>
                  </>
                )}
              />
            </ThemeProvider>
          </div>
        </>
      )}
    </div>
  );
};

const DownLineContent = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TableView />
  </LocalizationProvider>
);

export default DownLineContent;
