import React, { useEffect, useMemo, useState } from "react";
import style from "./Commisions.module.scss";


import _ from "lodash";
import CommisionsTitle from "./CommisionsTitle";
import { useSelector } from "react-redux";
import { MaterialReactTable } from "material-react-table";
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const CommisionContent = () => {
  const { currentTheme } = useSelector((state) => state.Theme);
const [hide,setHide]=useState(false)
    const searchLevel = (level) => {
        // setState((prev) => ({
        //   ...prev,
        //   searchloader: true,
        // }));
    
        // setTimeout(() => {
        //   setState((prev) => ({
        //     ...prev,
        //     level: level,
        //     searchloader: false,
        //   }));
        // }, 1000);
      };
const lev=[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
const user=[
  {active:1},
  {active:1},
  {active:1},
  {active:1},

  {active:1},
  {active:1},
  {active:1},
 
]

const columns = useMemo(
  () => [
    {
      accessorKey: 'from_id', //normal accessorKey
      header: ' From Id',
      size: 100,
    },
    {
      accessorKey: 'from', //access nested data with dot notation
      header: 'from',
      size: 100,
    },
    {
      accessorKey: 'level',
      header: 'Level',
      size: 150,
    },
    
    {
      accessorKey: 'paidlevel',
      header: 'Paid Level',
      size: 150,
    },
    {
      accessorKey: 'source',
      header: 'source',
      size: 150,
    },
    {
      accessorKey: 'percentage',
      header: 'Percentage',
      size: 200,
    },
    
    {
      accessorKey: 'earned',
      header: 'Earned',
      size: 150,
    },

  
 
    
 
   
 
  ],
  [],
);
  return (
    <div style={{padding: 15,
        paddingTop: 35}}>
     
        <>
       

          <CommisionsTitle
            tilesvalue="Life time earnings"
            count="67867"
            level={lev}
            // searchLevel={searchLevel}
            // reset={reset}
            // searchloader={state.searchloader}
          />
<div className={`${
             currentTheme == "theme-dark"
               ? `${style.subcontainercarddark}`
               : `${style.subcontainercard}`
           }`}>

            <h4>October 2021 Commissions</h4>

            <div className={style.commisiongrid}>
              <div>
               <h2 style={{color:"#17a2b8"}}>$1500</h2>
               Qualified as: STL
              </div>
              <div>
            <h5 className={style.commisionhead}>  Previous Balance</h5> 
<h6>£0.00</h6>
<h6 className={style.commisionhead}>Earnings</h6>
<h6>£1,009.39</h6>
<h6 className={style.commisionhead}>Fee</h6>
<h6>£0.00</h6>
<h6 className={style.commisionhead}>Total</h6>
<h6>£1,009.39</h6>
<h6 className={style.commisionhead}>Balance Forwarded</h6>
<h6>£0.00</h6>
              </div>
              <div>
           <h5 className={style.commisionhead}> My Personal Business Volume</h5>  
			  <i class="fa fa-times" aria-hidden="true" style={{color:"#AA0000"}}></i> 

<h5 className={style.commisionhead}>Volume 2</h5>
0
<h5 className={style.commisionhead}>My Total Downline Volume</h5>
5,065
              </div>


            </div>

          
          
            <div style={{margin:10}}>
            <TableContainer
              component={Paper}
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_tabContent}`
                  : `${style.tabContent}`
              }`}
            >
              <Table
                // sx={{ minWidth: 600, opacity: isTabChanging ? 0.5 : 1 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                     From Id
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                     from
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                     From
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                     Level
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Paidlevel
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Source
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Percentage
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Earned
                    </TableCell>
                  </TableRow>
                </TableHead>
                
                <TableHead>
                  <TableRow style={{margin:10,width:200}}>
                  <TableCell
                          align="left"
                          style={{ border: "none"}}
                        >
                          {!hide ? <i class="fa fa-chevron-right" aria-hidden="true" onClick={()=>{
setHide(!hide)      }}></i>:
<i class="fa fa-angle-down" aria-hidden="true" onClick={()=>{
setHide(!hide)      }}></i>
}
                          
                         


                        Bonus: Overriding Residual Income </TableCell>
                  </TableRow>

                  {
                    hide &&
<>
<TableRow>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                         hei </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                     ege     </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>

                  </TableRow>
                  <TableRow>
            <TableCell rowSpan={3} />
            <TableCell rowSpan={3} />
            <TableCell rowSpan={3} />
            <TableCell rowSpan={3} />
            <TableCell rowSpan={1} />
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell align="right">$1999</TableCell>
          </TableRow>
</>
                  }
                
          {/* <TableRow>
            <TableCell>Tax</TableCell>
            <TableCell align="right">ja</TableCell>
            <TableCell align="right">aaaa</TableCell>
          </TableRow> */}
                 
                </TableHead>

                <TableHead>
                  <TableRow style={{margin:10,width:200}}>
                  <TableCell
                          align="left"
                          style={{ border: "none"}}
                        >
                        {!hide ? <i class="fa fa-chevron-right" aria-hidden="true" onClick={()=>{
setHide(!hide)      }}></i>:
<i class="fa fa-angle-down" aria-hidden="true" onClick={()=>{
setHide(!hide)      }}></i>}


                          Bonus: Personal Residual Income </TableCell>
                  </TableRow>
                  {hide &&
                  <><TableRow>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                         hei </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                     ege     </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>
                  <TableCell
                          align="center"
                          // style={{ border: "none", width: "15%" }}
                        >
                      zcc    </TableCell>

                  </TableRow>
                  <TableRow>
            <TableCell rowSpan={3} />
            <TableCell rowSpan={3} />
            <TableCell rowSpan={3} />
            <TableCell rowSpan={3} />
            <TableCell rowSpan={1} />
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell align="right">$1999</TableCell>
          </TableRow></>
                  }
                  
          {/* <TableRow>
            <TableCell>Tax</TableCell>
            <TableCell align="right">ja</TableCell>
            <TableCell align="right">aaaa</TableCell>
          </TableRow> */}
                 
                </TableHead>

              
              </Table>
            </TableContainer>
</div>
           </div>


          
         
        </>
    
    </div>
  );
};

export default CommisionContent;
