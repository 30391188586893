import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import HistoryContent from "../../components/orderHistory/historyContent";
import OrderService from "../../../service/orderHistory/orderHistory";
import companyName from "../../../store/mobxStore/companyName";
import { useHistory, withRouter } from "react-router";

function OrderHistory({ t }) {
  let service = new OrderService();
  let history = useHistory();
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [state, setState] = useState({
    loading: false,
    data: [],
  });

  // useEffect(() => {
  //   service.getOrderHistory().then((res) => {
  //     if (res.status) {
  //       setState({
  //         loading: false,
  //         data: res.data.orders,
  //       });
  //     } else {
  //       if (res.error.code === 1002) {
  //         history.push("/logout");
  //       } else if (res.error.code === 1057) {
  //         history.push({
  //           pathname: "/dashboard",
  //           state: {
  //             error: true,
  //             message: "permissionDenied",
  //           },
  //         });
  //       }
  //     }
  //   });
  // }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (state.loading) {
    return <Spinner />;
  }
  return (
    <React.Suspense fallback={<Spinner />}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.orderHistory")}
        </title>
      </Helmet>
      <PageTitle title="orderHistory" buttonOn={false} />
      <HistoryContent
        // {...state}
        // handleChangePage={handleChangePage}
        // rowsPerPage={rowsPerPage}
        // handleChangeRowsPerPage={handleChangeRowsPerPage}
        // page={page}
      />
    </React.Suspense>
  );
}
export default withTranslation()(withRouter(OrderHistory));
