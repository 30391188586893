import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../../components/common/pageTitle';
import RegisterInvoiceContent from '../../../components/registration/RegisterInvoiceContent';
import RegisterService from '../../../../service/Auth/Register'
import companyName from '../../../../store/mobxStore/companyName';

function RegisterInvoice(props) {

    
    let service = new RegisterService();

    const [state, setState] = useState({
        alert: {
            show: false,
            message: '',
            variant: ''
        }

    })
    const buttonGroups = [
    ];
    useEffect(() => {

        const { id } = props.match.params;
        const { state } = props.location;
           
    
        service.getInvoice(id).then(res => {
            if (res.status) {
                if (state) {
                    setState(prevState => ({
                        ...prevState,
                        alert: {
                            show: true,
                            message: 'validation.purchaseCompleatedSuccessfully',
                            variant: 'success',
                            field: {
                                number: res.data.invoice_no
                            }
                        }
                    }))
                }
                const productDetials = res.data.product_details.map((list) => {
                    return {
                        name: list.product_name,
                        qty: list.quantity,
                        price: list.amount
                    }
                })
                setState(prevState => ({
                    ...prevState,
                    ...res.data,
                    product_details: productDetials,
                    
                }))
            } else {
                if (res.error.code === 1002) {
                
                    props.history.push('/logout');
                } else if (res.error.code === 1057) {
                    props.history.push({
                        pathname: '/dashboard',
                        state: {
                            error: true,
                            message: 'permissionDenied'
                        }
                    })
                } else {
                    props.history.push('/repurchaseReport');
                }
            }
        })


    }, []);
    const {t}= props;
    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.invoice')}</title>
            </Helmet>
            <PageTitle title="invoice" buttonOn={false} group={buttonGroups}/>
            <RegisterInvoiceContent
                {...state}
            />
        </div>
    )




}
export default  withTranslation()(withRouter (RegisterInvoice))