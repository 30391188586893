import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";

export default class DownLineService {
  key = localStorage.getItem("apiKey");
  //get the downline members
  async getDownline(
    level = "all",
    offset = 0,
    limit = 20,
    cfilter = "",
    sortFilter = ""
  ) {
    const response = await fetch(
      BASE_URL +
        "tree/downline_members?level=" +
        level +
        "&offset=" +
        offset +
        "&limit=" +
        limit +
        "&cfilter=" +
        cfilter +
        "&sortFilter=" +
        sortFilter,

      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const legStatus = await response.json();
    return legStatus;
  }
}
