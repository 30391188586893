import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function CircularProgressBar(props) {
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(()=>{
    setCurrentPercentage(props.percentage)
  },[props.percentage])

  useEffect(() => {
    const animateProgress = () => {
      if (currentPercentage < props.percentage) {
        setCurrentPercentage((prevPercentage) =>
          Math.min(prevPercentage + 1, props.percentage)
        );
      }
    };

    const intervalId = setInterval(animateProgress, 30);

    return () => clearInterval(intervalId);
  }, [props.percentage, currentPercentage]);

  return (
    <div style={{textAlign:"center"}}>
      <div style={{ width: '60%'}}>
      <CircularProgressbar
          value={currentPercentage}
          text={`${currentPercentage}%`}
        />
      </div>
    </div>
  );
}
export default CircularProgressBar;