import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import TreeViewService from "../../../service/tree/treeView";
import { withRouter } from "react-router-dom";
import companyName from "../../../store/mobxStore/companyName";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";
import TreeviewContent from "../../components/tree/TreeviewContent";

const TreeView = (props) => {
  const usernaamee = useSelector((state) => state.ProjectConfig.userName);
  const [state, setState] = useState({
    username: usernaamee,
    data: [],
    tooltip_config: {},
    tooltip_array: [],
    loading: true,
  });
  const service = new TreeViewService();

  useEffect(() => {
    getlist();
  }, []);

  const getlist = (username = "") => {
    service.getTreeViewData(username).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          data: res.data.data,
          tooltip_config: res.data.tooltip_config,
          tooltip_array: res.data.tooltip_array,
          loading: false,
        }));
      } else {
        if (res.error.code === 1002) {
          props.history.push("/mobileTreeView");
        } else if (res.error.code === 1043) {
        }
      }
    });
  };

  const { t } = props;

  return (
    <div className="h-100">
      {/* <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.treeView')}</title>
            </Helmet>
            <PageTitle
                title="treeView"
                buttonOn={false}
            /> */}
      {state.loading ? (
        <Spinner />
      ) : (
        <TreeviewContent {...state} userName={usernaamee} />
      )}
    </div>
  );
};

export default withTranslation()(withRouter(TreeView));
