import {BASE_URL,DEFAULT_KEY} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';



export default class CrmService{
    key =localStorage.getItem('apiKey')
    // crm tiles 
    async Tiles(){
        const response = await fetch(BASE_URL+'crm/crmTile', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
    async Followup(){
        const response = await fetch(BASE_URL+'crm/followUp',{
            method:'GET',
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();

    }
    async  ViewLead(params){
        const url = new URL(`${BASE_URL}crm/viewLeads`);
        url.search = new URLSearchParams(params).toString();
        const response = await fetch(url,{
            method:'GET',
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();

    }
    async AddLead(form_data){
        // const url = new URL(`${BASE_URL}crm/addLeads`);
        const response = await fetch(`${BASE_URL}crm/addLeads`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: JSON.stringify(form_data)
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
    async EditLead(edit_data,id){

        // const url = new URL(`${BASE_URL}crm/addLeads`);
        const response = await fetch(`${BASE_URL}crm/editLeads/${id}`, {     //url
            method: 'PUT',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: JSON.stringify(edit_data)
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    } 
    async TimeLine(id){
        const response = await fetch(`${BASE_URL}crm/time_line?id=${id}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            }
        })
        return await response.json();
    }
    async AddFollowup(form_data)
    {
        const response = await fetch(`${BASE_URL}crm/addFollowup`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: form_data
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    } 
    
    async AddNextFollowup(form_data)
    {
        const response = await fetch(`${BASE_URL}crm/addNextFollowup`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                 'Accept'        : 'application/json',
                 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key 
            },
            body: JSON.stringify(form_data)
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    } 
    async  CrmGraph(){
        const url = new URL(`${BASE_URL}crm/graph`);
        const response = await fetch(url,{
            method:'GET',
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();

    }
    async getApi(admin){
        const response = await fetch(`${BASE_URL}common/api_key?admin_user_name=${admin}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : DEFAULT_KEY,
            }
        })
        const result = await response.json();
        return result;
    }
}