import React from 'react';
import { useEffect, useState } from 'react'
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router';
import SponsorTreeContent from '../../components/network/SponsorTreeContent';
import GenealogyService from '../../../service/tree/genealogy';
import companyName from '../../../store/mobxStore/companyName';






const SponsorTree = (props) => {

    const [state, setState] = useState({
        loading: true,
        data: [],
        tooltipConfig: {},
        searchloader:false,

    })

    const service = new GenealogyService();


useEffect(() => {
    //get the referal member action
    const { userName } = props.match.params;
    getlist(userName)
}, [])

//get the user data onload

const getlist = (username = '', userId = '',loader='') => {
    service.getSponsorTree(username, userId).then(res => {
        if (res.status) {
            setState(prevstate => ({
                ...prevstate,
                loading: false,
                data: res.data.TreeData,
                tooltipConfig: res.data.tooltip_config
            }))
        } else {
            if (res.error.code === 1002) {
                props.history.push('/logout')
            } else if (res.error.code === 1043) {
                props.history.push({
                    pathname: '/sponsorTree',
                    state: {
                        error: true,
                        message: 'InvalidUserName'
                    }
                })
            }
        }
    })

}


//filer the tree
const fileterUser = (username,id,loader) => {
    getlist(username,id,loader)
}


const resetButtonClick = () => {
    getlist()
}
const parentNode = (parentId) => {
    getlist('', parentId);
}

const { t } = props;


return (
    <div>
        {/* {state.loading ?
            <Spinner />
        : */}
        <React.Suspense fallback={<Spinner />}>
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.sponsorTree')}</title>
            </Helmet>
            <PageTitle title="sponsorTree" buttonOn={false} />
            <SponsorTreeContent
                fileterUser={fileterUser}
                resetButtonClick={resetButtonClick}
                parentNode={parentNode}
                {...state}
            />
        </React.Suspense>
{/* } */}
    </div>
)

}

export default withTranslation()((withRouter(SponsorTree)))