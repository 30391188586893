import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import _ from "lodash";
import { Button } from "react-bootstrap";
import TreeViewService from "../../../service/tree/treeView";
import { PageTitle } from "../../components/common";
import { TitleHeaders } from "../../components/tree";
import companyName from "../../../store/mobxStore/companyName";
import style from "../../components/tree/tree.module.scss";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const TableView = () => {
  const { t } = useTranslation();
  const curr = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new TreeViewService();
  const [userData, setUserData] = useState();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: currentTheme=="theme-light"?"light":"dark", //let's use the same dark/light mode as the global theme
          // primary: currentTheme=="theme-light"?"light":"dark", //swap in the secondary color as the primary for the table
          info: {
            main: "#14243a", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default: currentTheme === "theme-dark" ? "#14243a99" : "#fff",
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [currentTheme]
  );

  useEffect(() => {
    const fetchData = async () => {
      const start = pagination.pageIndex * pagination.pageSize;
      const length = pagination.pageSize;
      const columnFilter = JSON.stringify(columnFilters) ?? "[]";
      const sortFilter = JSON.stringify(sorting) ?? "[]";
      service
        .downlinewall(start, length, columnFilter, sortFilter)
        .then((res) => {
          setUserData(res.data);
        });
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "first_name", //access nested data with dot notation
        header: "FIRST NAME",
        size: 150,
        enableSo: false,
      },
      {
        accessorKey: "last_name",
        header: "LAST NAME",
        size: 150,
      },
      {
        accessorKey: "email",
        header: "EMAIL",
        size: 150,
      },
      {
        accessorKey: "mobile",
        header: "PHONE NUMBER",
        size: 150,
        enableColumnFilterModes: false,
      },
      {
        accessorKey: "service",
        header: "SERVICE",
        size: 150,
        enableColumnFilterModes: false,
      },

      {
        accessorKey: "active_bv",
        header: "WEEKLY BV",
        size: 150,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "date_of_joinings",
        header: "START DATE",
        filterVariant: "date-range",
        size: 150,
      },
      {
        accessorKey: "last_order_date",
        header: "LAST ORDERD DATE",
        filterVariant: "date-range",
    
        size: 150,
        Cell: ({ row }) =>
          row.original.last_order_date ? moment(row.original.last_order_date).format("MMM DD,YYYY"):"NA",
      },
    ],
    []
  );
  const handleExportData = (type) => {
    if (type == "excel") {
      const worksheet = XLSX.utils.json_to_sheet(userData.result);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "FIRST NAME",
            "LAST NAME",
            "EMAIL",
            "PHONE",
            "ID",
            "DATE",
            "TOTAL BV",
            "PRODUCT NAME",
            "WEEKLY BV",
            "LAST ORDERD DATE",
          ],
        ],
        { origin: "A1" }
      );
      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "Mycustomers.xlsx");
    } else {
      var copiedData = _.cloneDeep(userData.result);

      const fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(copiedData);

         XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            "FIRST NAME",
            "LAST NAME",
            "EMAIL",
            "PHONE",
            "DATE",
            "PRODUCT NAME",
            "WEEKLY BV",
            "LAST ORDERD DATE",
          ],
        ],
        { origin: "A1" }
      );
      ws["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];

      const csvOutput = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, "Mycustomers" + fileExtension);
    }
  };
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.total_Mycustomerwall")}
        </title>
      </Helmet>
      {/* <div > */}

      <PageTitle title="total_Mycustomerwall" buttonOn={false} />
      <div className={style.DownlineMembers}>
        {userData && (
          <>
            <TitleHeaders
              tilesvalue="total_Mycustomerwall"
              count={userData.totalCount}
            />

            <div className="material-table">
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={userData.result}
                  enableHiding={false}
                  //3 dots in tablehead
                  muiTableHeadCellColumnActionsButtonProps={{
                    sx: {
                      color:
                        currentTheme === "theme-dark" ? "#fff " : "#14243a",
                    },
                  }}
                  muiTableBodyProps={{
                    sx: {
                      background:
                        currentTheme === "theme-dark" ? "#14243a" : "#fff80",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      color:
                        currentTheme === "theme-dark" ? "#fff " : "#14243a",
                      borderBottom:
                        currentTheme === "theme-dark"
                          ? ".5px solid #47556560"
                          : "#00090",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      color:
                        currentTheme === "theme-dark" ? "#fff " : "#14243a",
                    },
                  }}
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setGlobalFilter}
                  onPaginationChange={setPagination}
                  onSortingChange={setSorting}
                  rowCount={userData.count}
                  manualPagination={true}
                  manualFiltering={true}
                  enableGlobalFilter={false}
                  manualSorting={true}
                  state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        p: "0.5rem",
                        flexWrap: "wrap",
                      }}
                    >
                      <div className={style.exportButton}>
                        <Button
                          onClick={() => {
                            handleExportData("excel");
                          }}
                        >
                          <i className="fa fa-file-excel-o"></i>
                          Excel
                        </Button>

                        <Button
                          onClick={() => {
                            handleExportData("csv");
                          }}
                        >
                          <i className="fa fa-file-text-o"></i>
                          Csv
                        </Button>
                      </div>
                    </Box>
                  )}
                />
              </ThemeProvider>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const DownlinewallTable = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TableView />
  </LocalizationProvider>
);

export default DownlinewallTable;
