import React from "react";
import { useState } from "react";
import style from "./network.module.scss";
// import { animated, useTransition } from 'react-spring';
import { usePopperTooltip } from "react-popper-tooltip";
import { ToolTips } from "./";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE } from "../../../configuration/apiconfig";
import proPic from "../../../assets/images/nophoto/no_photo.jpg";
import add from "../../../assets/icons/add.png";
import disabledimg from "../../../assets/icons/add_disabled.png";
import addImage from "../../../assets/icons/add.png";
import up from "../../../assets/icons/up.png";
import down from "../../../assets/icons/down.png";
import loder from "../../../assets/images/loading-load.gif";
import GenealogyService from "../../../service/tree/genealogy";
import Swal from "sweetalert2";
import { Popover, OverlayTrigger } from "react-bootstrap";
import CryptoJS from "crypto-js";
// import 'sweetalert2/src/sweetalert2.scss'

const sharedSecret = "8a3c7d7a6d3b5f9e2b1e4d6a7c8b9a0f";

function encryptData(data) {
  const jsonData = JSON.stringify(data);
  const iv = CryptoJS.lib.WordArray.random(16);
  const cipherText = CryptoJS.AES.encrypt(
    jsonData,
    CryptoJS.enc.Utf8.parse(sharedSecret),
    { iv: iv }
  ).toString();
  const ivHex = iv.toString(CryptoJS.enc.Hex);

  return {
    iv: ivHex,
    encryptedData: cipherText,
  };
}

const AddComponents = (props) => {
  const project = useSelector((state) => state.ProjectConfig);
  const history = useHistory();
  const service = new GenealogyService();
  const addMember = () => {
    if (props.storeUrl) {
      window.open(
        `${props.storeUrl}&position=${props.leg}&username=${props.user_name}&reg_from_tree=1`,
        "_blank"
      );
    } else {
      history.push(`/register/${props.user_name}/${props.leg}/true`);
    }
  };
  return (
    <td colSpan="2">
      <div>
        {project.mlmPlan == "Unilevel"
          ? [
              project.userName != props.user_name ? (
                <img
                  src={disabledimg}
                  alt=""
                  className={`${style.addButtonImg} ${style.marginWidth}`}
                  key={props.user_name}
                  // onClick={addMember}
                />
              ) : (
                <img
                  src={disabledimg}
                  alt=""
                  className={`${style.addButtonImg} ${style.marginWidth}`}
                  // onClick={addMember}
                  key={props.user_name}
                />
              ),
            ]
          : [
              (props.disabled && props.leg == props.disabled) ||
              props.disabled == "both" ? (
                <img
                  src={disabledimg}
                  alt=""
                  className={`${style.addButtonImg} ${
                    project.mlmPlan != "Binary" && style.marginWidth
                  }`}
                  // onClick={addMember}
                  key={props.user_name}
                />
              ) : (
                <img
                  src={addImage} //change cross icon
                  alt=""
                  className={`${style.addButtonImg} ${
                    project.mlmPlan != "Binary" && style.marginWidth
                  }`}
                  //onClick={addMember}
                  onClick={() =>
                    props.handleBinaryModalToggle(props.user_name, props.leg,props.userId,props)
                  }
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  key={props.user_name}
                />
              ),
            ]}
      </div>
    </td>
  );
};

const BinaryLockModal = () => {
  return (
    <div
      className="modal fade"
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content" style={{ backgroundColor: "#fff" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Binary Lock
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Are you sure you want to put a binary lock?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              //onClick={()=>OnclickYes(state.position,state.placement)}
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TreeNode = (props) => {
  const hasChildren = (children) => {
    return children && children.length;
  };
  const history = useHistory();
  const [isExpand, setIsExpand] = useState();
  const [linesw, setLine] = useState(0);
  const [exapndeduser, setexapndeduser] = useState();
  const [rootLeg, setRootLeg] = useState("");
  const [transform, setTranform] = useState(false);
  const [level] = useState("3");
  const [margin, setMargin] = useState();
  const [lineWidth, setLineWidth] = useState(0);
  const [render, setRender] = useState(0);
  const [headnode, setHeadnode] = useState(props.userData);

  const [state, setState] = useState({
    placement: "",
    position: "",
  });

  const [width, setWidth] = useState(
    localStorage.getItem("width") ? localStorage.getItem("width") : ""
  );
  const service = new GenealogyService();

  // binaary lock
  const [binaryLockPromtModal, setBinaryLockPromtModal] = useState(true);

  function Clipboard_CopyTo(value) {
    var tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  const callUnLock = (useranme, position,userId) => {
    const inputValue = `${process.env.REACT_APP_REG}?position=${position}&username=${useranme}&lock=true`;

    Swal.fire({
      title: "Do you want to Unlock this position?",
      showCancelButton: true,
      confirmButtonText: "YES",
      cancelButtonText: "COPY",
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText: "Cancel",
      input: "text",
      inputLabel: "Link to copy",
      inputValue,
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(`${inputValue}`, "_blank");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Clipboard_CopyTo(inputValue);
        Swal.fire("Link copied to clipboard");
      }
    });
  };
  const handleBinaryModalToggle = (placement, position,fatherId,props) => {
   

    let data = {
      position: position,
      placement: placement,
      sponsor: project.userName,
      lock: false,
    };

    const inputValue = `${process.env.REACT_APP_REG}?position=${position}&placement=${placement}&sponsor=${project.userName}&lock=false`;

    let formData = {
      placement: placement,
      position: position,
      sponsor: project.userName,
    };

    Swal.fire({
      title: "Please choose an action to perform on the user:",
      showCancelButton: true,
      confirmButtonText: "YES",
      cancelButtonText: `
    <i class="fa fa-copy"></i> Copy
  `,
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText: "Cancel",
      input: "text",
      inputLabel: "Link to copy",
      inputValue,
      confirmButtonText: `
    <i class="fa fa-user-plus"></i> Add User
  `,
      confirmButtonAriaLabel: "Add User",

      denyButtonText: `
    <i class="fa fa-lock"></i> Lock Position
  `,
    }).then((result) => {
      console.log(result);
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.open(`${inputValue}`, "_blank");
      } else if (result.isDenied) {
        Swal.fire({
          title: "Do you want to lock this position?",
          showCancelButton: true,
          confirmButtonText: "YES",
          denyButtonText: `Cancel`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            service.submitPosition(formData).then((res) => {
              if (res.data.status) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  width: 600,
                  height: 200,
                  title: "Position has been locked",
                  showConfirmButton: false,
                  timer: 1500,
                });
                window.location.reload();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "danger",
                  title: "Something went wrong",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            });
          } else if (result.isDenied) {
            // Swal.fire("Changes are not saved", "", "info");
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Clipboard_CopyTo(inputValue);
        Swal.fire("Link copied to clipboard");
      }
    });

    // Function to lock position
    // setBinaryLockPromtModal(!binaryLockPromtModal)
  };
  const OnclickYes = (placement, position) => {
    console.log(placement, position);
    let formData = {
      placement: placement,
      position: position,
    };
    service.submitPosition(formData).then((res) => {
      console.log(res);
    });
  };

  const project = useSelector((state) => state.ProjectConfig);

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  useEffect(() => {
    if (props.depth == "1") {
      setRootLeg(props.position);
    } else {
      setRootLeg(props.rootLeg);
    }
    //   },[props,level])
  }, []);

  //other than Binary recursive component
  const recursiveComponent = (data) => {
    let Components = "";
    Components = data.children.map((children, index) => {
      return (
        <td className={style.nodeContainer} colSpan="2" key={index}>
          <TreeNode
            {...children}
            expand={props.expand}
            closeExpand={closeExpand}
            setSearchExpand={props.setSearchExpand}
            expndLevel={data.expndLevel}
            rootLeg={rootLeg}
            depthLevel={props.depthLevel}
            toolTipConfig={props.toolTipConfig}
            callParent={props.callParent}
            storeUrl={props.storeUrl}
            filterUser={props.filterUser}
            expanded={props.expanded}
            headnode={props.headnode}
            isHead={props.isHead}
            loader={props.loader}
            userData={props.userData}
          />
        </td>
      );
    });

    return Components;
  };
  const BinaryrecursiveComponent = (data) => {
    let Components = "";
    if (data.children.length == 1) {
      if (data.children[0].position == "L") {
        Components = (
          <>
            {data.children.map((children, index) => {
              return (
                <td className={style.nodeContainer} colSpan="2" key={index}>
                  <TreeNode
                    {...children}
                    expand={props.expand}
                    closeExpand={closeExpand}
                    setSearchExpand={props.setSearchExpand}
                    expndLevel={data.expndLevel}
                    rootLeg={rootLeg}
                    depthLevel={props.depthLevel}
                    toolTipConfig={props.toolTipConfig}
                    callParent={props.callParent}
                    storeUrl={props.storeUrl}
                    filterUser={props.filterUser}
                    expanded={props.expanded}
                    headnode={props.headnode}
                    isHead={props.isHead}
                    loader={props.loader}
                    userData={props.userData}
                  />
                </td>
              );
            })}
            <AddComponents
              user_name={props.user_name}
              userId={props.user_id}
              leg="R"
              disabled={props.disabled}
              storeUrl={props.storeUrl}
              handleBinaryModalToggle={handleBinaryModalToggle}
            />
          </>
        );
      } else {
        Components = (
          <>
            <AddComponents
              user_name={props.user_name}
              userId={props.user_id}
              leg="L"
              disabled={props.disabled}
              storeUrl={props.storeUrl}
              handleBinaryModalToggle={handleBinaryModalToggle}
            />
            {data.children.map((children, index) => {
              return (
                <td className={style.nodeContainer} colSpan="2" key={index}>
                  <TreeNode
                    {...children}
                    expand={props.expand}
                    closeExpand={closeExpand}
                    setSearchExpand={props.setSearchExpand}
                    expndLevel={data.expndLevel}
                    rootLeg={rootLeg}
                    depthLevel={props.depthLevel}
                    toolTipConfig={props.toolTipConfig}
                    callParent={props.callParent}
                    storeUrl={props.storeUrl}
                    filterUser={props.filterUser}
                    expanded={props.expanded}
                    headnode={props.headnode}
                    isHead={props.isHead}
                    loader={props.loader}
                    userData={props.userData}
                  />
                </td>
              );
            })}
          </>
        );
      }
    } else {
      Components = data.children.map((children, index) => {
        return (
          <td className={style.nodeContainer} colSpan="2" key={index}>
            <TreeNode
              {...children}
              expand={props.expand}
              closeExpand={closeExpand}
              setSearchExpand={props.setSearchExpand}
              expndLevel={data.expndLevel}
              rootLeg={rootLeg}
              depthLevel={props.depthLevel}
              toolTipConfig={props.toolTipConfig}
              callParent={props.callParent}
              storeUrl={props.storeUrl}
              filterUser={props.filterUser}
              expanded={props.expanded}
              headnode={props.headnode}
              isHead={props.isHead}
              loader={props.loader}
              userData={props.userData}
            />
          </td>
        );
      });
    }
    return Components;
  };

  //showexpand
  const showExpand = (data) => {
    setexapndeduser(data.user_id);
    // props.filterUser(data.user_name)

    props.expand(
      data,
      setIsExpand,
      props.user_id,
      props.depthLevel,
      props.user_name
    );
  };
  let line = {
    line1: 0,
    line2: 0,
  };
  const getReference = (ref, index) => {
    line[index] = ref.getBoundingClientRect().left;

    if (line.line1 > 0 && line.line2 > 0) {
      if (line.line1 > line.line2) {
        setTranform(true);
      } else {
        setTranform(false);
      }
      const temp = Math.abs(line.line1 - line.line2);

      setLineWidth(temp);
      line.line2 = 0;
      line.line1 = 0;
    } else {
      if (line.line1 < 0 || line.line2 < 0) {
        const temp = Math.abs(line.line1 - line.line2);
        setLineWidth(temp);
        setTranform(true);
        line.line2 = 0;
        line.line1 = 0;
      }
    }
  };
  //close expand
  const closeExpand = () => {
    props.closeExpand(props.depthLevel);
    setIsExpand(false);
  };
  const colspan = () => {
    let colspan = 0;
    if (project.mlmPlan == "Binary") {
      colspan = 4;
    } else if (project.mlmPlan == "Unilevel") {
      colspan = props.children ? props.children.length * 2 + 2 : 4;
    } else if (project.mlmPlan == "Matrix") {
      colspan = props.children ? props.children.length * 2 + 2 : 4;
    } else if (project.mlmPlan == "Board") {
      colspan = props.children ? props.children.length * 2 + 2 : 4;
    } else if (project.mlmPlan == "Stair_Step") {
      colspan = props.children ? props.children.length * 2 + 2 : 4;
    } else {
      colspan = props.children ? props.children.length * 2 : 4;
    }
    return colspan;
  };

  const callParent = (id) => {
    props.callParent(id);
    props.closeExpand(props.depthLevel);
    props.setSearchExpand(true);
    setIsExpand(false);
  };

  return (
    <>
      {props.firstLevel && project.userName != props.user_name && (
        <div className={`${style.upIcon} ${style.positionRelative}`}>
          {props.expanded ? (
            <div>
              <img
                src={up}
                alt=""
                onClick={closeExpand}
                style={{ backgroundColor: "red" }}
              />
            </div>
          ) : (
            <img
              src={up}
              alt=""
              onClick={() => props.callParent(props.father_id)}
            />
          )}
        </div>
      )}
      <table
        cellPadding={0}
        cellSpacing={0}
        className={`${style.mainTable} ${
          project.mlmPlan == "Binary" && style.width50
        }`}
      >
        <tbody>
          {!isExpand && (
            <tr className={style.rootNode}>
              <td colSpan={colspan()}>
                <div
                  className={`${style.node} ${
                    project.mlmPlan != "Binary" && style.treeNode
                  }`}
                >
                  {/*  
                           {props.isHead &&
                                      <div className={`${style.upIcon}`}>
                                      <img
                                        src={up}
                                        alt=""
                                        onClick={closeExpand}
                                      />
                                      <div className={`${style.line} ${style.left} ${style.expandDown} `}
                                       ref={(el) => {
                                        if (!el) return;
                                        getReference(el, "line2");
                                      }}
                                      style={{
                                        width: "20px",
                                      }}
                                       ></div>
                                       </div>
} */}

                  <img
                    src={props.photo ? props.photo : proPic}
                    alt=""
                    className={style.profileImage}
                    ref={setTriggerRef}
                    onDoubleClick={() => {
                      props.active == "7" && props.sponsor == project.userName
                        ? callUnLock(props.user_name, props.position,props.user_id)
                        : callParent(props.user_id);
                    }}
                  />
                  <>{console.log(props)}</>
                  {}
                  {visible && (
                    <div
                      ref={setTooltipRef}
                      {...getTooltipProps({
                        className: `tooltip-container ${style.tooltipContainer}`,
                      })}
                    >
                      <div
                        {...getArrowProps({ className: ` ${style.arrow}` })}
                      />
                      <ToolTips config={props.toolTipConfig} {...props} />
                    </div>
                  )}
                  {props.active == "1" ? (
                    <p className={`${style.dmeoName}`}>{props.user_name}</p>
                  ) : (
                    <p className={`${style.dmeoNameBlocked}`}>
                      {props.user_name}
                    </p>
                  )}

                  {props.depth == level && (
                    <div>
                      {props.loader ? (
                        <img src={loder} alt="" className={style.downIcon} />
                      ) : (
                        <img
                          src={down}
                          alt=""
                          className={style.downIcon}
                          onClick={() => {
                            showExpand(props);
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          )}
          {props.depth != level ? (
            <>
              <tr>
                <td colSpan={colspan()}>
                  <div className={`${style.line} ${style.down}`}></div>
                </td>
              </tr>
              <tr>
                <td className={`${style.line} ${style.left}`}>&nbsp;</td>
                {project.mlmPlan != "Binary" ? (
                  props.children &&
                  props.children.map((items, index) => {
                    if (index < props.children.length - 1) {
                      return (
                        <React.Fragment key={index}>
                          <td
                            className={`${style.line} ${style.right} ${style.top}`}
                          >
                            &nbsp;
                          </td>
                          <td
                            className={`${style.line} ${style.left} ${style.top}`}
                          >
                            &nbsp;
                          </td>
                        </React.Fragment>
                      );
                    }

                    return true;
                  })
                ) : (
                  <React.Fragment>
                    <td className={`${style.line} ${style.right} ${style.top}`}>
                      &nbsp;
                    </td>
                    <td className={`${style.line} ${style.left} ${style.top}`}>
                      &nbsp;
                    </td>
                  </React.Fragment>
                )}
                {hasChildren(props.children) &&
                  project.mlmPlan == "Unilevel" && (
                    <React.Fragment>
                      <td
                        className={`${style.line} ${style.right} ${style.top}`}
                      >
                        &nbsp;
                      </td>
                      <td
                        className={`${style.line} ${style.left} ${style.top}`}
                      >
                        &nbsp;
                      </td>
                    </React.Fragment>
                  )}
                {hasChildren(props.children) &&
                  project.mlmPlan == "Matrix" &&
                  props.children &&
                  props.children.length < width && (
                    <React.Fragment>
                      <td
                        className={`${style.line} ${style.right} ${style.top}`}
                      >
                        &nbsp;
                      </td>
                      <td
                        className={`${style.line} ${style.left} ${style.top}`}
                      >
                        &nbsp;
                      </td>
                    </React.Fragment>
                  )}
                {hasChildren(props.children) && project.mlmPlan == "Board" && (
                  <React.Fragment>
                    <td className={`${style.line} ${style.right} ${style.top}`}>
                      &nbsp;
                    </td>
                    <td className={`${style.line} ${style.left} ${style.top}`}>
                      &nbsp;
                    </td>
                  </React.Fragment>
                )}
                {hasChildren(props.children) &&
                  project.mlmPlan == "Stair_Step" && (
                    <React.Fragment>
                      <td
                        className={`${style.line} ${style.right} ${style.top}`}
                      >
                        &nbsp;
                      </td>
                      <td
                        className={`${style.line} ${style.left} ${style.top}`}
                      >
                        &nbsp;
                      </td>
                    </React.Fragment>
                  )}
                <td className={`${style.line} ${style.right} `}>&nbsp;</td>
              </tr>
              <tr>
                {hasChildren(props.children)
                  ? [
                      project.mlmPlan != "Binary" ? (
                        <React.Fragment key={project.mlmPlan}>
                          {recursiveComponent(props)}
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={project.mlmPlan}>
                          {BinaryrecursiveComponent(props)}
                        </React.Fragment>
                      ),
                    ]
                  : [
                      project.mlmPlan != "Binary" ? (
                        <AddComponents
                          key={project.mlmPlan}
                          user_name={props.user_name}
                          userId={props.user_id}
                          leg={1}
                          disabled={props.disabled}
                          storeUrl={props.storeUrl}
                          handleBinaryModalToggle={handleBinaryModalToggle}
                        />
                      ) : (
                        <React.Fragment key={props.user_id}>
                          <AddComponents
                            user_name={props.user_name}
                            userId={props.user_id}
                            leg="L"
                            disabled={props.disabled}
                            storeUrl={props.storeUrl}
                            handleBinaryModalToggle={handleBinaryModalToggle}
                          />
                          <AddComponents
                            user_name={props.user_name}
                              userId={props.user_id}
                            leg="R"
                            disabled={props.disabled}
                            storeUrl={props.storeUrl}
                            handleBinaryModalToggle={handleBinaryModalToggle}
                          />
                        </React.Fragment>
                      ),
                    ]}
                {hasChildren(props.children) &&
                  project.mlmPlan == "Unilevel" && (
                    <AddComponents
                      user_name={props.user_name}
                        userId={props.user_id}
                      leg={props.children.length + 1}
                      disabled={props.disabled}
                      storeUrl={props.storeUrl}
                      handleBinaryModalToggle={handleBinaryModalToggle}
                    />
                  )}
                {hasChildren(props.children) &&
                  project.mlmPlan == "Matrix" &&
                  props.children &&
                  props.children.length > 0 &&
                  props.children.length < width && (
                    <AddComponents
                      user_name={props.user_name}
                        userId={props.user_id}
                      leg={props.children.length + 1}
                      disabled={props.disabled}
                      storeUrl={props.storeUrl}
                      handleBinaryModalToggle={handleBinaryModalToggle}
                    />
                  )}
                {hasChildren(props.children) && project.mlmPlan == "Board" && (
                  <AddComponents
                    user_name={props.user_name}
                      userId={props.user_id}
                    leg={props.children.length + 1}
                    disabled={props.disabled}
                    storeUrl={props.storeUrl}
                    handleBinaryModalToggle={handleBinaryModalToggle}
                  />
                )}
                {hasChildren(props.children) &&
                  project.mlmPlan == "Stair_Step" && (
                    // props.children && props.children.length<0 &&
                    <AddComponents
                      user_name={props.user_name}
                        userId={props.user_id}
                      leg={props.children.length + 1}
                      disabled={props.disabled}
                      storeUrl={props.storeUrl}
                      handleBinaryModalToggle={handleBinaryModalToggle}
                    />
                  )}
              </tr>
            </>
          ) : (
            [
              isExpand && (
                <tr key={props.user_name}>
                  <td colSpan="4">
                    <div
                      className={`${style.line} ${style.left} ${style.expand}`}
                      ref={(el) => {
                        if (!el) return;
                        getReference(el, "line1");
                      }}
                    ></div>
                    {props.rootLeg == "R" ? (
                      <div>
                        <div
                          className={` ${style.right} ${style.top} ${style.expandRight}`}
                          style={{
                            width: lineWidth,
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div>
                        <div
                          className={` ${style.right} ${style.top} ${style.expandLeft}`}
                          style={{
                            width: lineWidth,
                            transform: transform
                              ? `translateX(${100}%)`
                              : "none",
                          }}
                        ></div>
                      </div>
                    )}
                    <div
                      className={`${style.line} ${style.left} ${style.expandDown} `}
                      ref={(el) => {
                        if (!el) return;
                        getReference(el, "line2");
                      }}
                      style={{
                        width: lineWidth,
                      }}
                    ></div>

                    <div className={`${style.upIcon}`}>
                      <img src={up} alt="" onClick={closeExpand} />
                      <div
                        className={`${style.line} ${style.left} ${style.expandDown} `}
                        ref={(el) => {
                          if (!el) return;
                          getReference(el, "line2");
                        }}
                        style={{
                          width: lineWidth,
                        }}
                      ></div>
                    </div>
                  </td>
                </tr>
              ),
            ]
          )}
        </tbody>
      </table>
    </>
  );
};

export default TreeNode;
