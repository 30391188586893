import React, { useEffect, useState } from "react";
import style from "./network.module.scss";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

//components
import { SearchContent, TreeContent } from "./";
function GenealogyTreeContent(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const history = useHistory();
  const username = useSelector((state) => state.ProjectConfig.userName);
  const [searchExpand, setSearchExpand] = useState(false);

  const [state, setState] = useState({
    username: username,
    error: {
      error: false,
    },
    searchloader: false,
    resetloader: false,
  });

  useEffect(() => {
    if (history.location.state && history.location.state.error) {
      setError(true);
    } else {
      setState({
        ...state,
        username: username,
      });
    }
  }, [history.location.state]);

  const change = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      username: value,
    }));
  };
  //click the down line members
  const clickDownLine = () => {
    history.push({
      pathname: "/downlineMembers",
      state: { prevPath: history.location.pathname },
    });
  };

  //reset button click
  const resetButtonClick = () => {
    setSearchExpand(true);
    setState((prev) => ({
      ...prev,
      resetloader: true,
    }));
    setError(false);
    history.push("/genealogyTree");
    props.getList(username);
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        username: username,
        resetloader: false,
      }));
    }, 3000);
  };

  //search button click
  const searchClick = (e) => {
    e.preventDefault();
    setSearchExpand(true);
    setState((prev) => ({
      ...prev,
      searchloader: true,
    }));
    if (state.username) {
      props.filterUser(state.username);
    }
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        searchloader: false,
      }));
    }, 3000);
  };
  return (
    <div className={style.MianContent}>
      {error && history.location.state && (
        <Alert variant="danger" onClose={() => setError(false)} dismissible>
          {t("validation." + history.location.state.message)}
        </Alert>
      )}
      <SearchContent
        reset={resetButtonClick}
        searchClick={searchClick}
        changeHandler={change}
        buttonClick={clickDownLine}
        buttonLabel="downlineMembers"
        {...state}
      />
      {props.data && (
        <TreeContent
          {...props}
          searchExpand={searchExpand}
          filterUser={props.filterUser}
          setSearchExpand={setSearchExpand}
        />
      )}
    </div>
  );
}

export default GenealogyTreeContent;
