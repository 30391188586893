import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import style from "./dashboard.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FilterToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    className={style.TilesfilterIcon}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </span>
));
function TilesFilter(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [type, setType] = useState("user");
  const { t } = useTranslation();

  const graphChnage = (type) => {
    setType(type);
  };
  const onChange = (range) => {
    props.onChange(range, type);
  };
  return (
    <>
      {/* <Dropdown className={`${style.lst_box_quick_select}`}>
        <Dropdown.Toggle className={style.lst_box_quick_select_btn}>
          <i
            style={{ color: currentTheme === "theme-dark" ? "white" : "black" }}
            className="fa fa-ellipsis-v"
          ></i>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className={style.dropdownmenu}>
          {props.items &&
            props.items.map((item, index) => {
              return (
                <Dropdown.Item
                  className={style.dropdownitem}
                  key={index}
                  eventKey={item.code}
                  onSelect={props.onChange}
                >
                  {t("Common." + item.code)}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown> */}
      {/* 
      <Dropdown className={`${style.lst_box_quick_select}`}>
        <Dropdown.Toggle className={style.lst_box_quick_select_btn}>
          <i
            style={{ color: currentTheme === "theme-dark" ? "white" : "black" }}
            className="fa fa-ellipsis-v"
          ></i>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className={style.dropdownmenu}>
          {props.items &&
            props.items.map((item, index) => {
              return (
                <Dropdown.Item
                  className={style.dropdownitem}
                  key={index}
                  eventKey={item.code}
                  onSelect={props.onChange}
                >
                  {t("Common." + item.code)}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown> */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Dropdown
          className={`${style.lst_box_quick_select}`}
          style={{ marginLeft: "87%" }}
        >
          <Dropdown.Toggle className={style.lst_box_quick_select_btn}>
            <i
              style={{
                color: currentTheme === "theme-dark" ? "white" : "black",
              }}
              className="fa fa-ellipsis-v"
            ></i>
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight className={style.dropdownmenu}>
            {props.items &&
              props.items.map((item, index) => {
                return (
                  <Dropdown.Item
                    className={style.dropdownitem}
                    key={index}
                    eventKey={item.code}
                    onSelect={onChange}
                  >
                    {t("Common." + item.code)}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
        {/* {props.type && (
          <Dropdown className={`${style.lst_box_quick_select}`}>
            <Dropdown.Toggle className={style.lst_box_quick_select_btn}>
              <i
                style={{
                  color: currentTheme === "theme-dark" ? "white" : "black",
                }}
                className="fa fa-filter"
              ></i>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className={style.dropdownmenu}>
              <Dropdown.Item
                className={style.dropdownitem}
                eventKey={"user"}
                onSelect={() => graphChnage("user")}
              >
                IBos
              </Dropdown.Item>
              <Dropdown.Item
                className={style.dropdownitem}
                eventKey={"customer"}
                onSelect={() => graphChnage("customer")}
              >
                Customer
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )} */}
      </div>
    </>
  );
}

export default TilesFilter;
