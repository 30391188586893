import React, { useEffect, useMemo, useState } from "react";
import style from "./history.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import DataTable from "react-data-table-component";
import Spinner from "../../shared/Spinner";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import moment from "moment";
import Invoice from "./Invoice";
import OrderService from "../../../service/orderHistory/orderHistory";
const TableView = (props) => {
  console.log("props", props);
  const StyledTabs = styled((props) => (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
    },
  });

  const [isTabChanging, setIsTabChanging] = useState(false);

  const handleChange = (event, newValue) => {
    setIsTabChanging(true);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 400);
    setSelectedTab(newValue);

    // rankDetails(newValue + 1);
  };

  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const service = new OrderService();
  const currency = useSelector((state) => state.curr);
  const [state, setState] = useState({
    modalShow: false,
    modalData: {},
    loading: true,
    data: [],
    count: "",
  });
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {}, [selectedTab]);

  useEffect(() => {
    const fetchData = async () => {
      const start = pagination.pageIndex * pagination.pageSize;
      const length = pagination.pageSize;
      const columnFilter = JSON.stringify(columnFilters) ?? "[]";
      const sortFilter = JSON.stringify(sorting) ?? "[]";
      service
        .getOrderHistory(start, length, columnFilter, sortFilter)
        .then((res) => {
          if (res.status) {
            setState({
              loading: false,
              data: res.data.orders,
              count: res.data.count,
            });
          } else {
            if (res.error.code === 1002) {
            } else if (res.error.code === 1057) {
            }
          }
        });
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const handleClose = () => {
    setState((prev) => ({
      ...prev,
      modalShow: false,
      modalData: {},
    }));
  };
  const handleShow = (data) => {
    setState((prev) => ({
      ...prev,
      modalShow: true,
      modalData: data,
    }));
  };

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),

      color: currentTheme == "theme-dark" ? "#868686" : "#00090",
      background: currentTheme == "theme-dark" ? "" : "#E5E5E550",
      "&.Mui-selected": {
        color: currentTheme == "theme-dark" ? "#fff" : "rgb(88, 147, 241)",
        backgroundColor: currentTheme != "theme-dark" ? "#ffffff" : "",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  let OrderStatus = {
    0: "Pending",
    1: "Added",
    2: "Accepted",
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "order_id", //access nested data with dot notation
        header: "ORDER ID",
        size: 100,
        //    enableColumnFilter: false,
        // enableSorting:false,
        Cell: ({ row }) => {
          return <div>{"#" + row.original["order_id"]}</div>;
        },
      },
      {
        accessorKey: "order_date", //access nested data with dot notation
        header: "PURCHSE DATE",
        size: 100,
        filterVariant: "date-range",
        //      enableColumnFilter: false,
        // enableSorting:false,
        Cell: ({ row }) => {
          return (
            <div>
              {moment(row.original["order_date"]).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "week_of_service", //access nested data with dot notation
        header: "WEEK OF SERVICE",
        //    enableColumnFilter: false,
        // enableSorting:false,
        size: 100,
      },

      {
        accessorKey: "product_id", //access nested data with dot notation
        header: "SERVICE",
        //    enableColumnFilter: false,
        // enableSorting:false,
        size: 100,
      },
      {
        accessorKey: "active_pv",
        header: "WEEKLY BV",
        size: 100,
        //    enableColumnFilter: false,
        // enableSorting:false,
      },
    ],
    []
  );
  const handleExportData = (type) => {
    if (type == "excel") {
      const worksheet = XLSX.utils.json_to_sheet(state.data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "ORDER ID",
            "PURCHSE DATE",
            "WEEK OF SERVICE",
            "SERVICE",
            "WEEKLY BV",
          ],
        ],
        { origin: "A1" }
      );
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "customers_order.xlsx");
    } else {
      var copiedData = _.cloneDeep(state.data);

      const fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(copiedData);
      ws["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            "ORDER ID",
            "PURCHSE DATE",
            "WEEK OF SERVICE",
            "SERVICE",
            "WEEKLY BV",
          ],
        ],
        { origin: "A1" }
      );

      const csvOutput = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, "customers_order" + fileExtension);
    }
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <Table striped bordered hover>
        lklkl;k
        {/* <thead>
          {rowscolumns.map((col) => (
            <th>{col}</th>
          ))}
        </thead>
        {ContentData.data[data].map((arrObj, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{arrObj.from_id}</td>
            <td>{arrObj.personal_pv}</td>
            <td>{arrObj.personal_pv}</td>
            <td>{arrObj.percentage}</td>
            <td>
              {Currency.currentCurr}

              {CurrencyDecimalFormat(
                arrObj.amount * Currency.value,
                Currency.precision
              )}
            </td>
          </tr>
        ))} */}
      </Table>
    );
  };
  if (state.loading) {
    return <Spinner />;
  }

  return (
    <div className={style.MainContainer}>
      <div className={style.summary}>
        <div className="material-table" style={{ marginTop: "10px" }}>
          <MaterialReactTable
            columns={columns}
            data={state.data}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={state.count}
            manualPagination={true}
            manualFiltering={true}
            manualSorting={true}
            enableGlobalFilter={false}
            state={{
              columnFilters,
              globalFilter,
              isLoading,
              pagination,
              showAlertBanner: isError,
              showProgressBars: isRefetching,
              sorting,
            }}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "2rem",
                  p: "0.8rem",
                  flexWrap: "wrap",
                }}
              >
                <div className={style.exportButton}>
                  <Button
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      handleExportData("excel");
                    }}
                  >
                    <i className="fa fa-file-excel-o"></i>
                    Excel
                  </Button>

                  <Button
                    onClick={() => {
                      handleExportData("csv");
                    }}
                  >
                    <i className="fa fa-file-text-o"></i>
                    Csv
                  </Button>
                </div>
              </Box>
            )}
          />
          {/* {state.modalData && (
            <Invoice
              show={state.modalShow}
              handleClose={handleClose}
              data={state.modalData}
              currency={currency}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};
const HistoryContent = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TableView />
  </LocalizationProvider>
);
export default HistoryContent;
