import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import companyName from "../../../../store/mobxStore/companyName";
import Forms from "../../../components/auth/Forms";
import ForgotPasswordService from "../../../../service/Auth/ForgotPassowrd";
import ForgotButton from "../../../components/auth/ForgotButton";
import { BASE } from "../../../../configuration/apiconfig";
import { Alert, Form } from "react-bootstrap";
import { AlertMessage } from "../../../components/common";
import { useParams, useHistory } from "react-router-dom";

function GoogleAuth({ t }) {
  const history = useHistory();
  var captchvalue;
  const recaptchaRef = React.createRef();

  let styles = {
    marginTop: "-1px",
    marginLeft: "23px",
    color: "#a94442",
    fontSize: "12px",
  };
  const service = new ForgotPasswordService();

  const notifyInintialState = {
    show: false,
    message: "",
    type: "",
    header: "",
  };

  const [state, setState] = useState({
    userName: "",
    Email: "",
    captcha: "",
    captchvalue: "",
    isLoggedError: false,
    notify: notifyInintialState,
    captchaImage: "",
    FormError: {
      userName: "",
      Email: "",
      captcha: "",
      validUsernameError: "",
      validEmailError: "",
    },
    btnDisabled: false,
  });
  const [show, setShow] = useState(false);

  const formValid = (formError) => {
    let valid = true;
    Object.values(formError).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  onchange = (e) => {
    const service = new ForgotPasswordService();
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      FormError: {
        [name]: "",
      },
    }));
  };
  const blurhandler = (name) => {
    if (name === "userName") {
      const formData = {
        user_name: state.userName,
      };
      service.checkUsername(formData).then((res) => {
        if (!res.status) {
          if (res.error.code == "1043") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                validUsernameError: "Username_not_exists",
              },
            }));
          }
        } else {
          // reloadCaptcha(state.userName);
          // setState(prevState => ({
          //     ...prevState,
          //     FormError: {
          //         ...prevState.FormError,
          //         userName: ''
          //     }
          // }));
        }
      });
    }
    if (name === "Email") {
      const formData = {
        e_mail: state.Email,
        user_name: state.userName,
      };
      service.checkUsermail(formData).then((res) => {
        if (!res.status) {
          if (res.error.code == "1048") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                validEmailError: "email_not_exists",
              },
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            FormError: {
              ...prevState.FormError,
              Email: "",
            },
          }));
        }
      });
    }
  };

  const requiredValid = (value, name) => {
    const FormError = state.FormError;
    switch (name) {
      case "userName":
        FormError.userName = value.length === 0 ? "unamereq" : "";
        break;
      case "Email":
        FormError.Email = value.length === 0 ? "emailreq" : "";
        break;
      case "captcha":
        FormError.captcha = value.length === 0 ? "captchareq" : "";
        break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      FormError,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault(); //prevent default
    requiredValid(state.userName, "userName");
    requiredValid(state.Email, "Email");
    //   requiredValid(state.captcha, 'captcha');
    if (formValid(state.FormError)) {
      const formData = {
        username: state.userName,
        email: state.Email,
        //captcha: state.captcha
      };
      service.forgot_otp(formData).then((res) => {
        if (res.status) {
          // showNotify('success','Common.success','validation.captchaSuccess');
          showNotify("success", "Common.success", "validation.otpreset");
          setTimeout(() => {
            history.push("/login");
          }, 5000);
        } else {
          showNotify("danger", t("Common.error"), res.error.description);
        }
      });
    }
  };

  const showNotify = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: t(header),
        message: t(message),
      },
    }));
  };

  const reloadCaptcha = (userName) => {
    service.getCaptcha(userName).then((res) => {
      let outside = URL.createObjectURL(res);
      setState((prev) => ({
        ...prev,
        captchaImage: outside,
      }));
    });
  };
  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      notify: notifyInintialState,
    }));
  };

  const CaptchaChage = (value) => {
    setState((prevState) => ({
      ...prevState,
      captcha: value,
      FormError: {
        ...prevState.FormError,
        captcha: "",
      },
    }));
  };

  return (
    <>
      <div className="mb-2">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Common.google_auth")}
          </title>
        </Helmet>

        {state.notify.show && (
          <AlertMessage
            type={state.notify.type}
            message={state.notify.message}
            show={state.notify.show}
            dismiss={notifyDissmissed}
          />
        )}
        <form id="forgot-form">
          <Forms
            type="text"
            name="userName"
            change={onchange}
            error={
              state.FormError.userName
                ? state.FormError.userName
                : state.FormError.validUsernameError
                ? state.FormError.validUsernameError
                : ""
            }
            blurhandler={blurhandler}
            from={"forgot"}
          />
          <Forms
            type="email"
            name="Email"
            change={onchange}
            error={
              state.FormError.Email
                ? state.FormError.Email
                : state.FormError.validEmailError
                ? state.FormError.validEmailError
                : ""
            }
            blurhandler={blurhandler}
            from={"forgot"}
          />

          {/* {state.captchaImage &&
                        <>
                            <React.Fragment>
                                <Form.Group style={{ textAlign: 'center' }}>
                                    <img src={state.captchaImage} alt="" />
                                </Form.Group>
                                <div style={{ textAlign: 'center' }}>

                                    <a style={{ verticalAlign: "middle", fontSize: '15px', float: 'right', cursor: 'pointer' }} onClick={() => reloadCaptcha(state.userName)}>   {t('Common.changeCaptcha')} </a>


                                </div>
                                <Forms
                                    type="text"
                                    name="captcha"
                                    change={onchange}
                                    error={state.FormError.captcha}
                                />


                            </React.Fragment>

                        </>

                    } */}

          <ForgotButton submit={onSubmit} />
        </form>
      </div>
    </>
  );
}
export default withTranslation()(GoogleAuth);
