import React, { Component } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import './App.scss';
import './style/customstyle.css';
import RouteMap from './route';
import { createBrowserHistory } from 'history';
import { ToastContainer } from "react-toastify";
import AppInfoservice from '../service/common/Appinfo';


function getFaviconEl() {
  return document.getElementById("favicon");
}
function getAppFavicon(){
  return document.getElementById("fav");
}
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});
function getAppTitle(){
  return document.getElementById('companyname')
}
class App extends Component {
  constructor(props) {
    super(props)
    this.AppInfoservice = new AppInfoservice();
    this.state = {
      logo: '',
      title:''
    }
  }
  componentDidMount() {
    this.AppInfoservice.Appinfo().then(async (res) => {
      if (res.status) {
          this.setState({
            logo: res.data.company_info.favicon,
            title:res.data.company_info.company_name 
          });
      }
      this.handleGoogle();
  })
}
  handleGoogle() {
    const favicon = getFaviconEl(); // Accessing favicon element
    const fav = getAppFavicon();
    favicon.href =  this.state.logo;
    fav.href =  + this.state.logo;
  };
  handleApptitle(){
    getAppTitle()
    document.title=this.state.title;

  }
  render() {
    return (
      <Router>
        <ToastContainer limit={2}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
      autoClose= {5000}/>
        <RouteMap />
      </Router>
    )
  }
}
export default App;
