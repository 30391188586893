import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import SubscriptionRenewal from "../../components/subscription/SubscriptionRenewal";
import ProfileService from "../../../service/profile";
import Payments from "../../../service/common/PaymentApi";
import { withRouter } from "react-router-dom";
import style from "../../components/package/package.module.scss";
import companyName from "../../../store/mobxStore/companyName";

function Subscription (props) {

  
const [state, setState] = useState({
        product: {
        id: "",
        price: "",
        validity: "",
      },
      selectedTab: 0,
      payment_methods: [],
      selectedPeyment: "",
      alert: {
        show: false,
        message: "",
        varient: "",
      },
      ewalletData: {
        username: "",
        password: "",
      },

      epinData: {
        pinArray: [],
      },
      paypal: {
        orderId: "",
        payeerId: "",
      },
      loader:false,
});
    
const [clientSecret, setClientSecret] = useState("");
const [checked,setChecked] = useState()
     
    
    const Service = new Payments();
    const service = new ProfileService();
    // this.loadDetails();
  
    useEffect(() => {
      Service.createPayment(props.product,"Paypal").then((res) => {
         if(res.status){
      setClientSecret(res.data.public_key);
    }
      });
      loadDetails();
      loadPaymentData();
    },[state.alert.show]);


  // componentDidMount() {
  //   this.loadDetails();
  //   this.loadPaymentData();
  // };

//subscription change

const handleSubscription = (e) =>{
    
  if(e.target.checked){
      if (window.confirm(t("validation.sure_subscribe"))) {
          service.activateSubscription().then((res) => {
             
              if (res.status) {
                  setChecked(
                      true
                  )
              } else {
                 // history.push('/logout');
              }
          })
          }
          else{
              setChecked(
                  false
              )
          }
      
  }
  else{
      if (window.confirm(t("validation.sure_unsubscribe"))) {
          service.cancelSubscription().then((res) => {
         
              if (res.status) {
                  setChecked(
                      false
                  )
                  setState((prev) => ({
                    ...prev,
                    alert: {
                      show: true,
                      varient: "success",
                      message: "subscription cancelled sucessfully",
                    },
                  }));
              } else {
                setState((prev) => ({
                  ...prev,
                  alert: {  
                    show: true,
                    varient: "danger",
                    message: res.error.description,
                  },
                }));
              }
          })
          }
          else{
              setChecked(
                  true
              )
          }
    
  
  }
  }



  //load details
  const loadDetails = () => {
     service.subscriptionDetails().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          product: res.data,
        }));
        setChecked(
          res.data.renewal
      )
      }
    });
  };

  const loadPaymentData = () => {
     service.getPaymentData("membership_renewal").then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          payment_methods: res.data,
          selectedPeyment:res.data[0]

        }));
      }
    });
  };

  // Payment method change event
  const changeTabHandler = (tab) => {
    setState((prevState) => ({
      ...prevState,
      selectedPeyment: state.payment_methods[tab],
    }));
  };

  // Payment Submit Event
  const submitSubscription = (event,token) => {
    if(event.hasOwnProperty('defaultPrevented')){
      event.preventDefault()
    }
    let formData = {
      product_id: state.product.id,
      payment_method: state.selectedPeyment.code,
    };
    if (state.selectedPeyment.code === "ewallet") {
      formData.user_name_ewallet = state.ewalletData.username;
      formData.tran_pass_ewallet = state.ewalletData.password;
    } else if (state.selectedPeyment.code === "epin") {
      formData.pin_array = state.epinData.pinArray.length;
      state.epinData.pinArray.forEach((element, index) => {
        formData["epin" + (index + 1)] = element.pin;
      });
    } else if (state.selectedPeyment.code === "paypal") {
      formData.paypal_token = state.paypal.orderId;
      formData.PayerID = state.paypal.payeerId;
      formData.subscriptionID = state.paypal.subscriptionID;
      formData.data = state.paypal.data;
      
      formData.currency = "USD";
    }
    else if (state.selectedPeyment.code === "stripe") {
      formData.token = event;
      formData.currency = "USD";
    
    }
    setState((prevState) => ({
      ...prevState,
      loader:true
    }));
     service.subscriptionSubmit(formData).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          alert: {
            show: true,
            message: "Common.success",
            varient: "success",
          },
          ewalletData: {
            username: "",
            password: "",
          },
          epinData: {
            pinArray: [],
          },
          paypal: {
            orderId: "",
            payeerId: "",
          },
          selectedPeyment: "",
          selectedTab: 1,
          loader:false
        }));
        window.scrollTo(0, 0);
      } else {
        if (res.error.code !== 1002) {


          setState({
            ...state,
            loader:false,
            alert: {
              show: true,
              message: "Common.error",
              varient: "danger",
            },
          });
          // setState((prevState) => ({
          //   ...prevState,
          //   loader: false,
          //   alert: {
          //     show: true,
          //     message: "Common.error",
          //     varient: "danger",
          //   },
          // }));
          window.scrollTo(0, { behaviour: "smooth" });
        } else {
          props.history.push("/logout");
        }
      }
    });
  };
 const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };

  //get the ewallet data
  const getEwalletData = (username, password) => {
    setState((prevState) => ({
      ...prevState,
      ewalletData: {
        username: username,
        password: password,
      },
    }));
  };

  /**
   * Epin Data
   */
  const getEpinData = (pinArray) => {
    if (pinArray.length > 0) {
      setState((prevState) => ({
        ...prevState,
        epinData: {
          pinArray,
        },
      }));
    }
  };

  const paypalData = (data) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        paypal: {
          subscriptionID : data?.subscriptionID,
          data : data.data,
          orderId: data?.orderID,
          payeerId: data?.payerID,
        },
        
      }));
    }
  };
  

    const { t } = props;
    return (
      <div className={`h-100`}>
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.packageUpgrade")}
          </title>
          <script src={`https://www.paypal.com/sdk/js?client-id=${clientSecret}&currency=USD&vault=true`}></script>
        </Helmet>
        <PageTitle title="subscriptionRenewal" buttonOn={false} />
        <div className={style.MainContainer}>
          <SubscriptionRenewal
            data={state}
            tabChange={changeTabHandler}
            onSubmit={submitSubscription}
            paymentData={state.payment_methods}
            ewallet={getEwalletData}
            epin={getEpinData}
            alert={state.alert}
            closeAlert={closeAlert}
            getPaypalData={paypalData}
            handleSubscription={handleSubscription}
            checked={checked}
          />
        </div>
      </div>
    );
  
}

export default withTranslation()(withRouter(Subscription));


