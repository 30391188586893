import React from "react";
import style from "../../components/package/package.module.scss";
import { Card } from "react-bootstrap";
export default function NowPaymentFail() {
  return (
    <>
      {" "}
      <div className={`h-100`}>
        <div className={style.MainContainer}>
          <Card style={{ padding: "10px", height: "300px" }}>
            <div style={{ textAlign: "center", padding: "30px" }}>
              <div style={{ color: "red" }}>
                <h3>Payment incomplete</h3>{" "}
              </div>
              <br />
              <strong></strong>
              <br />
              <div>{`Your transaction is incomplete or failed , please try again`}</div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
