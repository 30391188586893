import React, { useEffect, useState } from "react";
import Tabs from "react-responsive-tabs";
import style from "./subscription.module.scss";
import SubscriptionRenewal from "./SubscriptionRenewal";
import Overdue from "./Overdue";
import { useSelector } from "react-redux";
import History from "./History";

const stylees = {
  paddingTop: "10px",
  fontFamily: "system-ui",
};
function Content() {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [tabs, setTabs] = useState([
    "Subscriptions",
    "Overdue Payments",
    "Payment History",
  ]);

  const getTabs = () => {
    return tabs.map((tab, index) => ({
      title: tab,
      getContent: () => getContent(tab),
      /* Optional parameters */
      key: tab,
      tabClassName: style.TabClass,
      panelClassName: style.TabPanel,
      // tabsWrapperClass : 'safdsadfads'
    }));
  };

  const changeHandler = (tab) => {
    getContent(tab);
  };

  const getContent = (contentId) => {
    if (contentId === "Subscriptions") {
      return (
        <div className={style.dataTable} style={stylees}>
          <SubscriptionRenewal />
        </div>
      );
    }
    else if (contentId === "Payment History") {
      return (
        <div className={style.dataTable} style={stylees}>
          <History />
        </div>
      );
    } else {
      return (
        <div className={style.dataTable} style={stylees}>
          <Overdue />
        </div>
      );
    }
  };
  return (
    <div className={style.MainContainer}>
      <div className={style.summary}>
        <div
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_tabContent}`
              : `${style.tabContent}`
          }`}
        >
          <Tabs
            items={getTabs()}
            transform={true}
            transformWidth={734}
            onChange={changeHandler}
            selectedTabKey={"Subscriptions"}
          />
        </div>
      </div>
    </div>
  );
}

export default Content;
