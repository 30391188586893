import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import style from "../view/dashboard./dashboard.module.scss";
//nested data is ok, see accessorKeys in ColumnDef below
import style from "../../components/tree/tree.module.scss";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import _ from "lodash";
import { Button, Col, Row } from "react-bootstrap";
import TreeViewService from "../../../service/tree/treeView";
import { PageTitle } from "../../components/common";
import { TitleHeaders } from "../../components/tree";
import companyName from "../../../store/mobxStore/companyName";
import { FIlterCountry } from "../../components/commission";
import { Filter } from "../../components/ewallet";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const TableView = () => {
  const { t } = useTranslation();
  const curr = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);

  const service = new TreeViewService();
  const [userData, setUserData] = useState();

  //data and fetching state
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  //table state
  const [rowCount, setRowCount] = useState(10);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [filter, setfilters] = useState({
    start_date: moment().startOf("month").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  });

  //should be memoized or stable
  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    filter,
  ]);

  const fetchData = async () => {
    console.log(pagination);
    const start = pagination.pageIndex * pagination.pageSize;
    const length = pagination.pageSize;
    const start_date = filter.start_date;
    const end_date = filter.end_date;
    const country = filter.country;
    const columnFilter = JSON.stringify(columnFilters) ?? "[]";
    const sortFilter = JSON.stringify(sorting) ?? "[]";
    try {
      setIsLoading(true); // Set loading state to true
      const response = await service.iboslist(
        start,
        length,
        start_date,
        end_date,
        country,
        columnFilter,
        sortFilter
      );
      setUserData(response.data);
      setRowCount(response.data.count);
      setIsLoading(false); // Set loading state to false after data fetch
    } catch (error) {
      setIsError(true); // Set error state to true if there's an error
      setIsLoading(false); // Set loading state to false in case of error
      console.error("Error fetching data:", error);
    }
  };
  const array = [
    { key: 1, value: "abh" },
    { key: 2, value: "abh" },
  ];
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: currentTheme=="theme-light"?"light":"dark", //let's use the same dark/light mode as the global theme
          // primary: currentTheme=="theme-light"?"light":"dark", //swap in the secondary color as the primary for the table
          info: {
            main: "#14243a", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default: currentTheme === "theme-dark" ? "#14243a99" : "#fff",
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    pagination[currentTheme]
  );
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id", //access nested data with dot notation
        header: "ID",
        size: 150,
        enableColumnFilter: false,
      },
      {
        accessorKey: "first_name", //access nested data with dot notation
        header: "FIRST NAME",
        size: 150,
      },
      {
        accessorKey: "last_name",
        header: "LAST NAME",
        size: 150,
      },
      {
        accessorKey: "status", //normal accessorKey
        header: "STATUS",
        size: 200,
        enableColumnFilter: false,
        
      },
      {
        accessorKey: "date_of_joinings",
        header: "JOIN DATE",
         filterVariant: "date-range",
        size: 150,
      },
    ],
    []
  );
  const handleExportData = (type) => {
    if (type == "excel") {
      const worksheet = XLSX.utils.json_to_sheet(userData.result);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "ID",
            "FIRST NAME",
            "LAST NAME",
            "DATE",
            "STATUS",
            "MOBILE",
            "EMAIL",
          ],
        ],
        { origin: "A1" }
      );
      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "IBIS.xlsx");
    } else {
      var copiedData = _.cloneDeep(userData.result);

      const fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(copiedData);
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            "ID",
            "FIRST NAME",
            "LAST NAME",
            "DATE",
            "STATUS",
            "MOBILE",
            "EMAIL",
          ],
        ],
        { origin: "A1" }
      );

      const csvOutput = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, "IBIS" + fileExtension);
    }
  };
  const filterType = [
    { key: t("Common.debit"), value: "user_debit" },
    { key: t("Common.credit"), value: "user_credit" },
  ];

  const resetData = () => {
    fetchData();
    setfilters({
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    });
  };
  const filterHandler = (start, end, keys = "", country) => {
    setfilters({
      start_date: start,
      end_date: end,
      country: country,
    });
  };
  const handlePaginationChange = (newPaginationState) => {
    console.log("New Pagination State:", newPaginationState);
    setPagination(newPaginationState);
  };

  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.iboslist")}
        </title>
      </Helmet>
      <PageTitle title="Ibos_list" />
      <div className={style.DownlineMembers}>
        {userData && (
          <>
            <TitleHeaders
              tilesvalue="total_ibos"
              count={userData.totalCount}
              // level={state.totalLevels}
              // searchLevel={searchLevel}
              // reset={reset}
              // searchloader={state.searchloader}
            />
            <div className={style.dataTable}>
              <ThemeProvider theme={tableTheme}>
                <MaterialReactTable
                  columns={columns}
                  data={userData.result}
                  enableHiding={false}
                  onColumnFiltersChange={setColumnFilters}
                  onGlobalFilterChange={setGlobalFilter}
                  onPaginationChange={setPagination}
                  manualPagination={true}
                  manualFiltering={true}
                  manualSorting={true}
                  rowCount={rowCount}
          
                  onSortingChange={setSorting}
                  enableGlobalFilter={false}

                  state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                  }}
                  // rowCount={userData.count}
                  muiTableBodyProps={{
                    sx: {
                      background:
                        currentTheme === "theme-dark" ? "#14243a" : "#fff80",
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      color:
                        currentTheme === "theme-dark" ? "#fff " : "#14243a",
                      borderBottom:
                        currentTheme === "theme-dark"
                          ? ".5px solid #47556560"
                          : "#00090",
                    },
                  }}
                  muiTableHeadCellProps={{
                    sx: {
                      color:
                        currentTheme === "theme-dark" ? "#fff " : "#14243a",
                    },
                  }}
                  renderTopToolbarCustomActions={({ table }) => (
                    <div style={{ width: "100%", gap: 10, padding: "-3px" }}>
                      <Row>
                        <Col md={8} sm={12}>
                          {/* <label>filter by date</label> */}
                          <div
                            style={{
                              padding: 10,
                            }}
                          >
                            {/* <FIlterCountry
                              filterHandler={filterHandler}
                              resetHandler={resetData}
                              isSingle={true}
                              // loader={loader}
                            /> */}
                          </div>
                        </Col>

                        <Col md={4} sm={12}>
                          <div
                            className={style.exportButton}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Button
                              onClick={() => {
                                handleExportData("excel");
                              }}
                            >
                              <i className="fa fa-file-excel-o"></i>
                              Excel
                            </Button>

                            <Button
                              onClick={() => {
                                handleExportData("csv");
                              }}
                            >
                              <i className="fa fa-file-text-o"></i>
                              Csv
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <div style={{ padding: 15, gap: 10 }}></div>

                      {/* </Box> */}
                    </div>
                  )}
                />
              </ThemeProvider>
            </div>
          </>
        )}
      </div>
    </div>
  );
  // return <MaterialReactTable columns={columns} data={data} />;
};

const Iboslist = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TableView />
  </LocalizationProvider>
);
export default Iboslist;
