import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IMG_URL } from "../../../../configuration/apiconfig";
import { CustomButton, FormInputControl, AlertBs } from "../../common";
import style from "../tools.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function BannerInvites(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [state, setState] = useState({
    show: false,
    varient: "success",
    message: "validation.bannerInviteUrlCopied",
  });
  const copyText = (id) => {
    var tempInput = document.getElementById(id);
    tempInput.select();
    var textField = document.createElement("textarea");
    textField.innerText = tempInput.textContent;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setState((prev) => ({
      ...prev,
      show: true,
    }));
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        show: false,
      }));
    }, 3000);
  };
  const banners = props.banners.map((banner, key) => {
    return (
      <Row key={key}>
        <Col className="mb-5">
          <article className={style.bannerItem}>
            <div className={style.bannerImage}>
              <img
                src={`${banner.content}`}
                alt="Banner Images"
                className="img-ban"
              />
            </div>
            <div className={style.bannerContent}>
              <div className={style.postHeader}>
                <h2 className={style.postTitle}>{banner.subject}</h2>
                <FormInputControl
                inputClass={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_textArea}`
                      : `${style.textArea}`
                  }`}
                  inputProps={{
                    as: "textarea",
                    row: 3,
                    id: `banner${banner.id}`,
                    disabled: true,
                    value: `<a href=${banner.target_url}><img src="${banner.content}" height="150" width="250"></a>`,
                  }}
                ></FormInputControl>
                <CustomButton
                  variant="primary"
                  onClick={() => {
                    copyText(`banner${banner.id}`);
                  }}
                >
                  {t("Button.copy")}
                </CustomButton>
              </div>
            </div>
          </article>
        </Col>
      </Row>
    );
  });
  return (
    <Card>
      <Card.Header>
        <AlertBs {...state} />
      </Card.Header>
      <Card.Body>{banners}</Card.Body>
    </Card>
  );
}

export default BannerInvites;
