import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import companyName from "../../../../store/mobxStore/companyName";
import Forms from "../../../components/auth/Forms";
import ForgotPasswordService from "../../../../service/Auth/ForgotPassowrd";
import ForgotButton from "../../../components/auth/ForgotButton";
import { BASE } from "../../../../configuration/apiconfig";
import { Alert, Form } from "react-bootstrap";
import { AlertMessage } from "../../../components/common";
import { useParams, useHistory } from "react-router-dom";

function ForgotPassword({ t }) {
  const history = useHistory();
  var captchvalue;
  const recaptchaRef = React.createRef();

  let styles = {
    marginTop: "-1px",
    marginLeft: "23px",
    color: "#a94442",
    fontSize: "12px",
  };
  const service = new ForgotPasswordService();

  const notifyInintialState = {
    show: false,
    message: "",
    type: "",
    header: "",
  };

  const [state, setState] = useState({
    userName: "",
    Email: "",
    captcha: "",
    captchvalue: "",
    isLoggedError: false,
    notify: notifyInintialState,
    captchaImage: "",
    FormError: {
      userName: "",
      Email: "",
    },
      FormReqError: {
      userName: "",
      Email: "",
    },
    btnDisabled: false,
  });
  const [show, setShow] = useState(false);

  // useEffect(()=>{
  //  let adminName = localStorage.getItem('adminname');
  //   service.getApi(adminName).then((res) => {
  //     if (res.status) {
  //       localStorage.setItem('apiKey', res.data.key);
  //     }
  //     else {
  //       alert("Invalid Admin user name");
  //     }
  //   })
  // },[])

  const formValid = (formError) => {
    let valid = Object.values(formError).forEach((val) => val.length > 0);
    console.log("formError", valid);
    return valid;
  };

  onchange = (e) => {
    const service = new ForgotPasswordService();
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const blurhandler = (name) => {
    if (name === "userName") {
      const formData = {
        user_name: state.userName,
      };
      service.checkUsername(formData).then((res) => {
        if (!res.status) {
          if (res.error.code == "1070") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                userName: "Username_not_exists",
              },
            }));
          }
        } else {
          // reloadCaptcha(state.userName);
          setState((prevState) => ({
            ...prevState,
            FormError: {
              ...prevState.FormError,
              userName: "",
            },
          }));
        }
      });
    }
    if (name === "Email") {
      const formData = {
        e_mail: state.Email,
        user_name: state.userName,
      };
      service.checkUsermail(formData).then((res) => {
        if (!res.status) {
          console.log(res);
          if (res.error.code == "1048") {
            setState((prevState) => ({
              ...prevState,
              FormError: {
                ...prevState.FormError,
                Email: "email_not_exists",
              },
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            FormError: {
              ...prevState.FormError,
              Email: "",
            },
          }));
        }
      });
    }
  };

  const requiredValid = (value, name) => {
    const FormError = state.FormReqError;
    switch (name) {
      case "userName":
        FormError.userName = value.length === 0 ? "unamereq" : "";
        break;
      case "Email":
        FormError.Email = value.length === 0 ? "emailreq" : "";
        const formData = {
          e_mail: state.Email,
          user_name: state.userName,
        };
        // service.checkUsermail(formData).then((res) => {
        //   if (!res.status) {
        //     // FormError.Email = "email_not_exists"
        //     if (res.error.code == "1048") {
        //       setState((prevState) => ({
        //         ...prevState,
        //         FormError: {
        //           ...prevState.FormError,
        //           Email: "email_not_exists",
        //         },
        //       }));
        //     }
        //   }

        // });
        break;
      // case "captcha":
      //   FormError.captcha = value.length === 0 ? "captchareq" : "";
      //   break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      FormError,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault(); //prevent default
    const formErrors = Object.values(state.FormError);
    const hasError = formErrors.some((error) => error !== "");
    if (!hasError) {
      const formData = {
        username: state.userName,
        email: state.Email,
      };
      service.forgot_password(formData).then((res) => {
        if (res.status) {
          showNotify("success", "Common.success", "validation.captchaSuccess");
          setTimeout(() => {
            history.push("/login");
          }, 5000);
        } else {
          showNotify("danger", t("Common.error"), res?.error?.description);
        }
      });
    }
  };

  const showNotify = (type, header, message) => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: true,
        type: type,
        header: t(header),
        message: t(message),
      },
    }));
  };

  // const reloadCaptcha = (userName) => {
  //   service.getCaptcha(userName).then((res) => {
  //     let outside = URL.createObjectURL(res);
  //     setState((prev) => ({
  //       ...prev,
  //       captchaImage: outside,
  //     }));
  //   });
  // };
  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      notify: notifyInintialState,
    }));
  };

  const CaptchaChage = (value) => {
    setState((prevState) => ({
      ...prevState,
      captcha: value,
      FormError: {
        ...prevState.FormError,
        captcha: "",
      },
    }));
  };

  return (
    <>
      <div className="mb-2">
        <Helmet>
          <title>
            {companyName.companyName} | {t("Common.forgotPassword")}
          </title>
        </Helmet>

        {state.notify.show && (
          <AlertMessage
            type={state.notify.type}
            message={state.notify.message}
            show={state.notify.show}
            dismiss={notifyDissmissed}
          />
        )}
        <form id="forgot-form">
          <Forms
            type="text"
            name="userName"
            change={onchange}
            error={state.FormError.userName}
            reqError = {state.FormReqError.userName}
            blurhandler={blurhandler}
            from={"forgot"}
          />
          <Forms
            type="email"
            name="Email"
            change={onchange}
            error={state.FormError.Email}
            reqError={state.FormReqError.Email}
            blurhandler={blurhandler}
            from={"forgot"}
          />

          <ForgotButton submit={onSubmit} />
        </form>
      </div>
    </>
  );
}
export default withTranslation()(ForgotPassword);
