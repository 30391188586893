import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import Spinner from '../../shared/Spinner';
import { IMG_URL } from '../../../configuration/apiconfig';
import { SetupContent,GuestContent,HostContent } from '../../components/party';
import PartySetupService from '../../../service/party/setUp';
import companyName from '../../../store/mobxStore/companyName';
import { useHistory } from 'react-router';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
import { withRouter } from 'react-router-dom';




function Host({ t }) {
    let service = new PartySetupService()
    let history = useHistory()


    const [state, setState] = useState({
        loading: false,
        data: {
            host: [],
            guest:[],
        },
        partyContent: {
            name: '',
            image: IMG_URL + "uploads/images/party_image/party_image.png",
            uploadFile: '',
            host: 'old',
            oldHost: '',
            country: [],
            states: [],
            from: '',
            startTime: '',
            to: '',
            endTime: '',
            address: 'host_address',
            FormError: {
                name: ""
            },
            newHost: {
                firstName: '',
                lastName: '',
                address: '',
                phone: '',
                country: '',
                state: '',
                city: '',
                zip: '',
                email: '',
                FormError: {
                    firstName: '',
                    lastName: '',
                    address: '',
                    phone: '',
                    country: '',
                    state: '',
                    city: '',
                    zip: '',
                    email: ''
                }
            },
            newAddress: {
                firstName: '',
                lastName: '',
                address: '',
                phone: '',
                country: '',
                state: '',
                city: '',
                zip: '',
                email: '',
                FormError: {
                    firstName: '',
                    lastName: '',
                    address: '',
                    phone: '',
                    country: '',
                    state: '',
                    city: '',
                    zip: '',
                    email: ''
                }
            }
        }

    })

    const getHost = () =>{
        service.getHost().then(res => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    data: {
                        ...state.data,
                        host:res.data.result
                    }
                }));

            } else {
                if (res.error.code === 1002) {
                    history.push('/logout');
                }
            }
        });
    }
    useEffect(() => {
        getHost();

        service.getCountry().then(res => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    partyContent: {
                        ...state.partyContent,
                        country: res.data.country
                    }
                }));

            } else {
                history.push('/logout')
            }
        })


    }, []);

    const changeHandler = (e, isNew = false, key = '') => {
        const { name, value } = e.target;
        if (name === 'country') {
            service.countryChange(value).then(res => {
                if (res.status) {
                    setState(prevState => ({
                        ...prevState,
                        partyContent: {
                            ...prevState.partyContent,
                            states: res.data

                        }
                    }))
                    // setState(prevState => ({
                    //     ...prevState,
                    //     partyContent:{
                    //         ...state.partyContent,
                    //         states:"America"
                    //     }

                    // }))
                  

                } else {
                    history.push('/logout')
                }
            })

        }
        if (isNew) {

            setState({
                ...state,
                partyContent: {
                    ...state.partyContent,
                    [key]: {
                        ...state.partyContent.newHost,
                        [name]: value
                    }

                }
            })


        } else {

            setState(prevState => ({
                ...prevState,
                partyContent: {
                    ...state.partyContent,
                    [name]: value
                }
            }))
        }
    }

    const removeImage = () => {
        setState({
            partyContent: {
                ...state.partyContent,
                uploadFile: '',
                image: IMG_URL + "uploads/images/party_image/party_image.png"
            }
        })
    }
    const changeFile = (e) => {
        let reader = new FileReader();
        reader.onload = event => {
            setState(prevState => ({
                ...prevState,
                partyContent: {
                    ...state.partyContent,
                    uploadFile: e.target.files[0],
                    image: event.target.result
                }

            }))

        }
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    const createParty = () => {
        let isvalid = true;
        let content = state.partyContent;
        //validation the party name
        if (content.name === '') {
            isvalid = false;
            content.FormError.name = {
                error: 'validation.required',
                field: {
                    field: 'partyName'
                }
            }
        }
        //validation the step Two
        if (content.host === 'old') {

        }
        if (isvalid) {

        } else {
            setState({
                partyContent: {
                    ...content
                }
            })
        }
    }
    const deleteHost = (id) => {
        service.deleteHost(id).then((res)=>{
            if(res.status){
                getHost();
            }

        })

     
    }
    if (state.loading) {
        return <Spinner />
    }
    return (

        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.HostManagement')}</title>
            </Helmet>
            <PageTitle title="HostManagement" buttonOn={false} />
            <HostContent
                {...state}
                changeHandler={changeHandler}
                changeFile={changeFile}
                removeImage={removeImage}
                createParty={createParty}
                deleteHost={deleteHost}
                getHost={getHost}
            />
        </div>
    )
}
export default withTranslation()(withRouter(Host));