import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../../../store/action";
import UserStore from "../../../../store/UserStore";
import LoginService from "../../../../service/Auth/Login";
function Logout() {
  const history = useHistory();
  const dispatch = useDispatch();
  const service = new LoginService();
  useEffect(() => {
    service.logOut().then((res) => {
      if (res.status) {
        dispatch(logout());
        localStorage.removeItem("login");
        localStorage.removeItem("apiKey");
        localStorage.removeItem("currency");
        UserStore.key = "";
        history.push("/login");
        window.location.reload();
      } else {
        dispatch(logout());
        localStorage.removeItem("login");
        localStorage.removeItem("apiKey");
        localStorage.removeItem("currency");
        UserStore.key = "";
        history.push("/login");
        window.location.reload();
      }
    });
  });
  return <div></div>;
}

export default Logout;
