import React, { useRef } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import style from "./history.module.scss";
import { useTranslation } from "react-i18next";
//import {CustumBtnGroup} from '../common';
import moment from "moment";
import ReactToPrint from "react-to-print";
import OrderHistoryPrintView from "./OrderHistoryPrintView";
import { useSelector } from "react-redux";

function Invoice(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const componentRef = useRef();
  // const printInvoice = () =>{

  // }

  let Bv_status = {
    0: "Pending",
    1: "Added",
    2: "Accepted",
  };
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      contentClassName={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_modalContent}`
          : `${style.modalContent}`
      }`}
    >
      <Modal.Header
        closeButton
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_modalHeader}`
            : `${style.modalHeader}`
        }`}
      >
        <Modal.Title>{t("Common.invoice")}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ background: currentTheme === "theme-dark" ? "" : "#fff" }}
      >
        <div className={`${style.invoiceBody}`}>
          <div className={`${style.printButton}`}>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <i className="fa fa-print"></i>
                  {t("Button.print")}
                </Button>
              )}
              content={() => componentRef.current}
            />

            <div style={{ display: "none" }}>
              <OrderHistoryPrintView ref={componentRef} {...props} />
            </div>
          </div>
          <div className={`${style.printArea}`}>
            <Table responsive borderless width="700" height="100">
              <tbody>
                <tr>
                  <td colSpan="2">
                    <h3>
                      <b>
                        INVOICE FOR ORDER :
                        <font color="#7266ba">
                          #{props?.data["details.order_id"]}
                        </font>
                      </b>
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <b>Ordered &nbsp;</b>
                    {moment(props.data.order_date).format("MMMM  DD , YYYY")}
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      lineHeight: "18px",
                    }}
                  >
                    <h4>Shipping Address</h4>
                    <br />
                    {props.data.address} <br />
                    {props.data.city} <br />
                    Mobile:{props.data.mobile}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h2>
                      <b>{t("Common.orderProducts")} </b>
                    </h2>
                    <hr />
                    <Table bordered striped hover className={style.bordered}>
                      <thead>
                        <tr>
                          <th>{"Date"}</th>
                          <th>{t("profile.product")}</th>
                          <th>{t("Common.quantity")}</th>
                          <th>{"BV"}</th>
                          <th>{"Status"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {moment(props.data.order_date).format(
                              "MMMM  DD , YYYY"
                            )}
                          </td>
                          <td>{props.data["details.pack1.name"]}</td>
                          <td>{1}</td>
                          <td>{props.data.bv}</td>
                          <td>{Bv_status[props.data["order_status"]]}</td>
                        </tr>

                        <tr>
                          <td colSpan="6">
                            <hr />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <table
                      border="0"
                      width="30%"
                      height="100"
                      align="right"
                      className={style.tableBottom}
                    >
                      <tbody>
                        <tr>
                          <td width="60%">
                            <h6>Total</h6>
                          </td>
                          <td>
                            {props.currency.currentCurr}
                            {(
                              parseInt(props.data.product_amount) *
                              props.currency.value
                            ).toFixed(props.currency.precision)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Invoice;
