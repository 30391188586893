import React, { useState } from "react";
import { Form, Button ,Card} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./payment.module.scss";
import Payments from "../../../service/common/PaymentApi";
import { FormInputControl } from "../common";
import { useSelector } from "react-redux";

function PaymentHistories(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new Payments();
  const { t } = useTranslation();
  const [state, setState] = useState({
    username: "",
    transpassword: "",
    FormError: {
      username: "",
      transpassword: "",
      bala: false,
    },
    isvalid: false,
    loader: false,
  });

  const submitHandler = (e) => {
    window.location.pathname.includes("/register") ||
    window.location.pathname.includes("/replica_register")
      ? props.submit(e, "", "payment")
      : props.submit(e, "");
      setState({
        ...state,
        username:"",
        transpassword:""
      })
  };

  //change handler
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      FormError: {
        ...prev.FormError,
        [name]: "",
      },
      isvalid: false,
    }));
  };

  //check the ewallet balance
  const checkAvailable = () => {
    let valid = true;
    if (!state.username) {
      valid = false;
      setState((prev) => ({
        ...prev,
        FormError: {
          ...prev.FormError,
          username: t("validation.required", { field: "userName" }),
        },
      }));
    }
    if (!state.transpassword) {
      valid = false;
      setState((prev) => ({
        ...prev,
        FormError: {
          ...prev.FormError,
          transpassword: t("validation.required", {
            field: "transactionPassword",
          }),
        },
      }));
    }
    if (valid) {
      let userData = {
        user_name: state.username,
        ewallet: state.transpassword,
        product_id: props.product,
        payment_type: props.payment_type,
      };
      service.ewalletavailability(userData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            isvalid: true,
            FormError: {
              transpassword: "validation.validEwalletDetails",
              username: "",
            },
          }));
          props.EwalletData(state.username, state.transpassword);
        } else {
          if (res.error.code === 1039 || res.error.code === 1003) {
            setState((prev) => ({
              ...prev,
              FormError: {
                ...prev.FormError,
                transpassword: t("validation.invalidTransactionDetails"),
              },
            }));
          } else if (res.error.code === 1014) {
            setState((prev) => ({
              ...prev,
              FormError: {
                ...prev.FormError,
                bala: true,
              },
            }));
          }
        }
      });
    }
  };
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_MianContent}`
          : `${style.MianContent}`
      }`}
    >
        <Card style={{margin:"20px",padding:"10px"}}>

      <table class="table">
  <thead class="thead-dark">
    <tr>
      <th scope="col">#</th>
      <th scope="col">PAYMENT ID</th>
      <th scope="col">TYPE</th>
      <th scope="col">PAYLOAD</th>
      <th scope="col">RESPONSE</th>
      <th scope="col">STATUS</th>
       <th scope="col">DATE</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
           <td><textarea
           style={{maxHeight:'130px',maxWidth:"500px"}}
  name="textarea"
  rows="5"
  cols="30"
  placeholder="Comment text."></textarea></td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
   
  </tbody>
</table>
        </Card>


    </div>
  );
}

export default PaymentHistories;
