import React, { useCallback, useEffect, useState } from "react";
import style from "../crm/crm.module.scss";


import { Form } from "react-bootstrap";
import {
  DatePickers,
  FormInputControl,
  DropDownBs,
  CustomButton,
  AlertBs,
} from "../common";
//import moment from 'moment';
//service
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CrmService from "../../../service/crm/Crm";
import AppinfoService from "../../../service/common/Appinfo";
//cookie
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

const InitialForm = {
  otp: "",

  formError: {
    otp: "",
  },
  editLoader: false,
};
function VerificationForm(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const visitorid = localStorage.getItem("visitorid");
  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState({
    ...InitialForm,
    alert: {
      show: false,
      message: "",
      varient: "",
    },
    editLoader: false,
  });
  const commonService = new AppinfoService();
  //intial loading the country

  // const [cookies, setCookie] = useCookies(['name']);

  // //get the edit data
  // useEffect(() => {
  //     const { data, id } = props;

  //     setState(prev => ({
  //         ...prev,
  //         id: id,
  //         ...data
  //     }));
  //     // if(id){
  //     //     const addStatus = status;
  //     //     addStatus.push({
  //     //         id : 'Accepted'
  //     //     })
  //     //     setStatus(addStatus)
  //     // }
  // }, [props, status])

  //changeHandler
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      formError: {
        ...prev.formError,
        [name]: "",
      },
    }));
  };

  function setCookie1(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  //submit the
  const onSubmitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    let FormError = {};
    //validation in otp
    // if (!state.otp) {
    //     isValid = false;
    //     FormError.otp = {
    //         error: 'validation.required',
    //         field: {
    //             field: 'OTP'
    //         }
    //     }
    // }

    if (isValid) {
      // let FormData = JSON.parse(JSON.stringify(state));
      // delete FormData.formError;
      // delete FormData.alert;
      // delete FormData.countryList;

      // let formData = new FormData();
      let formData = {};

      formData.demo_otp = state.otp;
      formData.visitor_id = visitorid;
      
      // formData.append("demo_otp", state.otp);
      // formData.append("visitor_id", visitorid);
      // let formData = {};
      // formData['demo_otp'] = state.otp;
      // formData['visitor_id'] = visitorid

      //     formData.append("visitor_id", props.visitorid)

      setState((prevState) => ({
        ...prevState,
        editLoader: true,
      }));
      commonService.VerifyOtp(formData).then((res) => {
        setState((prevState) => ({
          ...prevState,
          editLoader: false,
        }));
        if (res.status) {
          //set cookies

          // setCookie('name', visitorid, { path: '/' });

          setCookie1("visitor", visitorid, 1);
          props.setstate((prevState) => ({
            ...prevState,
            modal: {
              title: "",
              show: false,
              data: {},
              id: "",
            },
          }));
          localStorage.removeItem("visitorid");
        } else {
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              message: res.error.description,
              varient: "danger",
            },
          }));
        }
      });
    }
    // else{
    //     setState(prev => ({
    //         ...prev,
    //         formError: {
    //             ...prev.formError,
    //             ...FormError
    //         },
    //         editLoader: false,
    //         alert: {
    //             show: true,
    //             varient: 'danger',
    //             message: 'validation.checkValueYouHaveSubmitted'
    //         }
    //     }))
    // }
  };

  //ReSend Handler
  const resendHandler = () => {
    // let formData = new FormData();
    let formData = {};

    // formData.append("visitor_id", visitorid);
    formData.visitor_id = visitorid;

    // let formData = {};
    // formData['visitor_id'] = visitorid

    commonService.ResendOtp(formData).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          alert: {
            show: true,
            message: res.data.message,
            varient: "success",
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          alert: {
            show: true,
            message: res.error.description,
            varient: "danger",
          },
        }));
      }
    });
  };

  //close alert
  const closeAlert = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };
  return (
    <div className={`${
      currentTheme == "theme-dark"
        ? `${style.dark_mode_panel} ${style.papanel_defaultnel}`
        : `${style.panel} ${style.papanel_defaultnel}`
    }`}>
      <div
        className={`${style.panel_body} ${style.otp_pop_sc}`}
        style={{ padding: "14%" }}
      >

        <AlertBs {...state.alert} close={closeAlert} />
        <Form onSubmit={onSubmitForm}>
          <FormInputControl
            inputProps={{
              type: "text",
              name: "otp",
              placeholder: "Enter OTP sent to your email",
              value: state.otp,
              onChange: changeHandler,
            }}
          />
          <div style={{ textAlign: "center" }}>
            <div>
              If you don't receive a code
              {/* <div><a  style={{textDecoration : "none",color :"lightblue"}} onClick={resendHandler}>Resend</a></div> */}
              <div
                style={{
                  border: "none",
                  background: "transparent",
                  color: "#3d78e4",
                  display: "inline-block",
                  marginBottom: "20px",
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
                onClick={resendHandler}
              >
                {" "}
                Resend
              </div>
            </div>

            <CustomButton type="submit" variant="primary" disabled={state.editLoader}>
              {state.editLoader ? (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              ) : (
                ""
              )}

              {"Verify OTP"}
            </CustomButton>
          </div>
        </Form>
      </div>
    </div>
  );
}
export default VerificationForm;
