import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/common/pageTitle";
//style
//components
import { KycContent } from "../../components/profile";

//service
import ProfileService from "../../../service/profile";
import companyName from "../../../store/mobxStore/companyName";
//import { useHistory } from 'react-router';

function Kyc({ t }) {
  let service = new ProfileService();
  // let history = useHistory();

  const [state, setState] = useState({});

  const loadData = () => {
    service.getKYCDetails().then((res) => {
      if (res.status) {
        setState(res.data);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.kycDetails")}
        </title>
      </Helmet>
      <PageTitle title="kycDetails" buttonOn={false} />
      <KycContent data={state} loadData={loadData} />
    </div>
  );
}
export default withTranslation()(withRouter(Kyc));
