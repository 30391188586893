import React, { useState ,useEffect} from "react";
import style from "./network.module.scss";
import { useHistory } from "react-router-dom";
import { SearchContent, SponsorContent } from "./";
import { useSelector } from "react-redux";
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
function SponsorTreeContent(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const username = useSelector((state) => state.ProjectConfig.userName);
  const [error, setError] = useState(false);
  const [searchExpand, setSearchExpand] = useState(false);
  const [state, setState] = useState({
    username: username,
    error: {
      error: false,
    },
    searchloader: false,
    resetloader: false,
  });
  //search button click
  const searchClick = (e) => {
    e.preventDefault();
    setSearchExpand(true);
    setState((prev) => ({
      ...prev,
      searchloader: true,
    }));
    setTimeout(() => {
      if (state.username) {
        props.fileterUser(state.username);
      }
      setState((prev) => ({
        ...prev,
        searchloader: false,
      }));
    }, 2000);
  };
  //change handler
  const change = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      username: value,
    }));
  };

  useEffect(() => {
    if (history.location.state && history.location.state.error) {
      setError(true);
    }else{
      setState({
        ...state,
        username:username,
      })
    }
  }, [history.location.state]);
  //click the referral members
  const clickDownLine = () => {
    history.push("/referralMembers");
  };
  const resetButtonClick = () => {
    setSearchExpand(true);
    props.resetButtonClick();
    setState((prev) => ({
      ...prev,
      resetloader: true,
    }));
    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        resetloader: false,
        username: username,
      }));
    }, 2000);
  };
  return (
    <div className={style.MianContent}>
      {error && history.location.state && (
        <Alert variant="danger" onClose={() => setError(false)} dismissible>
          {t("validation." + history.location.state.message)}
        </Alert>
      )}
      <SearchContent
        searchClick={searchClick}
        changeHandler={change}
        buttonClick={clickDownLine}
        buttonLabel="referralMembers"
        reset={resetButtonClick}
        resetloader={state.resetloader}
        searchloader={state.searchloader}
        {...state}
      />
      {props.data && (
        <SponsorContent
          {...props}
          searchExpand={searchExpand}
          setSearchExpand={setSearchExpand}
        />
      )}
    </div>
  );
}

export default SponsorTreeContent;
