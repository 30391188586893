export default  function CurrencyDecimalFormat(amount,precision)  {
    if(amount > 10000){

        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "K" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "G" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
          ];
          var i;
          for (i = si.length - 1; i > 0; i--) {
            if (amount >= si[i].value) {
              break;
            }
          }
          return numberWithCommas((amount / si[i].value).toFixed(2)) + si[i].symbol
          
    }else{

        return numberWithCommas(amount.toFixed(precision))
    }
  }
  function numberWithCommas(x) {
    return ( new Intl.NumberFormat().format(x))
    // return (num);
}