import React, { useState } from 'react';
import {Row,Col, Button,Form} from 'react-bootstrap';
import {FormInputControl,AlertBs} from '../common';
import style  from './payment.module.scss';
import  {useTranslation} from 'react-i18next';
import Payments from '../../../service/common/PaymentApi';
import {useHistory} from 'react-router-dom';
function PurchaseWallet(props) {
    const {t}  =useTranslation();
    const history = useHistory();
    const service = new Payments();
    const [state,setState] = useState({
        username : '',
        password : '',
        formError : {
            username : {
                error : ''
            },
            password : {
                error : ''
            }
        },
        isValid : false,
        alert:{
            show : false,
            varient : '',
            message : ''
        }
    })
    //chanhe handler
    const changeHandler  = (e) =>{
        const {name,value} =e.target;
        setState(prev=>({
            ...prev,
            [name] : value,
            formError : {
                ...prev.formError,
                [name] : {
                    error : ''
                }
            }
        }))
    }
    //check the availability
    const checkAvailability =() =>{
        //validaion
        let isValid = true;
        if(!state.username){
            isValid = false;
            setState(prev=>({
                ...prev,
                formError : {
                    ...prev.formError,
                    username : {
                        error : 'validation.required',
                        field : {
                            field : 'userName'
                        }
                    }
                }
            }))
        }
        if(!state.password){
            isValid = false;
            setState(prev=>({
                ...prev,
                formError : {
                    ...prev.formError,
                    password : {
                        error : 'validation.required',
                        field : {
                            field : 'password'
                        }
                    }
                }
            }))
        }
        if(isValid){
            const formData = {
                user_name : state.username,
                ewallet : state.password,
                amount  : props.amount
            }
            service.purchaseWalletAvailable(formData).then(res=>{
                if(res.status){
                    setState(prev=>({
                        ...prev,
                        isValid  : true,
                        alert : {
                            show : true,
                            varient : 'success',
                            message : 'validation.validTransactionDetails'
                        }
                    }));
                props.purchaseWallet(state.username,state.password);
                }else{
                    if(res.error.code === 1002){
                        history.push('/logout');
                    }else if(res.error.code === 1039){
                        setState(prev=>({
                            ...prev,
                            formError : {
                                ...prev,
                                password  : {
                                    error : 'validation.invalidTransactionDetails'
                                }
                            }
                        }))
                    }else if(res.error.code === 1025){
                        setState(prev=>({
                            ...prev,
                            formError : {
                                ...prev,
                                password  : {
                                    error : 'validation.insufficientBalance'
                                }
                            }
                        }))
                    }
                }
            })
        }
    } 

    //close alert message
    const closeAlert =() =>{
        setState(prev=>({
            ...prev,
            alert : {
                show : false,
                varient : '',
                message  : ''
            }
        }))
    }
    return (
        <div className={`${style.MianContent} ${style.PurchaseWallet}`}>
            <AlertBs
                {...state.alert}
                close={closeAlert}
            />
            <Row>
            <Form  onSubmit={(e)=>{  window.location.pathname.includes('/register')  || window.location.pathname.includes('/replica_register') ?   props.submit(e,"","payment"): props.submit(e,"")}} >
                <Col lg={12} className={style.purchaseCol}>
                        <FormInputControl
                            label={t('profile.userName')}
                            required={true}
                            error={state.formError.username}
                            textIcon={true}
                            inputProps={{
                                name : 'username',
                                type : 'text',
                                value : state.username,
                                onChange : changeHandler
                            }}
                        />
                </Col>
                <Col lg={12} className={style.purchaseCol}>
                        <FormInputControl
                            label={t('profile.password')}
                            required={true}
                            error={state.formError.password}
                            inputProps={{
                                name : 'password',
                                type : 'password',
                                value : state.password,
                                onChange : changeHandler
                            }}
                        />
                </Col>
                <Col lg={12} className={style.purchaseCol}>
                    <div className={style.checkAvailability}>
                        <Button 
                            className={style.checkAvailabityBtn}
                            onClick={checkAvailability}
                            >
                            {t('Button.checkAvailability')}
                        </Button>
                    </div>
                </Col>
                <Col md={12}>
                    <div className={style.Buttons}>
                        {
                            style.PrevButton&&
                            <Button className={style.PrevButton} onClick={props.prevButtonClick}>{t('profile.prev')}</Button>
                        }
                        <Button className={style.NextButton} disabled={!state.isValid} type="submit">{t('Button.finish') }</Button>
                    </div>
                </Col>
                </Form>
            </Row>
        </div>
    )
}

export default PurchaseWallet
