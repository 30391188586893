import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
export default class OrderService {
  key = localStorage.getItem("apiKey");
  async getOrderHistory(start, length, columnFilter, sortFilter) {
    const response = await fetch(
      BASE_URL +
        "order/order_history?" +
        "limit=" +
        length +
        "&offset=" +
        start +
        "&cfilter=" +
        columnFilter +
        "&sortFilter=" +
        sortFilter,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    return await response.json();
  }
}
