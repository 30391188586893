import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import ticketService from "../../../service/ticket/ticket";
import { withRouter } from "react-router-dom";
import TicketDetailsContent from "../../components/support/TicketDetailsContent";
import companyName from "../../../store/mobxStore/companyName";

function TicketDetails(props) {
  const service = new ticketService();

  const [state, setState] = useState({
    loader: true,
    alert: {
      show: false,
      message: "",
      varient: "",
    },
    ticket_id: props.match.params.id,
    details: {},
    replies: [],
    new_message: "",
    new_attachment: "",
  });

  const getTicketDetails = (ticket_id) => {
    service.getTicketDetails(ticket_id).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          details: res.data.details,
          replies: res.data.ticket_replies,
        }));
      }
    });
  };

  const addMessage = (e) => {
    setState((prevState) => ({
      ...prevState,
      new_message: e.target.value,
    }));
  };

  const saveMessage = () => {
    const form_data = new FormData();
    form_data.append("message", state.new_message);
    form_data.append("ticket_id", state.ticket_id);
    form_data.append("row_id", state.details.id);
    if (state.new_attachment) {
      form_data.append("attachment", state.new_attachment);
    }
    service.saveMessage(state.ticket_id, form_data).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          new_message: "",
          new_attachment: "",
          alert: {
            show: true,
            message: "validation.messageSendedSuccessfully",
            varient: "success",
          },
        }));
        getTicketDetails(state.ticket_id);
        window.scrollTo(0, 0);
      } else {
        let msg = "";
        if (res.error.code === 1004) {
          msg = "Please enter message";
        } else {
          msg = res.error.description;
        }
        setState((prevState) => ({
          ...prevState,
          alert: {
            show: true,
            message: msg,
            varient: "danger",
          },
        }));
        window.scrollTo(0, 0);
      }
    });
  };

  const addAttachment = (e) => {
    setState((prevState) => ({
      ...prevState,
      new_attachment: e.target.files[0],
    }));
  };

  const closeAttachment = () => {
    setState((prevState) => ({
      ...prevState,
      new_attachment: "",
    }));
  };

  //   componentDidMount() {
  //    setState((prevState) => ({
  // 					...prevState,
  //       loader: false,
  //     });

  //     getTicketDetails(state.ticket_id);
  //   }

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      loader: false,
    }));

    getTicketDetails(state.ticket_id);
  }, []);

  
    const closeAlert = () => {
     setState((prevState) => ({
  					...prevState,
        alert: {
          show: false,
          varient: "",
          message: "",
        },
      }));
    };
     //Commenting closeAlert for Time being as it's not used anywhere

  const { t } = props;
  if (state.loader) {
    return <Spinner />;
  }

  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.ticketDetails")}
        </title>
      </Helmet>
      <PageTitle
        title="ticketDetails"
        buttonOn={false}
        AddonIcon={true}
        icon="fa fa-bar-chart"
        group={props.ButtonGroup}
      />

      <TicketDetailsContent
        {...state}
        addMessage={addMessage}
        saveMessage={saveMessage}
        addAttachment={addAttachment}
        closeAttachment={closeAttachment}
        closeAlert ={closeAlert}
      />
    </div>
  );
}
export default withTranslation()(withRouter(TicketDetails));
