import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import style from "../../components/tree/tree.module.scss";
import { Form, Row, Col } from "react-bootstrap";

import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import _ from "lodash";
import { Button } from "react-bootstrap";
import TreeViewService from "../../../service/tree/treeView";
import { PageTitle } from "../../components/common";
import { TitleHeaders } from "../../components/tree";
import companyName from "../../../store/mobxStore/companyName";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";

import Filter from "./Filter";

const MypersonalcustWall = () => {
  const { t } = useTranslation();
  const curr = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new TreeViewService();
  const [userData, setUserData] = useState();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [type, setType] = useState({
    value: "customer",
    label: "Cutsomer",
  });
  const [date, setDate] = useState({
    start: moment().startOf("month"),
    end: moment(),
  });

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: currentTheme=="theme-light"?"light":"dark", //let's use the same dark/light mode as the global theme
          // primary: currentTheme=="theme-light"?"light":"dark", //swap in the secondary color as the primary for the table
          info: {
            main: "#14243a", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default: currentTheme === "theme-dark" ? "#14243a99" : "#fff",
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [currentTheme]
  );

  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const fetchData = (start_date = "", end_date = "") => {
    const start = pagination.pageIndex * pagination.pageSize;
    const length = pagination.pageSize;

    service
      .PersonalcustWall(start, length, type, date.start, date.end)
      .then((res) => {
        setUserData(res.data);
      });
  };

  const submitHandler = () => {
    fetchData();
  };

  const setDateOptions = (start, end) => {
    setDate((prev) => ({
      ...prev,
      start: start,
      end: end,
    }));
  };
  const resetHandler = (start_date, end_date) => {
    setType((prev) => ({
      ...prev,
      value: "customer",
      label: "Cutsomer",
    }));

    const start = pagination.pageIndex * pagination.pageSize;
    const length = pagination.pageSize;

    setDate((prev) => ({
      ...prev,
      start: start_date,
      end: end_date,
    }));

    let type = {
      value: "customer",
      label: "cutsomer",
    };

    service
      .PersonalcustWall(start, length, type, start_date, end_date)
      .then((res) => {
        setUserData(res.data);
      });
  };

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id", //normal accessorKey
        header: " ID",
        size: 100,
      },
      {
        accessorKey: "fisrt_name", //access nested data with dot notation
        header: "FIRST NAME",
        size: 150,
      },
      {
        accessorKey: "last_name",
        header: "LAST NAME",
        size: 150,
      },

      {
        accessorKey: "email",
        header: "EMAIL",
        size: 150,
      },

      {
        accessorKey: "phone",
        header: "PHONE",
        size: 150,
      },

      {
        accessorKey: "service",
        header: "SERVICE",
        size: 150,
      },
      // {
      //   accessorKey: "pendingbv",
      //   header: "PENDING BV",
      //   size: 150,
      // },

      // {
      //   accessorKey: "bv",
      //   header: "BV",
      //   size: 150,
      // },

      {
        accessorKey: "start_date",
        header: "START DATE",
        size: 150,
      },
    ],
    []
  );
  const handleExportData = (use) => {
    if (use == "excel") {
      const worksheet = XLSX.utils.json_to_sheet(userData.result);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "ID",
            "FIRST NAME",
            "LAST NAME",
            "EMAIL",
            "PHONE",
            "SERVICE",
            "DATE",
          ],
        ],
        { origin: "A1" }
      );
      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, `${type.label}.xlsx`);
    } else {
      var copiedData = _.cloneDeep(userData.result);

      const fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(copiedData);
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            "ID",
            "FIRST NAME",
            "LAST NAME",
            "EMAIL",
            "PHONE",
            "SERVICE",
            "DATE",
          ],
        ],
        { origin: "A1" }
      );
      const csvOutput = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, type.label + fileExtension);
    }
  };

  const options = [
    { value: "user", label: "IBI" },
    { value: "customer", label: "Customer" },
  ];

  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} |{" "}
          {t("Sidemenu.mypersonalcustomerwalltable")}
        </title>
      </Helmet>
      {/* <div > */}

      <PageTitle title="Mypersonalcustomerwall" buttonOn={false} />
      <div className={style.DownlineMembers}>
        {
          userData && (
            <>
              {/* <TitleHeaders
            tilesvalue="total_downline_customers"
            count={userData.count}
        
          /> */}

              <div className="material-table">
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    columns={columns}
                    data={userData.result}
                    enableHiding={false}
                    muiTableBodyProps={{
                      sx: {
                        background:
                          currentTheme === "theme-dark" ? "#14243a" : "#fff80",
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:
                          currentTheme === "theme-dark" ? "#fff " : "#14243a",
                        borderBottom:
                          currentTheme === "theme-dark"
                            ? ".5px solid #47556560"
                            : "#00090",
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        color:
                          currentTheme === "theme-dark" ? "#fff " : "#14243a",
                      },
                    }}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    manualPagination = {true}
                    onSortingChange={setSorting}
                    rowCount={userData.count}
                    state={{
                      columnFilters,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                    }}

                  //    renderTopToolbarCustomActions={({ table }) => (
                  //   <div style={{ width: "100%", gap: 10, padding: "-3px" }}>
                  //     <Row>
                  //       <Col md={8} sm={12}>
                  //         {/* <label>filter by date</label> */}
                  //         <div
                  //           style={{
                  //             padding: 10,
                  //           }}
                  //         >
                  //           <FIlterCountry
                  //             filterHandler={filterHandler}
                  //             resetHandler={resetData}
                  //             isSingle={true}
                  //             // loader={loader}
                  //           />
                  //         </div>
                  //       </Col>

                  //       <Col md={4} sm={12}>
                  //         <div
                  //           className={style.exportButton}
                  //           style={{
                  //             display: "flex",
                  //             justifyContent: "flex-start",
                  //           }}
                  //         >
                  //           <Button
                  //             onClick={() => {
                  //               handleExportData("excel");
                  //             }}
                  //           >
                  //             <i className="fa fa-file-excel-o"></i>
                  //             Excel
                  //           </Button>

                  //           <Button
                  //             onClick={() => {
                  //               handleExportData("csv");
                  //             }}
                  //           >
                  //             <i className="fa fa-file-text-o"></i>
                  //             Csv
                  //           </Button>
                  //         </div>
                  //       </Col>
                  //     </Row>

                  //     <div style={{ padding: 15, gap: 10 }}></div>

                  //     {/* </Box> */}
                  //   </div>
                  // )}
                    renderTopToolbarCustomActions={({ table }) => (
                      <>
                        <div
                          style={{ width: "100%", gap: 10, padding: "-3px" }}
                        >
                          <Row>
                            <Col md={8} sm={12}>
                              <div
                                style={{
                                  padding: 10,
                                }}
                              >
                                <Filter
                                  onChange={(entry) => {
                                    setType(entry);
                                  }}
                                  selectOptions={options}
                                  fetchData={fetchData}
                                  filterHandler={submitHandler}
                                  setDateOptions={setDateOptions}
                                  resetHandler={resetHandler}
                                  type={type}
                                />
                              </div>
                            </Col>

                            <Col md={4} sm={12}>
                              <div
                                className={style.exportButton}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    handleExportData("excel");
                                  }}
                                >
                                  <i className="fa fa-file-excel-o"></i>
                                  Excel
                                </Button>

                                <Button
                                  onClick={() => {
                                    handleExportData("csv");
                                  }}
                                >
                                  <i className="fa fa-file-text-o"></i>
                                  Csv
                                </Button>
                              </div>
                            </Col>
                          </Row>

                          <div style={{ padding: 15, gap: 10 }}></div>

                          {/* </Box> */}
                        </div>
                      </>
                    )}
                  />
                </ThemeProvider>
              </div>
            </>
          )
          //  <MaterialReactTable

          //  columns={columns} data={userData} />
        }
      </div>
    </div>
  );
};

export default MypersonalcustWall;
