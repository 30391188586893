import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FormInputControl,
  DropDownBs,
  AlertMessage
} from "../common";
import style from "./party.module.scss";
import { Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

function CreateHost(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if(id){
      props.viewHost(id);
    }

  }, []);

  return (
    <div className={style.addguestContainer}>
      {props.notify.show && (
        <AlertMessage
          type={props.notify.type}
          message={props.notify.message}
          show={props.notify.show}
         // dismiss={notifyDissmissed}
        />
      )}
      <Container fluid className={`p-3`}>
        <div
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_panel} ${style.papanel_defaultnel}`
              : `${style.panel} ${style.papanel_defaultnel}`
          }`}
        >
          <div className={`${style.panel_body}`}>
            <FormInputControl
              label={t("profile.firstName")}
              required={true}
              inputProps={{
                type: "text",
                value: props.form.firstName,
                name: "firstName",
                onChange: (e) => {
                  props.changeHandler(e, true, props.keyValue);
                },
              }}
              error={props.form.FormError.firstName}
            />
            <FormInputControl
              label={t("profile.lastName")}
              required={true}
              inputProps={{
                type: "text",
                value: props.form.lastName,
                name: "lastName",
                onChange: (e) => {
                  props.changeHandler(e, true, props.keyValue);
                },
              }}
              error={props.form.FormError.lastName}
            />
            <FormInputControl
              label={t("profile.address")}
              required={true}
              inputProps={{
                type: "text",
                value: props.form.address,
                name: "address",
                onChange: (e) => {
                  props.changeHandler(e, true, props.keyValue);
                },
              }}
              error={props.form.FormError.address}
            />
            <FormInputControl
              label={t("Common.phone")}
              required={true}
              inputProps={{
                type: "text",
                value: props.form.phone,
                name: "phone",
                onChange: (e) => {
                  props.changeHandler(e, true, props.keyValue);
                },
              }}
              error={props.form.FormError.phone}
            />
            <FormInputControl
              label={t("profile.email")}
              required={true}
              inputProps={{
                type: "text",
                value: props.form.email,
                name: "email",
                onChange: (e) => {
                  props.changeHandler(e, true, props.keyValue);
                },
              }}
              error={props.form.FormError.email}
            />
            <DropDownBs
              label={t("profile.country")}
              required={true}
              options={{
                data: props.partyContent.country,
                value: "value",
                label: "code",
              }}
              DropdownProps={{
                onChange: (e) => {
                  props.changeHandler(e, true, props.keyValue);
                },
                value: props.form.country,
                name: "country",
              }}
              error={props.form.FormError.country}
              preOption="country"
            />
            <DropDownBs
              label={t("profile.state")}
              options={{
                data: props.partyContent.state,
                value: "value",
                label: "code",
              }}
              DropdownProps={{
                onChange: (e) => {
                  props.dropdownChange(e, true, props.keyValue);
                },
                value: props.form.state,
                name: "state",
              }}
              error={props.form.FormError.state}
              preOption="state"
            />
            <FormInputControl
              label={t("profile.city")}
              required={true}
              inputProps={{
                type: "text",
                value: props.form.city,
                name: "city",
                onChange: (e) => {
                  props.changeHandler(e, true, props.keyValue);
                },
              }}
              error={props.form.FormError.city}
            />
            <FormInputControl
              label={t("profile.zipCode")}
              required={true}
              inputProps={{
                type: "text",
                value: props.form.zip,
                name: "zip",
                onChange: (e) => {
                  props.changeHandler(e, true, props.keyValue);
                },
              }}
              error={props.form.FormError.zip}
            />

            <Button
              type="submit"
              variant="primary"
              onClick={(e) => props.onsubmit()}
            >
              {t(`Button.addGuest`)}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CreateHost;
