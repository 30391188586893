import React, { useEffect, useState } from "react";

import { SafeChargeCC } from "../../components/Payments";
import ProfileService from "../../../service/profile";
import AlertMessage from "../common/AlertMessage";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import { TrustPayments } from "../../components/Payments";
import paymentApi from '../../../service/common/PaymentApi'
import { useTranslation } from "react-i18next";


function SubscribeCard({
  card,
  crypto,
  OnSubmit,
  paymentData,
  safeCharge,
  userDetails,
  onDeleteCard,
  setDefaultCard,
  setDefaultCrypto,
  deleteloader,
  loadDetails,
  paymentMethods
}) {
  console.log("crypto ==== ", crypto);
  const [sessionToken, setSessionToken] = useState();
  const service = new ProfileService();
  const paymentService = new paymentApi()
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [jwt, setJwt] = useState(false);

  const radios = paymentMethods
  const [radioValue, setRadioValue] = useState(radios[0]?.value);
  const [isCardDefault, setIsCardDefault] = useState(false);
  const [showCrptoForm, setShowCrptoForm] = useState(false);

  const {t} = useTranslation();

  useEffect(() => {
    if (radioValue === "trust-payment") {
      setJwtToken()
    }
  }, [radioValue])
  const setJwtToken = async () => {
    const response = await paymentService.getJwtToken()
    if (response.status) {
      setJwt(response.data)
    }
  }
  console.log("jwt ============= ", jwt);
  const toggleButtonChange = async (value) => {
    setRadioValue(value)
    if (value !== 'trust-payment') { setJwt(false) }
    if (value === "trust-payment") setJwtToken()
  }
  const setTrustPaymentCard = (data) => {
    const payload = {
      ccCardNumber: data.maskedpan,
      userPaymentOptionId: data.transactionreference,
    }
    handleSuccessfulPayment(payload)
  }
  useEffect(() => {
    if (safeCharge) {
      var safeChargeFields = safeCharge.fields({
        fonts: [
          { cssUrl: "https://fonts.googleapis.com/css?family=Source+Code+Pro" },
        ],
        locale: "en",
      });
    }
  }, [safeCharge]);

  var ScFieldStyles = {
    base: {
      color: "#32325D",
      fontWeight: 500,
      fontFamily: "Roboto, Consolas, Menlo, monospace",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
      ":-webkit-autofill": {
        color: "#e39f48",
      },
    },
    invalid: {
      color: "#E25950",
      "::placeholder": {
        color: "#FFCCA5",
      },
    },
  };
  var ScFieldClasses = {
    focus: "focused",
    empty: "empty",
    invalid: "invalid",
    complete: "complete",
  };

  const expirationMonth = [
    { id: "1", label: "1-January" },
    { id: "2", label: "2-February" },
    { id: "3", label: "3-March" },
    { id: "4", label: "4-April" },
    { id: "5", label: "5-May" },
    { id: "6", label: "6-June" },
    { id: "7", label: "7-July" },
    { id: "8", label: "8-August" },
    { id: "9", label: "9-September" },
    { id: "10", label: "10-October" },
    { id: "11", label: "11-November" },
    { id: "12", label: "12-December" },
  ];
  const expirationYear = [
    { id: "2022", label: "2022" },
    { id: "2023", label: "2023" },
    { id: "2024", label: "2024" },
    { id: "2025", label: "2025" },
    { id: "2026", label: "2026" },
    { id: "2027", label: "2027" },
    { id: "2028", label: "2028" },
    { id: "2029", label: "2029" },
    { id: "2030", label: "2030" },
    { id: "2031", label: "2031" },
    { id: "2032", label: "2032" },
    { id: "2033", label: "2033" },
    { id: "2034", label: "2034" },
    { id: "2035", label: "2035" },
    { id: "2036", label: "2036" },
    { id: "2037", label: "2037" },
    { id: "2038", label: "2038" },
    { id: "2039", label: "2039" },
    { id: "2040", label: "2040" },
    { id: "2041", label: "2041" },
    { id: "2042", label: "2042" },
  ];

  const [showAddCardForm, setShowAddCardForm] = useState(false);
  const [formData, setFormData] = useState({
    cardHolder: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    cardHolder: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [state, setState] = useState({
    notify: {
      show: false,
      type: "",
      message: "",
      header: "",
    },
  });

  const handleSavedCardSelect = (event) => {
    service.openOrder().then((res) => {
      if (res.status) {
        setSessionToken(res.data.nuvei_deatils.sessionToken);
      } else {
        setSessionToken("");
      }
    });
    const selectedCard = event.target.value;
    setShowAddCardForm(selectedCard === "add-new");
    setShowCrptoForm(selectedCard === "add-crypto")
    resetform();
    resetError();
    setFormSubmitted(false);
  };

  const resetform = () => {
    setFormData({
      ...formData,
      cardHolder: "",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      cvv: "",
    });
  };
  const resetError = () => {
    setErrorMessage({
      ...errorMessage,
      cardHolder: "",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      cvv: "",
    });
  };
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setErrorMessage({
      ...errorMessage,
      [id]: "",
    });
  };
  const dissmissNotify = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: false,
      },
    }));
  };
  const handleSubscribe = () => {
    setLoader(true);

    setFormSubmitted(true);

    // Extract common payment data
    const paymentData = {
      sessionToken: sessionToken,
      //cardHolderName: formData.cardHolder,
      userTokenId: userDetails?.user_token_id,
      billingAddress: {
        email: userDetails?.email,
      },
    };

    const formValid = true;
    console.log(formValid);
    if (!showAddCardForm) {
      // For existing card payment
      const paymentOptions = {
        ...paymentData,
        paymentOption: {
          userPaymentOptionId: userDetails?.user_payment_id,
        },
      };
      sendPaymentRequest(paymentOptions);
    } else {
      if (formValid) {
           
        // For new card payment
        const cardDetails = {
          ...paymentData,
          paymentOption: {
            card: {
              cardHolderName: formData.cardHolder,
              cardNumber: formData.cardNumber,
              CVV: formData.cvv,
              expirationMonth: formData.expirationMonth,
              expirationYear: formData.expirationYear,
            },
          },
          billingAddress: {
            firstName: userDetails?.first_name,
            lastName: userDetails?.second_name,
            country: userDetails?.country,
            email: userDetails?.email,
          },
        };
         console.log("handleSubscribe1", cardDetails);
        sendPaymentRequest(cardDetails);
      }
    }
  };

  // Function to send payment request
  const sendPaymentRequest = (paymentOptions) => {
    try {
      safeCharge.createPayment(paymentOptions, (resp) => {
         console.log(resp);
        if (resp.status == "ERROR" || resp.result == "ERROR") {
          setLoader(false);
          setState({
            ...state,
            notify: {
              show: true,
              type: "danger",
              message: resp?.error?.message
                ? resp.error.message
                : resp?.errorDescription,
            },
          });
        } else {
          console.log(resp);
          // Handle successful payment
          handleSuccessfulPayment(resp);
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Function to handle successful payment
  const handleSuccessfulPayment = (resp) => {
    let data = {
      ccCardNumber: resp.ccCardNumber,
      userPaymentOptionId: resp.userPaymentOptionId,
      isDefault: isCardDefault,
      type: radioValue
    };
    service.subscriptionSubmit(data).then((res) => {
      if (res.status) {
        setLoader(false);
        setState({
          ...state,
          notify: {
            show: true,
            type: "success",
            message: "Card Details Updated",
          },
        });
        setJwt(false)
        setRadioValue(radios[0]?.value)
        loadDetails();
      } else {
        setState({
          ...state,
          notify: {
            show: true,
            type: "danger",
            message: "Something Went Wrong",
          },
        });
        setLoader(false);
      }
    });
  };

  const validateForm = () => {
    let valid = true;
    const errors = {};

    // Validate cardHolder
    if (!formData.cardHolder.trim()) {
      errors.cardHolder = "Required";
      valid = false;
    }

    // Validate cardNumber
    if (!formData.cardNumber.trim()) {
      // errors.cardNumber = "Required";
      // valid = false;
    } else if (!/^\d*$/.test(formData.cardNumber.trim())) {
      errors.cardNumber = "Please enter numbers only";
      valid = false;
    }

    // Validate expirationMonth
    if (!formData.expirationMonth) {
      errors.expirationMonth = "Required";
      valid = false;
    }

    // Validate expirationYear
    if (!formData.expirationYear) {
      errors.expirationYear = "Required";
      valid = false;
    }

    // Validate CVV
    if (!formData.cvv.trim()) {
      errors.cvv = "Required";
      valid = false;
    } else if (!/^\d*$/.test(formData.cvv.trim())) {
      errors.cvv = "Please enter numbers only";
      valid = false;
    }

    setErrorMessage(errors);

    return valid;
  };

  return (
    <>
      {state.notify.show && (
        <AlertMessage
          show={state.notify.show}
          message={state.notify.message}
          dismiss={dissmissNotify}
          type={state.notify.type}
          header={state.notify.header}
        />
      )}
      <div
        className="card"
        style={{ margin: "20px", padding: "10px", maxWidth: "100%" }}
      >
        <div className="card-header">Payment</div>
        <div className="card-body">
          <p className="text-justify">
            How would you like to pay for your Subscription?
          </p>
          <div className="form-row" style={{ maxWidth: "500px" }}>
            <div className="input-group mb-6">
              <select
                className="custom-select"
                id="inputGroupSelect01"
                onChange={handleSavedCardSelect}
              >
                <option value="">select</option>

                {/* {card.map((card) => (
                  <option key={card.id} value={card.user_payment_id}>
                    {card.card_number}
                  </option>
                ))} */}
                {radios.length > 0 && <option value="add-new">Card</option>}
                {card.length > 0 && <option value="add-crypto">Crypto</option>}
              </select>
            </div>
          </div>
          <br />

          {showAddCardForm && (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ButtonGroup toggle>
                  {radios?.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      type="radio"
                      variant={radioValue === radio.value ? "primary" : "secondary"}
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.value}
                      onChange={(e) => toggleButtonChange(e.currentTarget.value)}
                      onClick={(e) => toggleButtonChange(e.currentTarget.value)}
                      style={{}}
                    >
                      {t("Common."+radio.name)}
                    </ToggleButton>
                  ))}

                </ButtonGroup>
                {/* {showAddCardForm && (
                  <div style={{ marginLeft: "2em" }}>
                    <Form.Check
                      type="switch"
                      id="isDefault"
                      label="Default card"
                      onChange={(e) => setIsCardDefault(e.target.checked)}
                    />
                  </div>
                )} */}
              </div>
              <br />
              {radioValue === 'nuvei' &&
                <>
                  <form id="new-card-form">
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label>NAME ON CARD</label>
                        <input
                          type="text"
                          className={`form-control ${formSubmitted &&
                            !formData.cardHolder.trim() &&
                            "is-invalid"
                            }`}
                          id="cardHolder"
                          placeholder="Name on card"
                          onChange={handleInputChange}
                        />
                        {formSubmitted && !formData.cardHolder.trim() && (
                          <div className="invalid-feedback">
                            {errorMessage.cardHolder}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label>CARD NUMBER</label>
                        <input
                          type="text"
                          className={`form-control ${formSubmitted &&
                            !formData.cardNumber.trim() &&
                            "is-invalid"
                            }`}
                          id="cardNumber"
                          placeholder="Card Number"
                          onChange={handleInputChange}
                        />
                        {formSubmitted && !formData.cardNumber.trim() && (
                          <div className="invalid-feedback">
                            {errorMessage.cardNumber}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label>MONTH</label>
                        <select
                          className={`custom-select ${formSubmitted && !formData.expirationMonth && "is-invalid"
                            }`}
                          onChange={handleInputChange}
                          id="expirationMonth"
                        >
                          <option value="">Select Month</option>
                          {expirationMonth.map((month) => (
                            <option key={month.id} value={month.id}>
                              {month.label}
                            </option>
                          ))}
                        </select>
                        {formSubmitted && !formData.expirationMonth && (
                          <div className="invalid-feedback">
                            {errorMessage.expirationMonth}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-3">
                        <label>YEAR</label>
                        <select
                          className={`custom-select ${formSubmitted && !formData.expirationYear && "is-invalid"
                            }`}
                          onChange={handleInputChange}
                          id="expirationYear"
                        >
                          <option value="">Select Year</option>
                          {expirationYear.map((year) => (
                            <option key={year.id} value={year.id}>
                              {year.label}
                            </option>
                          ))}
                        </select>
                        {formSubmitted && !formData.expirationYear && (
                          <div className="invalid-feedback">
                            {errorMessage.expirationYear}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-2">
                        <label>CVV</label>
                        <input
                          type="password"
                          className={`form-control ${formSubmitted && !formData.cvv.trim() && "is-invalid"
                            }`}
                          placeholder="cvv"
                          id="cvv"
                          onChange={handleInputChange}
                        />
                        {formSubmitted && !formData.cvv.trim() && (
                          <div className="invalid-feedback">{errorMessage.cvv}</div>
                        )}
                      </div>
                    </div>
                  </form>
                  <div className="check-box" style={{ display: "flex" }}>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      onClick={handleSubscribe}
                      disabled={loader}
                    >
                      {loader ? (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      ) : (
                        ""
                      )}
                      Update Card Details
                    </button>

                  </div>
                </>}
              {radioValue === 'trust-payment' && jwt && <div><TrustPayments jwt={jwt} submit={setTrustPaymentCard} />

              </div>}
            </>
          )}

          <br /> <br />
          <p className="text-justify">
            If you have any questions or concerns about your Auto Order, please
            call our NeXarise Customer Service Team at nex.support@nexarise.com.
            We'll be glad to assist you.
          </p>
        </div>
      </div>

      {showAddCardForm && <div
        className="card"
        style={{ margin: "20px", padding: "10px", maxWidth: "100%" }}
      >
        <div className="card-header">Saved Cards</div>
        <div className="card-body">
          <ul class="list-group">
            {card.map((card) => (
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <><div>
                  {<><span>{card.card_number}</span>{" "}<span style={{ fontSize: "x-small" }}></span></>}
                </div></>
                <div style={{ display: "flex" }}>
                  {card.default_card ? (
                    <span style={{ color: "green", display: "flex" }}>
                      <div>
                        {" "}
                        <i class="fa fa-check"></i> Default card
                      </div>

                      <div>
                        {" "}
                        <div style={{ marginLeft: "1em" }}>
                          <Button disabled>
                            <i class="fa fa-trash-o"></i> Delete
                          </Button>
                        </div>
                      </div>
                    </span>
                  ) : (
                    <>
                      <div>
                        <Button
                          onClick={() => setDefaultCard(card.id)}
                          disabled={deleteloader}
                        >
                          Set default
                        </Button>
                      </div>
                      <div style={{ marginLeft: "1em" }}>
                        <Button
                          onClick={() => onDeleteCard(card.id)}
                          disabled={deleteloader}
                        >
                          <i class="fa fa-trash-o"></i> Delete
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>

          <br />
        </div>
      </div>}
      {showCrptoForm && <div
        className="card"
        style={{ margin: "20px", padding: "10px", maxWidth: "100%" }}
      >
        <div className="card-header">Crypto Gateway</div>
        <div className="card-body">
          <ul class="list-group">
            {crypto.map((item) => (
              <li class="list-group-item d-flex justify-content-between align-items-center">
                <><div>
                  {<><span>{t("Common."+item.name)}</span></>}
                </div></>
                <div style={{ display: "flex" }}>
                  {
                    <>
                      {item.default ? <span style={{ color: "green", display: "flex" }}>
                        <div>
                          {" "}
                          <i class="fa fa-check"></i> Default Gateway
                        </div>
                        <div>
                        </div>
                      </span> : <div>
                        <Button
                          onClick={() => setDefaultCrypto(item.slug)}
                          disabled={deleteloader}
                        >
                          Set default
                        </Button>
                      </div>}
                      {/* <div style={{ marginLeft: "1em" }}>
          <Button
            onClick={() => onDeleteCard(card.id)}
            disabled={deleteloader}
          >
            <i class="fa fa-trash-o"></i> Delete
          </Button>
        </div> */}
                    </>
                  }
                </div>
              </li>
            ))

            }
          </ul>

          <br />
        </div>
      </div>

      }
    </>
  );
}

export default SubscribeCard;
