import React, { useEffect,useState } from 'react'
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Form,Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import AlertMessage from '../common/AlertMessage';
import moment from 'moment';
import EpinService from '../../../service/epin/epin';
import {FormControl} from './';
import style from './epin.module.scss';
function ModalForms(props) {
    const history = useHistory();
    const {t} = useTranslation();
    let Currency = useSelector(state=>state.curr);
    //service 
    const service = new EpinService();
    const [state,setState] = useState({
        field : [],
        balance : '',
        amounts : [],
        epins : [],
        notify : {
            show  :false,
            message : '',
            header : '',
            type : ''
        },
        loader:false
    });
    //
    useEffect(()=>{
        const service = new EpinService();
        if(props.field === 'transferEpin'){
            service.getepinNumbers().then(res=>{
                if(res.status){
                    setState(prev=>({
                        ...prev,
                        epins : res.data.epin_numbers
                    }))
                }else{
                    history.push('/logout')
                }
            })
        }else{
            service.getEpinamounts().then(res=>{
                if(res.status){
                    setState(prev=>({
                        ...prev,
                        balance : res.data.balance,
                        amounts : res.data.amount
                    }))
                }else{
                    history.push('/logout')
                }
            })
        }
    },[history,props.field])

    useEffect(()=>{
        let fileds = [];
        if(props.field === 'epinPurchase'){
            fileds = [
                {
                    prepend:true,
                    code: "currentBalance",
                    isEditable: false,
                    required: true,
                    type: "text",
                    value: (Currency.value*state.balance).toFixed(Currency.precision),
                    error : '',
                    errorField :'',
                    fieldName : 'balance'
                },
                {
                    prepend:false,
                    code: "amount",
                    isEditable: true,
                    required: true,
                    type: "select",
                    options : state.amounts,
                    error : '',
                    errorField :'',
                    value : '',
                    fieldName : 'amount'
                },
                {
                    prepend:false,
                    code: "epinCount",
                    isEditable: false,
                    required: true,
                    type: "number",
                    value: '',
                    error : '',
                    errorField :'',
                    fieldName : 'pin_count'
                },
                {
                    prepend:false,
                    code: "expiryDate",
                    isEditable: false,
                    required: true,
                    type: "date",
                    value: new Date(),
                    error : '',
                    errorField :'',
                    fieldName : 'expiry_date'
                },
                {
                    prepend:false,
                    code: "transactionPassword",
                    isEditable: false,
                    required: true,
                    type: "password",
                    value: '',
                    error : '',
                    errorField :'',
                    fieldName : 'passcode'
                }
            ];
        }else if(props.field === 'requestEpin'){
            fileds = [
                {
                    prepend:false,
                    code: "amount",
                    isEditable: true,
                    required: true,
                    type: "select",
                    options : state.amounts,
                    error : '',
                    errorField :'',
                    value : '',
                    fieldName : 'amount'
                },
                {
                    prepend:false,
                    code: "epinCount",
                    isEditable: false,
                    required: true,
                    type: "number",
                    value: '',
                    error : '',
                    errorField :'',
                    fieldName : 'pin_count'
                },
                {
                    prepend:false,
                    code: "expiryDate",
                    isEditable: false,
                    required: true,
                    type: "date",
                    value: new Date(),
                    error : '',
                    errorField :'',
                    fieldName : 'expiry_date'
                }
            ]
        }else if(props.field === 'transferEpin'){
            fileds = [
                {
                    prepend:false,
                    code: "toUsername",
                    isEditable: false,
                    required: true,
                    type: "text",
                    value: '',
                    error : '',
                    errorField :'',
                    fieldName : 'transfer_user'
                },
                {
                    prepend:false,
                    code: "epin",
                    isEditable: true,
                    required: true,
                    type: "select",
                    value: '',
                    error : '',
                    errorField :'',
                    fieldName : 'epin',
                    options : state.epins
                }
            ]
        }
        setState(prev=>({
            ...prev,
            field : fileds
        }))
    },[props,Currency,state.balance,state.amounts,state.epins,state.loader]);

    //date change 
    const datechange = (date) =>{
        let formItems  = state.field;
        let FieldItems = formItems.map(items=>{
            if(items.code==='expiryDate'){
                return{
                    ...items,
                    value : moment(date).format('yyyy-MM-DD'),
                    error :''
                }
            } 
            return items
        })
        setState(prev=>({
            ...prev,
            field  :FieldItems
        }))
    }
    //change handler 
    const changeHandler = (e) =>{
        const {name , value} = e.target;
        let formItems  = state.field;
        let FieldItems = formItems.map(items=>{
            if(items.code===name){
                return{
                    ...items,
                    value : value,
                    error : ''
                }
            } 
            return items
        })
        setState(prev=>({
            ...prev,
            field  :FieldItems
        }))
    }
    //submit form
    const submitForm =async (e)=>{
        e.preventDefault();
        let isValid =validation();
        if(isValid){
            let formData = {}
            state.field.map(items=>{
                if(items.code === 'expiryDate'){
                    formData[items.fieldName] = items.value
                }else{
                    formData[items.fieldName] = items.value;
                }
                return false
            });
            let type      = '';
            let header    = '';
            let message   = '';
            let field= state.field;
            if(props.field === 'epinPurchase'){
                setState(prev=>({
                    ...prev,
                   loader:true
                }))
                await service.epinPurchase(formData).then(res=>{
                    
                    if(res.status){
                        type    = 'success';
                        message = t('Epin.epinPurchasedSuccessfully');
                        header  = t('Common.success');
                        // const newfield = field.map((item)=>{
                        //     if(item.value){
                        //         return({
                        //             ...item,
                        //             value:''
                        //         });
                        //     }
                        //     else return item 
                        // })
                        setState(prev=>({
                            ...prev,
                           loader:false,
                        //    field:newfield
                        }))
                       
                    }else{
                        type = 'danger';
                        header = t('Common.error');
                        if(res.error.code ===1002){
                            history.push('/logout')
                        }else if(res.error.code === 1004){
                            field= validationError(res.error);
                            message = t('validation.checkValueYouHaveSubmitted');
                        }
                        else if(res.error.code === 1021){   
                            message = t('validation.invalidTransactionDetails');
                        }
                            else if(res.error.code ===1015){
                            let errorfiled=[];
                            errorfiled= state.field.map((items)=>{
                                if(items.code === 'transactionPassword') {
                                    return {
                                        ...items,
                                        error : 'validation.invalidTransactionDetails',
                                        errorField : {
                                            field : items.code,
                                            // length : res.error.fields[property+'_err']==='min_length'?3:32
                                            }
                                        }
                                }
                                return items
                            })
                            field = errorfiled;
                            message = t('validation.checkValueYouHaveSubmitted');
                        }else if(res.error.code === 1014){
                            message = t('validation.insufficientBalance');
                        }else if(res.error.code ===1044){
                            message = t('validation.epinPurchase Failed');
                        }
                        setState(prev=>({
                            ...prev,
                           loader:false
                        }))
                    }
                })
            }else if(props.field === 'requestEpin'){
                setState(prev=>({
                    ...prev,
                   loader:true
                }))
                await service.epinRequest(formData).then(res=>{
                    if(res.status){
                        setState(prev=>({
                            ...prev,
                           loader:false
                        }))
                        props.getPendingList()
                        type    = 'success';
                        message = t('Epin.epinRequestSuccessfully');
                        header  = t('Common.success');
                    }else{
                        setState(prev=>({
                            ...prev,
                           loader:false
                        }))
                        
                        type = 'danger';
                        header = t('Common.error');
                        if(res.error.code ===1002){
                            history.push('/logout')
                        }else if(res.error.code === 1004){
                            field= validationError(res.error);
                            message = t('validation.checkValueYouHaveSubmitted');
                        }else if(res.error.code ===1045){
                            message = t('validation.epinRequestFailed');
                        }
                    }
                })
            }else if(props.field ==='transferEpin'){
                setState(prev=>({
                    ...prev,
                   loader:true
                }))
                await service.epinTransfer(formData).then((res=>{
                    if(res.status){
                        setState(prev=>({
                            ...prev,
                           loader:false
                        }))
                        type    = 'success';
                        message = t('Epin.epinTransferSuccessfully');
                        header  = t('Common.success');
                    }else{
                        setState(prev=>({
                            ...prev,
                           loader:false
                        }))
                        type = 'danger';
                        header = t('Common.error');
                        if(res.error.code ===1002){
                            history.push('/logout')
                        }else if(res.error.code === 1004){
                            field= validationError(res.error);
                            message = t('validation.checkValueYouHaveSubmitted');
                          
                        }else if(res.error.code ===1016){
                            message = t('validation.invalidEpinCode');
                        }else if(res.error.code === 1011){
                            message = t('validation.InvalidUserName');
                        }else if(res.error.code === 1046){
                            message = t('validation.epintransferFailed');
                        }else if(res.error.code === 406){
                            message = t('validation.epintransferFailed');
                        }
                    }
                }))
            }
            setState(prev=>({
                ...prev,
                field : field,
                notify : {
                    show : true,
                    type : type,
                    message : message,
                    header : header
                }
            }))
        }else{
            setState(prev=>({
                ...prev,
                notify : {
                    show : true,
                    type : 'danger',
                    message : t('validation.checkValueYouHaveSubmitted'),
                    header : t('Common.error')
                }
            }))
        }
    }
    //notify message 
    const dissmissNotify = () =>{
        setState(prev=>({
            ...prev,
            notify : {
                ...prev.notify,
                show : false
            }
        }));
        if(state.notify.type === 'success'){
            props.successclose();
        }
    }

    //array validation
    const validation =() =>{
    let valid = true
    const validState= state.field.map(items=>{
        if(items.value===''&items.required){
            valid= false;
        return {
                ...items,
                error : 'validation.required',
                errorField : {
                    field : items.code
                }
            }
        }else if(items.code === 'epinCount'){
            if(items.value < 0){
                valid= false;
                return {
                    ...items,
                    error : 'validation.valueGreaterThan0'
                }
            }
        }else if(items.code==='expiryDate'){
            var a = moment();
            var b = moment(items.value);
            if(b.diff(a)<0){
                valid = false;
                return {
                    ...items,
                    error : 'validation.dategreateThancurrentDate',
                    // error : '',
                    errorField : {
                        field : ''
                    }
                }
            }
        }
        return items;
    });
    setState(prev=>({
        ...prev,
        field : validState
    }))
        return valid
    };

    //server validation error
    const validationError = (errorData) =>{
        let errorfiled=[];
        errorfiled= state.field.map((items)=>{
            if(errorData.fields.hasOwnProperty(items.fieldName)) {
                return {
                    ...items,
                    error : 'validation.'+errorData.fields[items.fieldName+'_err'],
                    errorField : {
                        field : items.code,
                        // length : res.error.fields[property+'_err']==='min_length'?3:32
                        }
                    }
            }
            return items
        })
        return errorfiled
    }
    return (
        <Form onSubmit={submitForm}>
            {
                state.field.map((items,index)=>(
                    <FormControl 
                        key={index} 
                        data={items}
                        datechange={datechange}
                        change={changeHandler}
                    />
                ))
            }
            
            <Button type="submit" className={style.submitButton} disabled={state.loader}>
                
            {state.loader ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px" }}
              />
            ):('')} {t('Button.'+props.field)}
            </Button>
            
            {
                state.notify.show &&
                <AlertMessage
                    show={state.notify.show}
                    message={state.notify.message}
                    dismiss={dissmissNotify}
                    type={state.notify.type}
                    header={state.notify.header}
                />
            }
        </Form>
    )
}

export default ModalForms
