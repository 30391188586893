import React from "react";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
//components
import PageTitle from "../../../components/common/pageTitle";
// import MainContent from "../../components/dashboard/MainContent";
import UnapprovedDashboard from "../../../components/dashboard/unApproved/UnapprovedDashboard";
//service
import DashboardService from "../../../../service/dashboard/Dashboard";
import AppinfoService from "../../../../service/common/Appinfo";
import Spinner from "../../../shared/Spinner";
import { withRouter } from "react-router";
import companyName from "../../../../store/mobxStore/companyName";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Button, Form, Image, Alert } from "react-bootstrap";

import { logout } from "../../../../store/action";
import UserStore from "../../../../store/UserStore";

import Navbar from "./navbar";
import zIndex from "@mui/material/styles/zIndex";
import Container from "react-bootstrap/Container";
import style from "../../../components/dashboard/dashboard.module.scss";

const Home = (props) => {
  const cplan = useSelector((state) => state.ProjectConfig.mlmPlan);
  const curr = useSelector((state) => state.curr);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    memberStatus: false,
    loading: true,
    alert: {
      show: false,
      varient: "",
      message: "",
    },
    modal: {
      title: "",
      show: false,
      data: {},
      id: "",
    },
    continue: false,
    visitorid: "",
  });

  const service = new DashboardService();
  const commonService = new AppinfoService();
  const { t } = props;

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const logoutHandler = () => {
    commonService.logOut().then((res) => {
      if (res.status) {
        dispatch(logout());
        localStorage.removeItem("login");
        localStorage.removeItem("apiKey");
        localStorage.removeItem("currency");
        UserStore.key = "";
        props.history.push("/login");
      } else {
        dispatch(logout());
        localStorage.removeItem("login");
        localStorage.removeItem("apiKey");
        localStorage.removeItem("currency");
        UserStore.key = "";
        props.history.push("/login");
      }
    });
  };

  //upload image
  const uploadImgae = (file, username, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("username", username ?? "");
    formData.append("type", type ?? "subscription_renewal");

    service.upload_reciept(formData).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          enableFinish: true,
          showAlert: true,
          alertType: "success",
          AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
          imageUploaded: true,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          enableFinish: false,
          showAlert: true,
          alertType: "danger",
          AlertMessage: t("Common.error_file_upload"),
        }));
      }
    });
  };

  // useEffect(() => {
  //   const service = new DashboardService();
  //   const { state } = props.location;

  //   // if (localStorage.getItem("visitorid")) {
  //   //   setState((prevState) => ({
  //   //     ...prevState,
  //   //     modal: {
  //   //       title: "Enter OTP sent to your email",
  //   //       show: true,
  //   //       data: {},
  //   //       id: "",
  //   //     },
  //   //     // visitorid : res.visitor_id,
  //   //     continue: true,
  //   //   }));
  //   // }

  //   // var userEmail = getCookie("visitor");
  //   // if (state) {
  //   //   if (state.error) {
  //   //     setState((prevstate) => ({
  //   //       ...prevstate,
  //   //       alert: {
  //   //         show: true,
  //   //         varient: "danger",
  //   //         message: "validation." + state.message,
  //   //       },
  //   //     }));
  //   //   }
  //   // }
  //   // commonService.checkDemoUsers().then((res) => {
  //   //   if (res.data.is_preset_demo === "yes" && userEmail == null) {
  //   //     setState((prevState) => ({
  //   //       ...prevState,
  //   //       modal: {
  //   //         title: "Please fill in your details to continue",
  //   //         show: true,
  //   //         data: {},
  //   //         id: "",
  //   //       },
  //   //     }));
  //   //   } else {
  //   //     // props.history.push('/dashboard');
  //   //   }
  //   // });
  //   service.UnapprovedDashboardLoad().then((res) => {
  //     if (res.status) {
  //       setState((prevState) => ({
  //         ...prevState,
  //         loading: false,
  //         ...res.data,
  //       }));
  //     } else {
  //       // props.history.push("/logout");
  //     }
  //   });
  //   // },[])
  // }, [props.history, props.location, curr]);

  const FiterGraph = (range) => {
    service.JoiningGraphFilter(range).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          joining_graph_data_new: res.data.joining_graph_data_new,
        }));
      } else {
        //props.history.push("/logout");
      }
    });
  };

  const TilesFitler = (type, range) => {
    service.TileFilter(type, range).then((res) => {
      if (res.status) {
        let newData = state.tiles.map((object) => {
          if (object.text === type) {
            return res.data;
          } else {
            return object;
          }
        });
        setState((prevState) => ({
          ...prevState,
          tiles: newData,
        }));
      } else {
        // props.history.push("/logout");
      }
    });
  };

  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };

  return (
    <div>
      {/* {alert("sdadada")} */}
      {/* <div style={{backgroundColor : "black"}}>how r u</div> */}
      {/* {state.loading ? (
        <Spinner />
      ) : ( */}
      <React.Suspense fallback={<Spinner />}>
        <Navbar />
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.Dashboard")}
          </title>
        </Helmet>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <PageTitle title="Dashboard" buttonOn={false} />
        </div>
        <Container fluid className={`m-10`} style={props?.style}>
          {/* <NewProfile profile={props.profile}/> */}
          <div className={style.left_navigation_fold}>
            <div className={style.main_contant_sec}>
              <section className={style.contant_sec}>
                <div className={style.cntr_main_cnt_sc}>
                  <div className={style.dashboard_main_cnt}>
                    {true && (
                      <div
                        className={style.dashboard_pannel_box}
                        style={{
                          border: "2px solid #F2C200",
                          textAlign: "center",
                        }}
                      >
                        <i
                          class="fa fa-warning"
                          style={{ margin: "0 5px", color: "#F2C200" }}
                        ></i>
                        We've noticed that your legacy ID is missing from your
                        profile, which could affect your payout experience. To
                        ensure seamless transactions and timely payouts, we
                        kindly ask you to review and update your payout details.
                        <div
                          style={{
                            lineHeight: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Please visit the <a href="/profile">profile page </a>{" "}
                          and update the legacy ID
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Container>
      </React.Suspense>
      {/* )} */}
    </div>
  );
};
export default withTranslation()(withRouter(Home));
