import React, { useEffect } from "react";
const TrustPayments = (props) => {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://webservices.securetrading.net/js/v3/st.js";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      (function () {
        var st = window.SecureTrading({
          jwt: props.jwt,

          submitCallback: (data) => {
            if (data && data.errorcode == 0) {
              props.submit(data)
            }
          },
          submitOnSuccess: false,
          styles: {
            "border-radius-input": "5px", 
            'font-size-label': '18px',
            // 'line-height-body':"10px",
            // 'space-outset-body':'10px',
            // 'border-color-input':'grey',
            // 'space-inset-body':'10px',
            // "line-height-input":"20px",
            "space-inset-input":"-5px"
           
      
          
          },
          translations: {
            "Pay": props.buttonText ?? "Update Card Details",
            "Value mismatch pattern": "Invalid Value"
          },
          fieldsToSubmit: props.fieldsToSubmit ?? []
        });
        st.Components();
      })();
    };
    return () => {
      document.body.removeChild(script);
    };
  }, [props.jwt]);

  return (
    <>
      <div>
        <div id="st-notification-frame"></div>

        <form id="st-form" style={{display:"flex",alignItems:"center",flexDirection:'column'}}>
          <div id="st-card-number"></div>
          <div id="st-expiration-date"></div>
          <div id="st-animated-card"></div>
          <div id="st-security-code"></div>

          <button type="submit" style={{ backgroundColor: "#7266ba", borderRadius: "3px", color: "white", padding: "0.4rem 1rem", fontSize: "0.875rem" ,margin:"20px",width:"28%"}}>
            {props.buttonText ?? "Update Card Details"}
          </button>
        </form>
      </div>
    </>
  );
};

export default TrustPayments;
