import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import style from "./epin.module.scss";
import { DataTable } from "./";
function RequestPendingList(props) {
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  //coloumns
  const columns = [
    {
      name: t("payout.requestedDate").toUpperCase(),
      selector: (row) => row.requested_date,
      //   width : "30%"
    },
    {
      name: t("Common.expireDate").toUpperCase(),
      selector: (row) => row.expiry_date,
      sortable: true,
    },
    {
      name: t("Epin.requestedPinCount").toUpperCase(),
      selector: (row) => row.requested_pin_count,
      sortable: true,
      cell: (row) => (
        <span className={`${style.badgegreen} ${style.badge}`}>
          {" "}
          {row.requested_pin_count}
        </span>
      ),
    },
    {
      name: t("Common.amount").toUpperCase(),
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => (
        <span className={`${style.badgegreen} ${style.badge}`}>
          {Currency.currentCurr}{" "}
          {(row.amount * Currency.value).toFixed(Currency.precision)}
        </span>
      ),
    },
  ];
  //handle row per change
  const handlePerRowsChange = (perPage, inPage) => {
    props.pendingFilter(perPage, inPage, "", "");
  };
  const handlePageChange = (inPage) => {
    props.pendingFilter(props.data.perPage, inPage, "", "");
  };
  return (
    <div className={style.tables}>
      <DataTable
        columns={columns}
        data={props.data.data}
        totalRows={props.data.totalRows}
        pending={props.data.pending}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
      />
    </div>
  );
}

export default RequestPendingList;
