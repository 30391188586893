import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import { BASE, IMG_URL } from "../../../configuration/apiconfig";
import { CurrencyDecimalFormat } from "../../helper";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import moment from "moment";
import { Button, ButtonGroup } from "react-bootstrap";
import NewsTicker from "react-advanced-news-ticker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function News({ news, lodervalue }) {
  const curr = useSelector((state) => state.curr);
  const navigation = useHistory();

  const { currentTheme } = useSelector((state) => state.Theme);
  const newslist = [
    { news_title: "telegramfixes", id: 1 },
    { news_title: "We have introduced Telegram", id: 2 },
    { news_title: "Lorem ipsum, dolor sit ", id: 3 },
    { news_title: "telegramfixes", id: 4 },
    { news_title: "telegramfixes", id: 5 },
    { news_title: "telegramfixes", id: 6 },
  ];
  const { t } = useTranslation();
  return (
    <div className={style.dashboard_new_member_sec}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? style.dark_mode_dashboard_pannel_box
            : style.dashboard_pannel_box
        }`}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className={`${
            currentTheme == "theme-dark"
              ? style.dark_mode_dashboard_new_member_head
              : style.dashboard_new_member_head
          }`}
        >
          {t("Dashboard.news")}
          <button
            className={style.readmore_btn}
            style={{ background: "none" }}
            onClick={() => {
              navigation.push("/news");
            }}
          >
            <span>View all </span>
          </button>
        </div>

        {news.length > 0 ? (
          <NewsTicker
            // rowHeight={40}
            maxRows={7}
            speed={1000}
            duration={1000}
            autoStart={true}
            pauseOnHover={false}
            style={{
              width: "100%",
              textAlign: "center",
              borderRadius: "15px",
              marginTop: "20px",
            }}
          >
            {news.map((item) => (
              <div className={style.newsticker} key={item.id}>
                <div className={style.iconWrapper}>
                  <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                </div>
                <div className={style.newsContent}>
                  <h3>{item.news_title}</h3>
                  <span>December 2023</span>
                </div>
              </div>
            ))}
          </NewsTicker>
        ) : (
          <div
            className={`${style.NodataImage}`}
            style={{ textAlign: "center" }}
          >
            <img src={nodata} alt="nodata" style={{ width: "50%" }} />
          </div>
        )}
      </div>
    </div>
  );
}
export default News;
