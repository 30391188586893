import React, { useEffect, useState } from "react";
import paymentApi from '../../../service/common/PaymentApi'
import TrustPayments from "./TrustPayment";
const Test = () => {
  const service = new paymentApi()
  const [paymentInitiated, setPaymentInitiated] = useState(false)
  const [jwt, setJwt] = useState("")

  const getJwt = async () => {
    const response = await service.getJwtToken()
    console.log("test res = ", response);
    if (response.status) {
      setJwt(response.data)
      setPaymentInitiated(true)
    }
  }
  const submit = async (data) => {
    const response = await service.setTrustPaymentCard(data)
    console.log("set card === ", response);
    if (response.status) {

    }
  }
  return (
    <>
      <button onClick={getJwt}>test</button>
      {paymentInitiated && jwt && <TrustPayments jwt={jwt} submit={submit} />}
    </>
  );
};

export default Test;
