import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import { routes, publicRoute, replicaRoute, unsubRoute } from "./routes";
import { MainLayout, AuthLayout } from "../layout";
import AppInfoservice from "../../service/common/Appinfo";
import UserStore from "../../store/UserStore";
import { useDispatch, useSelector } from "react-redux";
import { addUsername, islogged } from "../../store/action";
import Spinner from "../shared/Spinner";
import NotFount from "../components/404/NotFount";

import { useParams } from "react-router-dom";
import JSEncrypt from "jsencrypt";
import ResetPassword from "../view/auth/login/ResetPassword";
import ResetTranPassword from "../view/auth/login/ResetTransactionPassword";
import { logout } from "../../store/action/";
import UnApprovedDashboard from "../view/dashboard/UnApproved/unhome";
import TestTree from "../view/mobile_tree/testtree";
import SponsorTree from "../view/mobile_tree/sponsorTree";
import TreeView from "../view/mobile_tree/TreeView";
import GenealogyTree from "../view/mobile_tree/GenealogyTree";

const withStatusCheck = (WrappedComponent, status) => {
  return <WrappedComponent />;
};

const RouteMap = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [status, setStatus] = useState("");
  const logged = useSelector((state) => state.isLogged);
  const [tokens, setToken] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  console.log("urlparams =================== ",urlParams);
  let title = urlParams.get("title");
  let string = urlParams.get("string");
  let prefix = urlParams.get("prefix");

  const ckecking = localStorage.getItem("approved");
  let unAuthorized = [0, 3, 6];

  useEffect(() => {
    const fetchTokenone = async () => {
      localStorage.setItem(
        "apiKey",
        "387245a5a919ea2071cc406b10b89d4685e5cc8e"
      );
      const service = new AppInfoservice();
      const result1 = await service.check_token({ token: string });
      if (result1.status) {
        setLoader(false);
        localStorage.setItem(
          "login",
          JSON.stringify({
            token: string,
          })
        );
        UserStore.key = string;
        UserStore.isLoggedIn = true;
        localStorage.setItem("approved", result1.data.approved);
        dispatch(islogged());
        dispatch(addUsername(result1.data.user_name));
        localStorage.setItem("projectUserName", result1.data.user_name);
        history.push(`/${title}`);
      } else {
        setLoader(false);
        history.push(`/logout`);
      }
    };
    if (title && string) {
      fetchTokenone();
    }
  }, []);

  useEffect(() => {
    if (!title && !string) {
      const service = new AppInfoservice();
      let token = {
        token: UserStore.key,
      };
      service.check_token(token).then((res) => {
        setLoader(false);
        if (res.status) {
          dispatch(islogged());
          UserStore.isLoggedIn = true;
          setStatus(res.data.user_status);
          if (window.location.pathname) {
            history.push(
              window.location.pathname === "/login"
                ? "/dashboard"
                : window.location.pathname
            );
          } else {
            history.push("/dashboard");
          }
        } else {
          if (
            window.location.pathname.includes("/reset_password") ||
            window.location.pathname.includes("/reset_tran_password") ||
            window.location.pathname.includes("/replica") ||
            window.location.pathname.includes("/privacy-policy") ||
            window.location.pathname.includes("/terms-conditions") ||
            window.location.pathname.includes("/replica_register") ||
            window.location.pathname.includes("/lcp") ||
            window.location.pathname.includes("/login") ||
            window.location.pathname.includes("/forgotPassword") ||
            window.location.pathname.includes("/genealogyTree") ||
            window.location.pathname.includes("/mobileGenealogy") ||
            window.location.pathname.includes("/mobileSponser") ||
            window.location.pathname.includes("/mobileTreeView") ||
            window.location.pathname.includes("/confirm_email")
          ) {
            history.push(window.location.pathname);
            // history.push(window.location.pathname);
          } else {
            dispatch(logout());
            localStorage.removeItem("login");
            localStorage.removeItem("apiKey");
            UserStore.key = "";
            history.push("/login");
          }
        }
      });
    }
  }, [history, dispatch]);

  if (loader) {
    return <Spinner />;
  }

  return (
    <Switch>
      <Route path="/reset_password/:resetkey" component={ResetPassword} />
      <Route
        path="/reset_tran_password/:resetkey"
        component={ResetTranPassword}
      />
      {/* <Route path="/unapproved/dashboard" component={UnApprovedDashboard} /> */}
      <Route path="/mobileSponser" component={SponsorTree} />
      <Route path="/mobileTreeView" component={TreeView} />
      <Route path="/mobileGenealogy" component={GenealogyTree} />
      {/* {replicaRoute.map((component, index) => (
        <Route path={component.path} exact={component.exact} key={index}>
          withStatusCheck(
          <component.component />
          ,component)
        </Route>
      ))} */}

      {logged ? (
        <MainLayout>
          <Switch>
            {unAuthorized.includes(status)
              ? unsubRoute.map((component, index) => (
                  <Route
                    path={component.path}
                    exact={component.exact}
                    key={index}
                    component={component.component}
                  />
                ))
              : routes.map((component, index) => (
                  <Route
                    path={component.path}
                    exact={component.exact}
                    key={index}
                    component={component.component}
                  />
                ))}
            <Route path="/404" component={NotFount} />
            <Redirect to="/404" />
          </Switch>
        </MainLayout>
      ) : (
        <AuthLayout>
          {publicRoute.map((component, index) => (
            <Route path={component.path} exact={component.exact} key={index}>
              <component.component />
            </Route>
          ))}
        </AuthLayout>
      )}
    </Switch>
  );
};

export default RouteMap;
