import React, { useMemo, useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import style from "../shopping/cart.module.scss"; //change
import { useTranslation } from "react-i18next";
import AddonNotification from "../../components/common/AddonNotification";
import moment from "moment";
import PaymentMethods from "../common/PaymentMethods";
import AlertBs from "../common/AlertBs";
import { useSelector } from "react-redux";
import { MaterialReactTable } from "material-react-table";
import { useHistory } from "react-router-dom";
import { CurrencyDecimalFormat } from "../../helper";
import ProfileService from "../../../service/profile";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

function TableView(props) {
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  const history = useHistory();

  const clcickOnSub = (id) => {
    history.push(`/pay-now/${id}`);
  };
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [state, setState] = useState({
    product: [],
    count: "",
    overdue: "",
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  useEffect(() => {
    const service = new ProfileService();

    const fetchData = async () => {
      const offset = pagination.pageIndex * pagination.pageSize;
      const limit = pagination.pageSize;
      const level = "all";
      const columnFilter = JSON.stringify(columnFilters) ?? "[]";
      const sortFilter = JSON.stringify(sorting) ?? "[]";
      service
        .subscriptionDetails(offset, limit, columnFilter, sortFilter)
        .then((res) => {
          console.log(res);
          if (res.status) {
            setState((prevState) => ({
              ...prevState,
              product: res.data.result,
              count: res.data.resultCount,
              overdue: res.data.overduepayments,
            }));
          }
        });
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "renewal_date",
        header: "RENEWAL DATE ",
        size: 100,
        filterVariant: "date-range",
        Cell: ({ row }) =>
          row?.original?.renewal_date
            ? moment(row?.original?.renewal_date).format("MMM DD ,YYYY")
            : "NA",
      },
      {
        accessorKey: "renewed_on",
        header: "RENEWED ON",
        size: 100,
        filterVariant: "date-range",
        Cell: ({ row }) =>
          row?.original.renewed_on
            ? moment(row?.original.renewed_on).format("MMM DD ,YYYY")
            : "NA",
      },
      {
        accessorKey: "subscription_type", //access nested data with dot notation
        header: "SUBSCRIPTION TYPE",
        size: 100,
        enableColumnFilter: false,

        enableSorting: false,
        Cell: ({ row }) =>
          row?.original?.subscription_type == "monthly" ? (
            <p>Monlthy Support fee</p>
          ) : row?.original?.subscription_type == "enrolment" ? (
            <p>Enrollment Fee</p>
          ) : (
            <p>Annual Fee</p>
          ),
      },

      {
        accessorKey: "amount_payable",
        header: "AMOUNT",
        size: 150,
        Cell: ({ row }) => (
          <span className={`${style.badgegreen} ${style.badge}`}>
            {row?.original["currency.symbol_left"]}{" "}
            {parseFloat(row?.original.amount_payable).toFixed(2)}
          </span>
        ),
      },
      {
        accessorKey: "status",
        header: "STATUS",
        size: 150,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <div>
              {OrderStatus[row?.original?.status] == "Cancelled" ? (
                <Button
                  onClick={() => {
                    clcickOnSub(row?.original?.id);
                  }}
                >
                  Retry Payment
                </Button>
              ) : (
                OrderStatus[row?.original?.status]
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  let OrderStatus = {
    0: "Pending",
    1: "Paid",
    2: "Cancelled",
    3: "Processing",
  };

  return (
    <div className={style.MainContainer}>
     
 
      <div className="material-table" style={{ marginTop: "10px" }}>
        <MaterialReactTable
          columns={columns}
          data={state.product}
          enableHiding={false}
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={state.count}
          manualPagination={true}
          manualFiltering={true}
          manualSorting={true}
          // enableExpandAll={false} //disable expand all button
          muiDetailPanelProps={() => ({
            sx: (theme) => ({
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255,210,244,0.1)"
                  : "rgba(0,0,0,0.1)",
            }),
          })}
          enableGlobalFilter={false}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
      </div>
    </div>
  );
}
const SubscriptionRenewal = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TableView />
  </LocalizationProvider>
);

export default SubscriptionRenewal;
