import React from 'react'
import { Helmet } from 'react-helmet'
import CommisionsTitle from './CommisionsTitle'
import { useTranslation } from 'react-i18next'
import companyName from '../../../store/mobxStore/companyName'
import { PageTitle } from '../../components/common'
import CommisionContent from './CommisionContent'
function Commisions() {
    const {t}=useTranslation()
  return (
    <div className="h-100">
    <Helmet>
        <title>{companyName.companyName} | {t('Sidemenu.commisions')}</title>
    </Helmet>
    <PageTitle
        title="commisions"
        buttonOn={false}
    />
    <CommisionContent/>

</div>
  )
}

export default Commisions