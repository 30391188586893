import API from "../../api/api";

export default class RankadvService {
  key = localStorage.getItem("apiKey");
  ranklist = async () => {
    return await API.API.get("rank/rank_list").then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    });
  };
  rankdetails = async (id) => {
    return await API.API.get("rank/rank_deatils?rank_id=" + id).then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res;
      }
    });
  };
}
