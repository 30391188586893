import React from "react";
import { Card, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CustumBtnGroup, FormInputControl, CustomButton } from "../common";
import style from "./party.module.scss";
function StepOne(props) {
  const { t } = useTranslation();
  return (
    <Card className={`${style.Card}`}>
      <Card.Body className={`${style.CardBody}`}>
        <legend>
          <span>{t("Common.stepOne_PartyNameAndImage")}</span>
        </legend>
        <FormInputControl
          label={t("Common.partyName")}
          required={true}
          inputProps={{
            type: "text",
            name: "name",
            value: props.partyContent.name,
            required: true,
            onChange: props.changeHandler,
          }}
          error={props.partyContent.FormError.name}
        />
        <div className={`col-sm-6 pr-0 pl-0 ${style.fileContent}`}>
          <div className={`${style.partyImageUpload}`}>
            <div className={`${style.fileUplaod}`}>
              <div className={`${style.thumbnail} pull-right`}>
                <Image src={props.partyContent.image} thumbnail />
              </div>
              <div className={`${style.uploadButtons}`}>
                <CustumBtnGroup variant="success">
                  {props.partyContent.uploadFile ? (
                    <i className="fa fa-file-image-o"></i>
                  ) : (
                    <i className="fa fa-arrow-circle-o-up"></i>
                  )}
                  {props.partyContent.uploadFile
                    ? t("Button.change")
                    : t("Button.selectImage")}
                  <input
                    type="file"
                    className={style.fileUplaod}
                    name="file"
                    id=""
                    onChange={props.changeFile}
                  />
                </CustumBtnGroup>
                {/* <Form.Text
                  className={`
                ${
                  props.error && props.error.error
                    ? !props.textSuccess && style.isInvalid
                    : "d-none"
                }
                `}
                >
                  {props.textIcon && [
                    props.textSuccess ? (
                      <img
                        src={`${IMG_URL}/public_html/images/accepted.png`}
                        alt=""
                        key="success"
                        className={style.textSuccess}
                      />
                    ) : (
                      <i
                        className="fa fa-times-circle"
                        style={{ color: "red" }}
                        key="failiure"
                      ></i>
                    ),
                  ]}
                  &nbsp;
                  {props.error && t(props.error.error, props.error.field)}
                </Form.Text> */}
              </div>
              {props.partyContent.uploadFile && (
                <CustomButton
                  variant="primary"
                  onClick={props.removeImage}
                  btnclass={style.removeBtn}
                >
                  <i className="fa fa-times"></i>
                  {t("Button.remove")}
                </CustomButton>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default StepOne;
