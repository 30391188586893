
import React from "react";
import { Modal } from "react-bootstrap";
import style from "./registration.module.scss";
import { useSelector } from "react-redux";

function TAndC(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  return (
    <Modal
      show={props.data.show}
      onHide={props.handleClose}
      contentClassName={style.ModalContent}
    >
      <Modal.Header closeButton className={style.termsModalheader}>
        <Modal.Title>Terms and conditions</Modal.Title>
      </Modal.Header>

      <Modal.Body
        dangerouslySetInnerHTML={{ __html: props.data.content }}
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_modal_body}`
            : `${style.modal_body}`
        }`}
      ></Modal.Body>
    </Modal>
  );
}

export default TAndC;
