import React from "react";
import style from "./cart.module.scss";
import { Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
function ContactInfo(props) {
  let defaultaddress =
    props.address &&
    props?.address.find((address) => address["default"] == true);
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Row>
        {props?.address.map((addres, index) => (
          <Col md={3} sm={3} key={index}>
            <Card className={style.addressCard}>
              <Card.Header>
                <div className={style.addressHeader}>
                  <span>{addres.name}</span>
                  <span
                    className={style.close}
                    onClick={() => {
                      props.removeAddress(addres.id);
                    }}
                  >
                    <span aria-hidden="true">x</span>
                  </span>
                </div>
              </Card.Header>
              <Card.Body className={`${style.addressCardBody}`}>
                <ListGroup>
                  <ListGroup.Item>{addres.address}</ListGroup.Item>
                  <ListGroup.Item>{addres.town}</ListGroup.Item>
                  <ListGroup.Item>{addres.pin}</ListGroup.Item>
                  <ListGroup.Item>{addres.mobile}</ListGroup.Item>
                </ListGroup>

                <div className={`text-center `}>
                  <Button
                    className={`${style.btnAddon} ${style.btnAddonInfo} mt-1 mb-1`}
                    disabled={"default" in addres && addres.default}
                    onClick={() => {
                      props.setDefault(addres.id);
                    }}
                  >
                    <i className="fa fa-eye"></i>
                    {t("Button.default")}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Button
        className={`${style.btnAddon} ${style.btnPrimary} ${style.submittButton}`}
        onClick={props.modalToogle}
      >
        <i className="fa fa-plus"></i>
        {t("Button.newAddress")}
      </Button>
      <div className={style.wizardButton}>
        <Button
          style={{ marginRight: "10px" }}
          className={`${style.btnAddonInfo}`}
          onClick={props.prev}
        >
          {t("Button.back")}
        </Button>
        <Button
          className={` ${style.btnPrimary}`}
          disabled={props.address.length === 0 || !defaultaddress}
          onClick={() => {
            props.next();
          }}
        >
          {t("Button.continue")}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default ContactInfo;
