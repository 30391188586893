import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./teamcab.module.scss";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PeriodFilter from "./PeriodFilter";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";

const stylees = {
  paddingTop: "10px",
  fontFamily: "system-ui",
};

function Content({
  tableData,
  changeHandler,
  paginationHandler,
  repurchase_status,
  exportData,
  loader,
  filterPeriods,
  onPeriodChanges,
  submitSearch,
}) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [tabs, setTabs] = useState(["earning"]);
  useEffect(() => {
    if (repurchase_status !== "yes") {
      setTabs(["statement", "earning"]);
    }
  }, [repurchase_status]);

  let tamcabstatus = {
    0: {
      status: "Initiated",
      color: "green",
    },
    1: {
      status: "Processing",
      color: "green",
    },
    2: {
      status: "successful",
      color: "green",
    },
    3: {
      status: "unsuccessful",
      color: "red",
    },
  };

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.rank_name}
          </TableCell>
          <TableCell align="right">{row.count}</TableCell>
          <TableCell align="right">{row.bonus_amount}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  DownLines
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Period</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.userDetails.map((historyRow, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{historyRow.period}</TableCell>
                        <TableCell>{historyRow.full_name}</TableCell>
                        <TableCell>{historyRow.user_name}</TableCell>
                        <TableCell>
                          <span
                            style={{
                              color:
                                tamcabstatus[historyRow.teamcabs_status]?.color,
                            }}
                          >
                            {tamcabstatus[historyRow.teamcabs_status]?.status}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <div className={style.summary}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_tabContent}`
            : `${style.tabContent}`
        }`}
      >
        <PeriodFilter
          filterPeriods={filterPeriods}
          onPeriodChanges={onPeriodChanges}
          submitSearch={submitSearch}
          exportData={exportData}
        />
        <div className={style.dataTable} style={stylees}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Rank Name</TableCell>
                  <TableCell align="right">Open Lines</TableCell>
                  <TableCell align="right">Bonus Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 ? (
                  tableData.map((row) => <Row key={row.rank_name} row={row} />)
                ) : (
                    <div
                  className={`${style.NodataImage}`}
                  style={{ textAlign: "center" }}
                >
                  <img src={nodata} alt="nodata" style={{ width: "50%" }} />
                  <p>No Team Cabs found on this period</p>
                </div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default Content;
