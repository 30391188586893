import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import PageTitle from "../../components/common/pageTitle";
import Spinner from "../../shared/Spinner";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
import SubscribeCard from "../../components/subscribe/SubscribeCard";
import ProfileService from "../../../service/profile";
import AlertMessage from "../.././components/common/AlertMessage";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement("script"), {
      type: "text/javascript",
      defer: true,
      src,
      onerror: (e) => {
        reject(e);
      },
    });
    scriptElem.onload = () => {
      resolve();
    };
    document.body.appendChild(scriptElem);
  });

const Subscribe = (props) => {
  const { t } = props;
  const service = new ProfileService();
  const [safeCharge, setSafeCharge] = useState(null);
  const [state, setState] = useState({
    card: [],
    crypto: [],
    paymentData: "",
    userDetails: "",
    loading: true,
    notify: {
      show: false,
      type: "",
      message: "",
      header: "",
    },
    deleteloader: false,
    paymentMethods: []
  });

  useEffect(() => {
    loadDetails();
  }, []);

  useEffect(() => {
    loadScript(
      "https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js" //production
    ).then(() => {
      setSafeCharge(
        window.SafeCharge({
          env: process.env.REACT_APP_env,
          merchantId: process.env.REACT_APP_merchantID,
          merchantSiteId: process.env.REACT_APP_merchantSiteId,
        })
      );
    });
  }, []);

  const loadDetails = () => {
    service.cardDetails().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          card: res.data.card_data,
          crypto: res.data.crypto_data,
          paymentData: res.data.nuvei_deatils,
          userDetails: res.data.user_deatils,
          paymentMethods: res.data.paymentMethod,
          loading: false,
        }));
      } else {
        console.log(res);
      }
    });
  };

  const onDeleteCard = (id) => {
    let formData = {
      card_id: id,
    };
    setState({
      ...state,
      deleteloader: true,
    });
    service.deleteCardDetails(formData).then((res) => {
      if (res.status) {
        setState({
          ...state,
          notify: {
            show: true,
            type: "success",
            message: "Card details deleted",
          },
          deleteloader: false,
        });
        loadDetails();
      } else {
        setState({
          ...state,
          notify: {
            show: true,
            type: "danger",
            message: "Card details not deleted",
          },
          deleteloader: false,
        });
      }
    });
  };
  const setDefaultCard = (id) => {
    let formData = {
      card_id: id,
    };
    setState({
      ...state,
      deleteloader: true,
    });
    service.setDefaultCard(formData).then((res) => {
      if (res.status) {
        setState({
          ...state,
          notify: {
            show: true,
            type: "success",
            message: "Default card updated",
          },
          deleteloader: false,
        });
        loadDetails();
      } else {
        setState({
          ...state,
          notify: {
            show: true,
            type: "danger",
            message: "Card details not updated",
          },
          deleteloader: false,
        });
      }
    });
  };
  const setDefaultCrypto = (id) => {
    let formData = {
      gateway: id,
    };
    setState({
      ...state,
      deleteloader: true,
    });
    service.setDefaultCrypto(formData).then((res) => {
      if (res.status) {
        setState({
          ...state,
          notify: {
            show: true,
            type: "success",
            message: "Default Crypto updated",
          },
          deleteloader: false,
        });
        loadDetails();
      } else {
        setState({
          ...state,
          notify: {
            show: true,
            type: "danger",
            message: "Crypto details not updated",
          },
          deleteloader: false,
        });
      }
    });
  };
  const dissmissNotify = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: false,
      },
    }));
  };

  if (state.loading) {
    return <Spinner />;
  }

  return (
    <>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.subscribe")}
        </title>
      </Helmet>
      <PageTitle title="subscribe" buttonOn={false} />
      {state.notify.show && (
        <AlertMessage
          show={state.notify.show}
          message={state.notify.message}
          dismiss={dissmissNotify}
          type={state.notify.type}
          header={state.notify.header}
        />
      )}

      <SubscribeCard
        card={state.card}
        crypto={state.crypto}
        onDeleteCard={onDeleteCard}
        setDefaultCard={setDefaultCard}
        setDefaultCrypto={setDefaultCrypto}
        paymentData={state.paymentData}
        safeCharge={safeCharge}
        userDetails={state.userDetails}
        deleteloader={state.deleteloader}
        loadDetails={loadDetails}
        paymentMethods={state.paymentMethods}
      />
    </>
  );
};

export default withTranslation()(withRouter(Subscribe));
