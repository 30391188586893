import React, { useState, useEffect } from 'react'
import style from './profile.module.scss';
import ProfileService from '../../../service/profile';
import { useHistory } from 'react-router-dom';
import AppInfoservice from '../../../service/common/Appinfo';
import { useTranslation } from 'react-i18next';
import proPic from '../../../assets/images/nophoto/no_photo.jpg';




function ProfileImage(props) {

  const [url, setUrl] = useState();
  const history = useHistory();
  const { t } = useTranslation();






  const onFileChange = event => {


    // Update the state
    if (event.target.files[0]) {

      let formdata = new FormData();
      let service = new ProfileService();
      formdata.append('image', event.target.files[0])
      service.uploadImage(formdata).then(res => {

        if (res.status) {
          setUrl(URL.createObjectURL(event.target.files[0]))
          props.notify('success', 'Common.success', 'Common.profileUpdatedSuccesfully');
          setTimeout(() => {
            props.getData();
          }, 1000);
        } else {
          if (res.error.code === 1038) {
            props.notify('error', 'Common.error', 'validation.tooManyUploadLimit');
          } else if (res.error.code === 1017) {
            props.notify('error', 'Common.error', 'validation.fileTypeNotSupported');
          } else if (res.error.code === 1018) {
            props.notify('error', 'Common.error', 'validaion.fileTypeExceeded');
          } else if (res.error.code === 1024) {
            props.notify('error', 'Common.error', 'Common.error_file_upload');
          } else if (res.error.code === 1002) {
            history.push('/logout');
          } else if (res.error.code === 1032) {
            props.notify('error', 'Common.error', 'validaion.chooseFile')
          }
        }
      })
    }

  };
  useEffect(() => {
    setUrl(props.image);

  }, [props])



  const onDeletePic = event => {

    if (window.confirm(t('validation.sureWantToDelete'))) {
      let service = new ProfileService();

      service.deleteImage().then(res => {
        if (res.status != 404) {
          props.getData();

          props.notify('success', 'Common.success', 'Common.profileDeletedSuccesfully');
        } else if (res.status == 404) {
          props.notify('error', 'Common.error', 'Common.cantdeletedefaultpic');

        }

      })

    }

  };

  const dateCalculation = (renewdate)=>{

    const date = new Date();

    let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();
let currentDate = `${month}/${day}/${year}`;

// To set two dates to two variables
var date1 = new Date(currentDate);
var date2 = new Date(renewdate);
// To calculate the time difference of two dates
var Difference_In_Time = date2.getTime() - date1.getTime();
// To calculate the no. of days between two dates
var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
var value = (Difference_In_Days / 30) * 100;
if(value > 100){
  value = 100;
}
return value;

  }

  const colorChange = ()=>{
  var percentage = dateCalculation(props.date);
  if(percentage> 100)
  {
    percentage = 100
  }
    if(percentage > 50)
    return "#3498db"
    else if(percentage > 20)
    return "#FFA500"
    else
    return "#FF0000"

  }







  return (


    <div className={style.avatarImage}>
      <div className={style.avatarEdit}>
        <input type="file" id="imageUpload" accept=".png,.jpg,.jpeg" onChange={onFileChange} />
        <label htmlFor="imageUpload" >
        </label>
      </div>
      <div className={style.avatarPreview}>
        <img src={url ? url : proPic} alt="" />
        {props.date && <div class="percent">
          <svg>
            <circle cx="105" cy="105" r="60"></circle>
            <circle cx="105" cy="105" r="60" style={{ "--percent": dateCalculation(props.date) , stroke : colorChange() }}></circle>
          </svg>

        </div>}
      </div>
      <button style={{
        color: "red", top: "80px",
        left: "18px",
        position: "absolute",
        background: "#201e1ed4", cursor: "pointer", borderRadius: "100%", width: "34px", height: "34px"
      }} onClick={onDeletePic}><i className="fa fa-trash-o" aria-hidden="true"></i></button>
    </div>
  )
}

export default ProfileImage;
