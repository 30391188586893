import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "bootstrap-daterangepicker/daterangepicker.css";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import style from "./datatable.module.scss";

import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";

import DownloadService from "../../../service/tools/download";
import AppInfoservice from "../../../service/common/Appinfo";
import Select from "react-select";

function Filter({
  filterHandler,
  type,
  resetHandler,
  isSingle,
  ContentData,
  loader,
  button_group,
  onChange,
  fetchData,
  selectOptions,
  setDateOptions,

  ...props
}) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const componentRef = useRef();
  const [selectedcountry, setSelectedcountry] = useState();
  const [state, setState] = useState({
    selectedCountry: "",
    selectedValues: [],
    start: moment().startOf("month"),
    end: moment(),
    label:
      moment().startOf("month").format("MMMM D, YYYY") +
      " - " +
      moment().format("MMMM D, YYYY"),
  });
  const [countries, setCountries] = useState();
  const service = new DownloadService();
  useEffect(() => {
    const commonService = new AppInfoservice();

    commonService.getCountry().then((res) => {
      const newArray = res?.data?.country?.map((item) => ({
        value: item.value,
        key: item.title,
      }));

      setCountries(newArray);
    });
  }, []);

  const { start, end, label, selectedValues } = state;
  const array = [
    { key: 1, value: "abh" },
    { key: 2, value: "abh" },
  ];
  //customselect options
  const options = {
    Today: [moment().toDate(), moment().toDate()],
    "This Week": [moment().startOf("week"), moment().endOf("week").toDate()],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "This Year": [
      moment().startOf("year").toDate(),
      moment().endOf("year").toDate(),
    ],
  };

  //datepicker select
  const handleCallback = (start, end) => {
    setState((prev) => ({
      ...prev,
      start: start,
      end: end,
      label: start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"),
    }));
    setDateOptions(start, end);
  };

  // multi select css components
  let styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      minWidth: "10%",
      maxWidth: "max-content",
      display: "inline-block",
    },
    inputField: {
      // To change input field position or margin
      margin: "5px",
      width: "40px",
    },
    chips: {
      // To change css chips(Selected options)
      background: "rgb(228, 228, 228)",
      color: "rgb(88, 102, 110)",
      borderRadius: "10px",
      height: "20px",
    },
    optionContainer: {
      // To change css for option container
      boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
      border: "none",
    },
    searchBox: {
      // To change search box element look
      padding: 0,
      marginRight: "5px",
      paddingLeft: "5px",
    },
    option: {
      // To change css for dropdown options
      color: "black",
    },
  };
  //on select the multi select
  const selecthandler = (e) => {
    setState((prev) => ({
      ...prev,
      selectedValues: e,
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
  };

  //remove selected items

  const removeHandler = (e) => {
    setState((prev) => ({
      ...prev,
      selectedValues: e,
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
  };

  //reset button click
  const resetValue = () => {
    setState((prev) => ({
      ...prev,
      selectedValues: [],
      start: moment().startOf("month"),
      end: moment(),
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
    resetHandler(start, end, selectedValues);
    // filterHandler(start.format('YYYY-MM-DD'),end.format('YYYY-MM-DD'),selectedValues);
  };

  const submitHandler = () => {
    filterHandler(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
  };

  const handleSelectChange = (e) => {
    setSelectedcountry(e.target.value);
  };
  return (
    <div className={style.filterSection}>
      <DateRangePicker
        initialSettings={{
          startDate: start.toDate(),
          endDate: end.toDate(),
          ranges: options,
        }}
        onCallback={handleCallback}
      >
        <div
          style={{ padding: 7 }}
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_DateRangePicker}`
              : `${style.DateRangePicker}`
          }`}
        >
          <i className="fa fa-calendar"></i>&nbsp;
          <span>{label}</span> <i className="fa fa-caret-down"></i>
        </div>
      </DateRangePicker>

      {/* <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_DateRangePicker}`
            : `${style.DateRangePicker}`
        }`}
        style={{ border: "none", minWidth: "20%" }}
      >
        <Select
          style={styles}
          onChange={(entry) => {
            onChange(entry);
          }}
          value={type}
          multi={true}
          options={selectOptions}
        />
      </div> */}

      <div className={style.filterButton} style={{ margin: 10 }}>
        <Button
          variant="info"
          className={style.padding5}
          onClick={submitHandler}
        >
          {t("Button.search")}
        </Button>

        <Button
          className={style.padding5}
          onClick={resetValue}
          disabled={loader?.EarningsTable.reset}
        >
          {loader && loader.EarningsTable.reset ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )}
          {t("Button.reset")}
        </Button>
      </div>
      {props.exports && (
        <div className={style.exportButton}>
          <Button
            disabled={loader.EarningsTable.excel}
            onClick={() => {
              props.exportData(
                start.format("YYYY-MM-DD"),
                end.format("YYYY-MM-DD"),
                selectedValues,
                "excel"
              );
            }}
          >
            {loader && loader.EarningsTable.excel ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              />
            ) : (
              <i className="fa fa-file-excel-o"></i>
            )}

            {t("Button.excel")}
          </Button>
          <Button
            disabled={loader.EarningsTable.csv}
            onClick={() => {
              props.exportData(
                start.format("YYYY-MM-DD"),
                end.format("YYYY-MM-DD"),
                selectedValues,
                "csv"
              );
            }}
          >
            {loader.EarningsTable.csv ? (
              <i
                className="fa fa-refresh fa-spin"
                style={{ marginRight: "5px", backgroundColor: "transparent" }}
              />
            ) : (
              <i className="fa fa-file-text-o"></i>
            )}

            {t("Button.CSV")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default Filter;
