import React, { useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Profile from "../../../assets/images/nophoto/no_photo.jpg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Content from "../../view/Rankadvancement/Content";
import CircularProgressBar from "../Circularprogrssbar/Circularprogress";
import RankModalView from "./UserModalViews/RankModalView";
import DownLineMember from "../../view/tree/DownLineMember";
import Volumes from "../../view/Datatables/Volumes";
import VolumesModalView from "./UserModalViews/VolumesModalView";
import { MaterialReactTable } from "material-react-table";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppInfoservice from "../../../service/common/Appinfo";
import moment from "moment";
function NewmembersModal(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [isTabChanging, setIsTabChanging] = useState(false);
  const navigation = useHistory();
  //   const navigate = useNavigate();
  const [id, setId] = useState("");
  const [userDetails, setUserdetails] = useState("");

  const style = {
    position: "absolute",
    // style={isTabChanging?{opacity:0, transition: "opacity 0.3s ease"  }:{}}
    // opacity:isTabChanging?0.5:1,

    // top: '20%',
    top: "2%",
    left: 0,
    right: 0,
    margin: "auto",
    width: "100%",
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    height: "auto",
    padding: "1%",
    maxWidth: "1000px",
    borderRadius: "1vh",
  };
  const StyledTabs = styled((props) => (
    <Tabs
      variant="fullWidth"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      // visibleScrollbar
      // aria-label="scrollable auto tabs example"
      {...props}
      // allowScrollButtonsMobile
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
      // backgroundColor: '#635ee7',
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      // marginRight: theme.spacing(1),

      color: currentTheme == "theme-dark" ? "#868686" : "#00090",
      // background:"rgba(128, 128, 128, 0.075)",
      "&.Mui-selected": {
        color: currentTheme == "theme-dark" ? "#fff" : "rgb(88, 147, 241)",
        backgroundColor: currentTheme != "theme-dark" ? "#ffffff" : "",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  const handleClose = () => {
    setShow(false);
  };
  const [show, setShow] = useState(props.show ? props.show : false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setIsTabChanging(true);
    getprofiledetails(newValue);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 300);
    setSelectedTab(newValue);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);
  const [selectedtype, setSelectedtype] = useState("activity");
  const getprofiledetails = (value) => {
    switch (value) {
      case 0:
        setSelectedtype("activity");
        break;
      case 1:
        setSelectedtype("rank");
        break;
      case 2:
        setSelectedtype("volume");
        break;

      case 3:
        setSelectedtype("orders");
        break;
    }
  };

  useEffect(() => {
    setId(props.id);
    const service = new AppInfoservice();

    service.getProfilebyId(props.id, selectedtype).then((res) => {
      setUserdetails(res.data);
    });
  }, [props.id, selectedtype]);

  const categories = [
    { name: "Activity" },
    { name: "Next Rank" },
    { name: "Volumes" },
    { name: "Orders" },
    { name: "Auto Orders" },
  ];

  const user = [
    { active: 1 },
    { active: 1 },
    { active: 1 },
    { active: 1 },

    { active: 1 },
    { active: 1 },
    { active: 1 },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: "user_rank_id", //normal accessorKey
        header: " Period",
        size: 100,
      },
      {
        accessorKey: "first_name", //access nested data with dot notation
        header: "Start date",
        size: 100,
      },
      {
        accessorKey: "second_name",
        header: "End date",
        size: 150,
      },

      {
        accessorKey: "active",
        header: "Paid as title",
        size: 150,
      },
      {
        accessorKey: "next_rank",
        header: "personal business volume",
        size: 150,
      },
      {
        accessorKey: "next_rank",
        header: "Group business volume",
        size: 200,
      },

      {
        accessorKey: "percent",
        header: "Leg1 count",
        size: 150,
      },
      {
        accessorKey: "percent",
        header: "Leg2 count",
        size: 150,
      },
      {
        accessorKey: "percent",
        header: "Leg 3 count",
        size: 150,
      },
      {
        accessorKey: "percent",
        header: "Leg 1 volume",
        size: 150,
      },
    ],
    []
  );
  const orders = useMemo(
    () => [
      {
        accessorKey: "order_date", //normal accessorKey
        header: " Order Date",
        size: 150,
      },
      {
        accessorKey: "total", //access nested data with dot notation
        header: "Total",
        size: 150,
      },
      {
        accessorKey: "bv",
        header: "BV",
        size: 150,
      },

      {
        accessorKey: "cv",
        header: "CV",
        size: 150,
      },
    ],
    []
  );
  const auto_orders = useMemo(
    () => [
      {
        accessorKey: "last process date", //normal accessorKey
        header: "Last process date",
        size: 150,
      },
      {
        accessorKey: "next process date", //access nested data with dot notation
        header: "Next Process date",
        size: 150,
      },
      {
        accessorKey: "subtotal",
        header: "Sub total",
        size: 150,
      },

      {
        accessorKey: "bv",
        header: "BV",
        size: 150,
      },
      {
        accessorKey: "cv",
        header: "CV",
        size: 150,
      },
    ],
    []
  );
  const userData = [{}];
  return (
    <>
      <Modal
        open={show}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={props.onclose}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            noWrap
            variant="h5"
            component="h5"
            style={{
              letterSpacing: 3,
            }}
          >
            {/* CUSTOMER DETAILS      */}
          </Typography>

          <button
            type="button"
            class="close"
            aria-label="Close"
            onClick={props.onclose}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <span
            style={{ marginTop: 15, justifyContent: "center", display: "flex" }}
          ></span>
          <Typography id="modal-modal-description">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100px 1fr 100px",
              }}
            >
              <img src={Profile} alt="" style={{ height: 80, width: 80 }} />
              <div>
                <h3>
                  <strong>
                    {" "}
                    {userDetails?.basic_details?.username.toUpperCase()}{" "}
                  </strong>{" "}
                </h3>
                <h4>
                  {userDetails?.basic_details?.user_type} since{" "}
                  {moment(userDetails?.basic_details?.date_of_joining).format(
                    "M/D/YYYY"
                  )}{" "}
                </h4>
                <h4> Lifetime Rank: {userDetails?.basic_details?.rank}</h4>
              </div>
              <div>
                <button
                  className={style.readmore_btn}
                  style={{ background: "none" }}
                  onClick={() => {
                    navigation.push("/genealogyTree");
                  }}
                >
                  <span>View Genelogy </span>
                </button>
              </div>
            </div>
            <StyledTabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="styled tabs example"
              style={
                isTabChanging
                  ? { opacity: 0, transition: "opacity 0.3s ease" }
                  : {}
              }
            >
              {categories?.map((item) => (
                <StyledTab label={item.name} />
              ))}
            </StyledTabs>
            {selectedTab == 0 && (
              <>
                no activities yet
                {/* <VolumesModalView/> */}
              </>
            )}
            {selectedTab == 1 && (
              <>
                {}
                <RankModalView />
              </>
            )}

            {selectedTab == 2 && (
              <>
                <MaterialReactTable
                  columns={columns}
                  data={user}
                  enableHiding={false}
                  onIsFullScreenChange={true}
                  // onColumnFiltersChange={setColumnFilters}
                  // onGlobalFilterChange={setGlobalFilter}
                  // onPaginationChange={setPagination}
                  // onSortingChange={setSorting}
                  // rowCount={userData.count}
                  // state={{
                  //   columnFilters,
                  //   globalFilter,
                  //   isLoading,
                  //   pagination,
                  //   showAlertBanner: isError,
                  //   showProgressBars: isRefetching,
                  //   sorting,
                  // }}
                  // renderTopToolbarCustomActions={({ table }) => (
                  //   <Box
                  //     sx={{
                  //       display: "flex",
                  //       gap: "1rem",
                  //       p: "0.5rem",
                  //       flexWrap: "wrap",
                  //     }}
                  //   >
                  //    <div className={style.exportButton}>
                  //       <Button onClick={()=>{handleExportData("excel")}}>
                  //         <i className="fa fa-file-excel-o"></i>
                  //         Excel
                  //       </Button>

                  //       <Button onClick={()=>{handleExportData("csv")}}>
                  //         <i className="fa fa-file-text-o"></i>
                  //         Csv
                  //       </Button>
                  //     </div>

                  //   </Box>
                  // )}
                />
              </>
            )}

            {selectedTab == 3 && (
              <>
                <MaterialReactTable
                  columns={orders}
                  data={userData}
                  enableHiding={false}
                  onIsFullScreenChange={true}
                  // onColumnFiltersChange={setColumnFilters}
                  // onGlobalFilterChange={setGlobalFilter}
                  // onPaginationChange={setPagination}
                  // onSortingChange={setSorting}
                  // rowCount={userData.count}
                  // state={{
                  //   columnFilters,
                  //   globalFilter,
                  //   isLoading,
                  //   pagination,
                  //   showAlertBanner: isError,
                  //   showProgressBars: isRefetching,
                  //   sorting,
                  // }}
                  // renderTopToolbarCustomActions={({ table }) => (
                  //   <Box
                  //     sx={{
                  //       display: "flex",
                  //       gap: "1rem",
                  //       p: "0.5rem",
                  //       flexWrap: "wrap",
                  //     }}
                  //   >
                  //    <div className={style.exportButton}>
                  //       <Button onClick={()=>{handleExportData("excel")}}>
                  //         <i className="fa fa-file-excel-o"></i>
                  //         Excel
                  //       </Button>

                  //       <Button onClick={()=>{handleExportData("csv")}}>
                  //         <i className="fa fa-file-text-o"></i>
                  //         Csv
                  //       </Button>
                  //     </div>

                  //   </Box>
                  // )}
                />
              </>
            )}
            {selectedTab == 4 && (
              <>
                <MaterialReactTable
                  columns={auto_orders}
                  data={userData}
                  enableHiding={false}
                  onIsFullScreenChange={true}
                  // onColumnFiltersChange={setColumnFilters}
                  // onGlobalFilterChange={setGlobalFilter}
                  // onPaginationChange={setPagination}
                  // onSortingChange={setSorting}
                  // rowCount={userData.count}
                  // state={{
                  //   columnFilters,
                  //   globalFilter,
                  //   isLoading,
                  //   pagination,
                  //   showAlertBanner: isError,
                  //   showProgressBars: isRefetching,
                  //   sorting,
                  // }}
                  // renderTopToolbarCustomActions={({ table }) => (
                  //   <Box
                  //     sx={{
                  //       display: "flex",
                  //       gap: "1rem",
                  //       p: "0.5rem",
                  //       flexWrap: "wrap",
                  //     }}
                  //   >
                  //    <div className={style.exportButton}>
                  //       <Button onClick={()=>{handleExportData("excel")}}>
                  //         <i className="fa fa-file-excel-o"></i>
                  //         Excel
                  //       </Button>

                  //       <Button onClick={()=>{handleExportData("csv")}}>
                  //         <i className="fa fa-file-text-o"></i>
                  //         Csv
                  //       </Button>
                  //     </div>

                  //   </Box>
                  // )}
                />
              </>
            )}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default NewmembersModal;
