import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
function Home() {

  useEffect(() => {

  }, []);




  return (
    <div className="h-100">
      <p>Hiiiii</p>

    </div>
  );
}

export default withTranslation()(withRouter(Home));
