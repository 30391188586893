import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import API from "../../api/api";

export default class GenealogyService {
  key = localStorage.getItem("apiKey");
  //get the referral members
  async getTreelist(userName = "", user_id = "") {
    const response = await fetch(
      `${BASE_URL}tree/genealogy_tree?user_name=${userName}&user_id=${user_id}`,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }

  //test genealogy
  async getTreelist2(userName = "", user_id = "") {
    const response = await fetch(
      `${BASE_URL}tree/genealogy_tree1?user_name=${userName}&user_id=${user_id}`,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }

  //sponsor tree
  async getSponsorTree(username, userId) {
    const response = await fetch(
      `${BASE_URL}tree/sponsor_tree?user_name=${username}&user_id=${userId}`,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }

  // new sponsor tree
  async getSponsorTree2(username, userId) {
    const response = await fetch(
      `${BASE_URL}tree/sponsor_tree1?user_name=${username}&user_id=${userId}`,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }

  submitPosition = async (formData) => {
    return await API.API.post("tree/lock_position", formData)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
}
