import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import style from "./payment.module.scss";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";

function FreeJoin(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [state, setState] = useState({ loader: false });

  return (
    <div className={style.MianContent}>
      <Form
        onSubmit={(e) => {
          setState((prev) => ({
            ...prev,
            loader: true,
          }));
          window.location.pathname.includes("/register") ||
          window.location.pathname.includes("/replica_register")
            ? props.submit(e, "", "payment")
            : props.submit(e, "");
        }}
      >
        <div>
          <pre
            className={`${
              currentTheme == "theme-dark"
                ? ` alert alert-danger`
                : `alert alert-info`
            }`}
          >
            {t("Common.clickFinish")}
          </pre>
          <div className={style.Buttons}>
            {props.prevButtonClick && (
              <Button
                color="primary"
                onClick={props.prevButtonClick}
                className={`btn-pill  ${style.PrevButton}`}
                style={{ marginRight: "20px" }}
              >
                <i className="fa fa-chevron-left" />
                {t("profile.prev")} &nbsp;
              </Button>
            )}
            <Button
              color="primary"
              className={`btn-pill ${style.NextButton}`}
              type="submit"
              style={{ marginRight: "20px" }}
              disabled={props.loader ? true : false}
            >
              {props.loader ? (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              ) : (
                ""
              )}
              {t("Button.finish")} &nbsp;
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default FreeJoin;
