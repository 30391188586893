import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import { BASE, IMG_URL } from "../../../configuration/apiconfig";
import { CurrencyDecimalFormat } from "../../helper";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import banner1 from "../../../assets/images/carousel/banner_1301.jpeg";
import banner2 from "../../../assets/images/carousel/banner_1302.jpeg";
import banner3 from "../../../assets/images/carousel/banner_1303.jpeg";
import DashboardService from "../../../service/dashboard/Dashboard";

function Banner({ memberList, lodervalue }) {
  const curr = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [carousalimg, SetcarousalImg] = useState();
  const service = new DashboardService();
  useEffect(() => {
    getBanner();
  }, []);
  const getBanner = () => {
    service.dashboardTiles().then((data) => {
      SetcarousalImg(data?.data?.banner);
    });
  };
  return (
    <div className={style.dashboard_new_member_sec}>
      <div className={`${style.dashboard_carousel_box}`}>
        {/* <> */}
          {carousalimg && carousalimg.length > 0 ? (
            <>
              <div
                // style={{ position: "fixed" }}
                className={`${
                  currentTheme == "theme-dark"
                    ? style.dark_mode_dashboard_new_member_head
                    : style.dashboard_new_member_head
                }`}
              ></div>

              <div>
                <Carousel
                  showThumbs={false}
                  className={style.dashboard_new_member_cnt_scrl}
                  autoPlay={true}
                  infiniteLoop={true}
                >
                  {carousalimg &&
                    carousalimg.map((item) => (
                      <div>
                        <img src={item.banner_image} />
                      </div>
                    ))}
                </Carousel>
              </div>
            </>
          ) : (
            <>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_pannel_box}`
                    : `${style.dashboard_pannel_box}`
                }`}
              >
                <div
                  className={`${
                    currentTheme == "theme-dark"
                      ? style.dark_mode_dashboard_new_member_head
                      : style.dashboard_new_member_head
                  }`}
                >
                  {t("Dashboard.banner")}
                </div>

                <div
                  className={`${style.NodataImage}`}
                  style={{ textAlign: "center" }}
                >
                  <img src={nodata} alt="nodata" style={{ width: "50%" }} />
                </div>
              </div>
            </>
          )}
        {/* </> */}
      </div>
    </div>
  );
}
export default Banner;
