import React, { useEffect, useState } from "react";
import style from "./dashboard.module.scss";
import { useTranslation } from "react-i18next";
import platinum from "../../../assets/images/dashboard/pltinum.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import  NumberDisplay  from "../../helper/NumberDisplay";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgressBar from "../Circularprogrssbar/Circularprogress";
const Ranks = React.memo((props) => {
  const navigation = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);
  useEffect(() => {
    if (document.getElementsByClassName("progress-bar")[0] != undefined) {
      document.getElementsByClassName("progress-bar")[0].style.backgroundColor =
        "#1b3275";
    }
  }, []);

  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const StyledTabs = styled((props) => (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      // visibleScrollbar
      // aria-label="scrollable auto tabs example"
      {...props}
      // allowScrollButtonsMobile
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      // justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
      // backgroundColor: '#635ee7',
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      // marginRight: theme.spacing(1),

      color: currentTheme == "theme-dark" ? "#868686" : "#00090",
      // background:"rgba(128, 128, 128, 0.075)",
      "&.Mui-selected": {
        color: currentTheme == "theme-dark" ? "#fff" : "rgb(88, 147, 241)",
        backgroundColor: currentTheme != "theme-dark" ? "#ffffff" : "",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );
  const [isTabChanging, setIsTabChanging] = useState(false);
  const handleChange = (event, newValue) => {
    setIsTabChanging(true);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 300);
    setSelectedTab(newValue);
  };

  const ranks = Object.keys(props.rank.allrank);
  const selectedRank = ranks[selectedTab];
  const selectedData = props.rank.allrank[selectedRank];
  const [containsGrossPV, setcontainsGrossPV] = useState();
  useEffect(() => {
    for (const item of selectedData) {
      if (item.title === "Group PV") {
        setcontainsGrossPV(true);
        break;
      }
    }
  }, [selectedData]);

  useEffect(() => {
    const ranks = Object.keys(props.rank.allrank);
    const index = ranks.findIndex(
      (rankName) => rankName === props?.rank?.current?.name
    );
    !props?.rank?.current?.name ?? setSelectedTab(0);

    if (index !== -1) {
      setSelectedTab(index);
    }
  }, [props?.rank?.next?.name]);

  //progress bar styling mui
  const BorderLinearProgress = styled(LinearProgress)(({ theme, success }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: success == true ? "#3dd89a" : "red",
      //  theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));
  return (
    <div className={style.dashboard_rank_sec}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_dashboard_pannel_box}`
            : `${style.dashboard_pannel_box}`
        }`}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className={`${
            currentTheme == "theme-dark"
              ? style.dark_mode_dashboard_new_member_head
              : style.dashboard_new_member_head
          }`}
        >
          {t("Common.Ranks")}

          <button
            className={style.readmore_btn}
            style={{ background: "none" }}
            onClick={() => {
              navigation.push("/rank-advancement");
            }}
          >
            <span>View all </span>
          </button>
        </div>
        <div className={style.dashboard_rank_scrl_sec}>
          <StyledTabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="styled tabs example"
            style={
              isTabChanging
                ? { opacity: 0, transition: "opacity 0.3s ease" }
                : {}
            }
          >
            {Object.keys(props?.rank?.allrank).map((item, index) => (
              <StyledTab label={item} />
            ))}
          </StyledTabs>

          {selectedData &&
            selectedData.map((rank) => (
              <>
                <div style={{ flexDirection: "row" }}>
                  <div>
                    {rank.text != "grosspersonalPv" && (
                      <div
                        style={
                          isTabChanging
                            ? {
                                opacity: 0,
                                transition:
                                  "opacity 0.3s ease, transform 0.5s ease",
                              }
                            : {}
                        }
                        className={`${style.dashboard_rank_row} ${
                          isTabChanging ? "tab-animation" : ""
                        }`}
                      >
                        <div
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_row_head}`
                              : `${style.dashboard_rank_row_head}`
                          }`}
                          style={{ marginTop: 25 }}
                        ></div>

                        <div className={style.dashboard_rank}>
                          <div className={style.dashboard_rank_ico}>
                            <img src={platinum} alt="" />
                          </div>
                          <div className={style.dashboard_rank_hd}>
                            {" "}
                            {t("Common." + rank.text)}{" "}
                          </div>
                          <div className={style.dashboard_rank_prg_bar}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={rank.percentage}
                              className={style.progress}
                              success={true}
                            />

                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dashboard_rank_prg_bar_cnt}`
                                  : `${style.dashboard_rank_prg_bar_cnt}`
                              }`}
                            >
                              {parseInt(rank.percentage)}%
                            </div>
                          </div>
                        </div>
                        <div className={style.dashboard_rank_cnt_sc}>
                          <div className={style.dashboard_rank_box}>
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dashboard_rank_box_rnd}`
                                  : `${style.dashboard_rank_box_rnd}`
                              }`}
                            >
                              {rank.required}
                              {/* {rank.required} */}
                            </div>
                            <h6 style={{ fontSize: 12 }}>
                              {t("Common.required")}
                            </h6>
                          </div>
                          <div className={style.dashboard_rank_box}>
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dashboard_rank_box_rnd}`
                                  : `${style.dashboard_rank_box_rnd}`
                              }`}
                            >
                              {rank.achieved}
                            </div>
                            <h6 style={{ fontSize: 12 }}>
                              {t("Common.achieved")}
                            </h6>
                          </div>
                        </div>
                        {/* <button style={{
borderRadius: '0 0 20px 20px',
color: currentTheme=="theme-dark"?"#fff":"#3B444B",

width: "100%",
height: "auto",
float: "left",
position: "absolute",
left: "0",
bottom: "0",
borderRadius: "10px",
padding: "5px",
transition: "background 0.3s ease", 
cursor: "pointer", 
border:"none",
background:currentTheme=="theme-dark"?"#112237":"#ffff",
boxShadow: '0px 9px 21px rgba(0, 0, 0, 0.03)'
}}
onClick={()=>{
navigation.push('/rank')

}}
>

<i  style={{fontSize:20}}class="fa fa-angle-double-down" aria-hidden="true"></i>

</button> 
    */}
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              </>
            ))}

          {/* {containsGrossPV && 
  <h5 style={{textAlign:"center"}}>

OR
</h5>
} */}

          {selectedData &&
            selectedData.map((rank) => (
              <>
                <div style={{ flexDirection: "row" }}>
                  <div>
                    {rank.text == "grosspersonalPv" && (
                      <div
                        style={
                          isTabChanging
                            ? {
                                opacity: 0,
                                transition:
                                  "opacity 0.3s ease, transform 0.5s ease",
                              }
                            : {}
                        }
                        className={`${style.dashboard_rank_row} ${
                          isTabChanging ? "tab-animation" : ""
                        }`}
                      >
                        <h5
                          style={{
                            textAlign: "center",
                            margin: "25px 0px -25px 0px",
                          }}
                        >
                          OR
                        </h5>
                        <div
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_row_head}`
                              : `${style.dashboard_rank_row_head}`
                          }`}
                          style={{ marginTop: 40 }}
                        ></div>

                        <div className={style.dashboard_rank}>
                          <div className={style.dashboard_rank_ico}>
                            <img src={platinum} alt="" />
                          </div>
                          <div className={style.dashboard_rank_hd}>
                            {" "}
                            {t("Common." + rank.text)}{" "}
                          </div>
                          <div className={style.dashboard_rank_prg_bar}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={rank.percentage}
                              className={style.progress}
                              success={true}
                            />

                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dashboard_rank_prg_bar_cnt}`
                                  : `${style.dashboard_rank_prg_bar_cnt}`
                              }`}
                            >
                              {parseInt(rank.percentage)}%
                            </div>
                          </div>
                        </div>
                        <div className={style.dashboard_rank_cnt_sc}>
                          <div className={style.dashboard_rank_box}>
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dashboard_rank_box_rnd}`
                                  : `${style.dashboard_rank_box_rnd}`
                              }`}
                            >
                              {rank.required}

                            </div>
                            <h6 style={{ fontSize: 12 }}>
                              {t("Common.required")}
                            </h6>
                          </div>
                          <div className={style.dashboard_rank_box}>
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_dashboard_rank_box_rnd}`
                                  : `${style.dashboard_rank_box_rnd}`
                              }`}
                            >
                              {rank.achieved}
                            </div>
                            <h6 style={{ fontSize: 12 }}>
                              {t("Common.achieved")}
                            </h6>
                          </div>
                        </div>
                        {/* <button style={{
borderRadius: '0 0 20px 20px',
color: currentTheme=="theme-dark"?"#fff":"#3B444B",

width: "100%",
height: "auto",
float: "left",
position: "absolute",
left: "0",
bottom: "0",
borderRadius: "10px",
padding: "5px",
transition: "background 0.3s ease", 
cursor: "pointer", 
border:"none",
background:currentTheme=="theme-dark"?"#112237":"#ffff",
boxShadow: '0px 9px 21px rgba(0, 0, 0, 0.03)'
}}
onClick={()=>{
navigation.push('/rank')

}}
>

<i  style={{fontSize:20}}class="fa fa-angle-double-down" aria-hidden="true"></i>

</button> 
    */}
                      </div>
                    )}
                  </div>
                  <div></div>
                </div>
              </>
            ))}

          {/* {Object.values(props.rank.allrank).map((ranking,index)=>(
  ranking.map((rank)=>(
    <>
    <div className={style.dashboard_rank_row}>
      <div className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_dashboard_rank_row_head}`
            : `${style.dashboard_rank_row_head}`
        }`}
        style={{marginTop:40}}>
      </div>
    
      <div className={style.dashboard_rank} >
              <div className={style.dashboard_rank_ico}>
                <img src={platinum} alt="" />
              </div>
              <div className={style.dashboard_rank_hd}>
                {" "}
                {t("Common." + rank.text)}{" "}
              </div>
              <div className={style.dashboard_rank_prg_bar}>
                <ProgressBar
                  max={100}
                  min={0}
                  className={`${style.progress_bar} ${style.progress}`}
                  now={40}
                />
                <div className={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_dashboard_rank_prg_bar_cnt}`
                      : `${style.dashboard_rank_prg_bar_cnt}`
                  }`}>
                  {parseInt(rank.percentage)}%
                </div>
              </div>
            </div>
            <div className={style.dashboard_rank_cnt_sc}>
              <div className={style.dashboard_rank_box}>
                <div className={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_dashboard_rank_box_rnd}`
                      : `${style.dashboard_rank_box_rnd}`
                  }`}>
                  {rank.required}
                </div>
                <span>{t("Common.required")}</span>
              </div>
              <div className={style.dashboard_rank_box}>
                <div className={`${
                    currentTheme == "theme-dark"
                      ? `${style.dark_mode_dashboard_rank_box_rnd}`
                      : `${style.dashboard_rank_box_rnd}`
                  }`}>
                  {rank.achieved}
                </div>
                <span>{t("Common.achieved")}</span>
              </div>
            </div>
    
    
      
    </div>
    
    
      
      
      
      
      
      </>
  ))

))} */}

          {/* {selectedTab == 1 &&props.rank?.next && ( 

<div className={style.dashboard_rank_row}>
  <div className={`${
      currentTheme == "theme-dark"
        ? `${style.dark_mode_dashboard_rank_row_head}`
        : `${style.dashboard_rank_row_head}`
    }`}
    style={{marginTop:40}}>
    {t("Common.nextRank")} - <span>{props.rank.next?.name? props.rank.next?.name:"NA"}</span>
  </div>NumberDisplay
  {props.rank.next?.criteria.map((rank, index) => {
    return (
      <div key={index}>
        <div className={style.dashboard_rank} >
          <div className={style.dashboard_rank_ico}>
            <img src={platinum} alt="" />
          </div>
          <div className={style.dashboard_rank_hd}>
            {" "}
            {t("Common." + rank.text)}{" "}
          </div>
          <div className={style.dashboard_rank_prg_bar}>
            <ProgressBar
              max={100}
              min={0}
              className={`${style.progress_bar} ${style.progress}`}
              now={40}
            />
            <div className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_dashboard_rank_prg_bar_cnt}`
                  : `${style.dashboard_rank_prg_bar_cnt}`
              }`}>
              {parseInt(rank.percentage)}%
            </div>
          </div>
        </div>
        <div className={style.dashboard_rank_cnt_sc}>
          <div className={style.dashboard_rank_box}>
            <div className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_dashboard_rank_box_rnd}`
                  : `${style.dashboard_rank_box_rnd}`
              }`}>
              {rank.required}
            </div>
            <span>{t("Common.required")}</span>
          </div>
          <div className={style.dashboard_rank_box}>
            <div className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_dashboard_rank_box_rnd}`
                  : `${style.dashboard_rank_box_rnd}`
              }`}>
              {rank.achieved}
            </div>
            <span>{t("Common.achieved")}</span>
          </div>
        </div>
      </div>
    );
  })}
</div>
)}  */}

          {/* {value == 1 &&props.rank?.next && ( 

            <div className={style.dashboard_rank_row}>
              <div className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_rank_row_head}`
                    : `${style.dashboard_rank_row_head}`
                }`}
                style={{marginTop:40}}>
                {t("Common.nextRank")} - <span>{props.rank.next?.name? props.rank.next?.name:"NA"}</span>
              </div>
              {props.rank.next?.criteria.map((rank, index) => {
                return (
                  <div key={index}>
                    <div className={style.dashboard_rank} >
                      <div className={style.dashboard_rank_ico}>
                        <img src={platinum} alt="" />
                      </div>
                      <div className={style.dashboard_rank_hd}>
                        {" "}
                        {t("Common." + rank.text)}{" "}
                      </div>
                      <div className={style.dashboard_rank_prg_bar}>
                        <ProgressBar
                          max={100}
                          min={0}
                          className={`${style.progress_bar} ${style.progress}`}
                          now={40}
                        />
                        <div className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_prg_bar_cnt}`
                              : `${style.dashboard_rank_prg_bar_cnt}`
                          }`}>
                          {parseInt(rank.percentage)}%
                        </div>
                      </div>
                    </div>
                    <div className={style.dashboard_rank_cnt_sc}>
                      <div className={style.dashboard_rank_box}>
                        <div className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_box_rnd}`
                              : `${style.dashboard_rank_box_rnd}`
                          }`}>
                          {rank.required}
                        </div>
                        <span>{t("Common.required")}</span>
                      </div>
                      <div className={style.dashboard_rank_box}>
                        <div className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_dashboard_rank_box_rnd}`
                              : `${style.dashboard_rank_box_rnd}`
                          }`}>
                          {rank.achieved}
                        </div>
                        <span>{t("Common.achieved")}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}  */}
        </div>
      </div>
    </div>
  );
});
export default Ranks;
