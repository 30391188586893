import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import NewsTicker from "react-advanced-news-ticker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function RecentOrders(props) {
  const curr = useSelector((state) => state.curr);
  const navigation = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);

  const { t } = useTranslation();

  return (
    <div className={style.dashboard_new_member_sec}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? style.dark_mode_dashboard_pannel_box
            : style.dashboard_pannel_box
        }`}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className={`${
            currentTheme == "theme-dark"
              ? style.dark_mode_dashboard_new_member_head
              : style.dashboard_new_member_head
          }`}
        >
          {t("Dashboard.recent_orders")}

          <button
            className={style.readmore_btn}
            style={{ background: "none" }}
            onClick={() => {
              navigation.push("/orderHistory");
            }}
          >
            <span>View all </span>
          </button>
        </div>

        <div>
          {props?.orders?.length === 0 ? (
            <div
              className={`${style.NodataImage}`}
              style={{ textAlign: "center" }}
            >
              <img src={nodata} alt="nodata" style={{ width: "50%" }} />
            </div>
          ) : (
            props?.orders?.map((item) => (
              <div className={style.dash_order} key={item.id}>
                <div className={style.iconorder}>
                  <i
                    className="fa fa-truck"
                    aria-hidden="true"
                    style={{ color: "#006A4E" }}
                  ></i>
                </div>
                <div className={style.orderContent}>
                  <h3>{`${item.service}` }</h3>
                  
               
                  <span>
                    {moment(item.order_date).format("MMM DD, YYYY hh:mm A")}
                  </span>
                </div>
                <div className={style.orderprice}>
                  <h3>{item.pend_bv}</h3>
                  <span style={{ color: "#006A4E" }}> PBV</span>
                </div>
              </div>
            ))
          )}
        </div>

        {/* <button style={{
    borderRadius: '0 0 20px 20px',
    color: currentTheme=="theme-dark"?"#ffffff":"#3B444B",
    
    width: "100%",
    height: "auto",
    float: "left",
    position: "absolute",
    left: "0",
    bottom: "0",
    borderRadius: "10px",
    padding: "5px",
    transition: "background 0.3s ease", 
    cursor: "pointer", 
    border:"none",
    background:currentTheme=="theme-dark"?"#112237":"#ffff",
   boxShadow: '0px 9px 21px rgba(0, 0, 0, 0.03)'
}}
   onClick={()=>{
    navigation.push('/rank')

   }}
>
   
    <i  style={{fontSize:20}}class="fa fa-angle-double-down" aria-hidden="true"></i>

</button>  */}
      </div>
    </div>
  );
}

export default RecentOrders;
