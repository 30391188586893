import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
// import fileDownload from 'js-file-download';
//components
import PageTitle from "../../components/common/pageTitle";
import { Tiles, Content, ModalForms } from "../../components/commission";
import { FormInputControl } from "../../components/common";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";

//style
import style from "../../components/commission/ewallet.module.scss";

import PaypalPayment from "../../components/Payments/PaypalPayment";
import Payments from "../../../service/common/PaymentApi";

//service
import EwalletService from "../../../service/ewallet/Ewallet";

import UserStore from "../../../store/UserStore";

//data
import ewalletdata from "./ewalletdata.json";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import companyName from "../../../store/mobxStore/companyName";

const mapStateToProps = (state) => ({ lang: state.Lang });

const Ewallet = (props) => {
  const Currency = useSelector((state) => state.curr);

  const { t } = props;
  const [checkout, setCheckout] = useState(false);
  // let amount = 10;
  const [EwalletAmount, setEwalletAmount] = useState({
    amount: "",
    FormError: {
      amount: "",
    },
    isvalid: false,
  });

  const [loader, setLoader] = useState({
    EarningsTable: {
      search: false,
      reset: false,
      excel: false,
      csv: false,
      print: false,
    },
    HistoryTable: {
      search: false,
      reset: false,
    },
  });

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setEwalletAmount((prev) => ({
      ...prev,
      [name]: value,
      FormError: {
        ...prev.FormError,
        [name]: "",
      },
      isvalid: false,
    }));
  };

  const [state, setState] = useState({
    Modal: {
      fundTransfer: false,
      addWallet: false,
    },
    tableData: {},
    filterDates: [],
    tilesData: [],
    balanceAmount: "",
    TransactionFee: "",
    repurchase_status: "yes",
    loading: true,
    DataTables: {
      key: UserStore.checkUserEarnings ? "earning" : "statement",
      statement: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
      },
      history: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        direction: "",
      },
      wallet: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
      },
      earnings: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        direction: "",
        category: [],
      },
    },
  });
  const [serachLoder ,setSerachLoader] = useState(false)
  const [clientSecret, setClientSecret] = useState("");
  const [period, setPeriods] = useState();

  const service = new EwalletService();
  const Service = new Payments();

  useEffect(() => {
    // Service.createPayment(props.product, "Paypal").then((res) => {
    //   if (res.status) {
    //     setClientSecret(res.data.public_key);
    //   }
    // });
    // history.listen((location) => {
    //   if (history.action === "PUSH") {
    //     setLocationKeys([location.key]);
    //     if (location.pathname === "/dashboard") {
    //       UserStore.checkUserEarnings = false;
    //     }
    //   }
    //   if (history.action === "POP") {
    //     if (locationKeys[1] === location.key) {
    //       setLocationKeys(([_, ...keys]) => keys);
    //     } else {
    //       setLocationKeys((keys) => [location.key, ...keys]);
    //       UserStore.checkUserEarnings = false;
    //     }
    //   }
    // });

    //componentdidmount
    loadData(); 
  }, [props, locationKeys, Currency.currentCurr]);

  const checkAvailable = () => {
    let valid = true;

    if (!EwalletAmount.amount) {
      valid = false;
      setEwalletAmount((prev) => ({
        ...prev,
        FormError: {
          ...prev.FormError,
          amount: t("validation.required", { field: "amount" }),
        },
      }));
    }
    if (valid) {
      setCheckout(true);
    }
  };

  //load the data
  const loadData = () => {
    setState((prevState) => ({
          ...prevState,
          filterPeriods: [],
         
        }));
    //ewallet tiles
    service.Ewallet().then((res) => {
      
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          filterPeriods: res.data.periods,
         
        }));
        
     getUserEarningsTable("","","","","","","",res?.data?.periods[0]?.period);
         
      } else {
        props.history.push("/logout");
      }
    });
   
    // getstatementTable();
    // getHistoryTable();
    // getWalletTable();
   

      
    
    // this.getHistoryTable();
  };

  //statement table
  const getstatementTable = (
    inPage = state.DataTables.statement.inPage,
    perPage = state.DataTables.statement.perPage
  ) => {
    let start = (inPage - 1) * perPage;
    service.statement(start, perPage).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          DataTables: {
            ...prevState.DataTables,
            statement: {
              ...prevState.DataTables.statement,
              totalRows: res.data.count,
              data: res.data.table_data,
              pending: false,
            },
          },
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  //change statement table
  const changeStatementTable = (inPage, perPage) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        statement: {
          ...prevState.DataTables.statement,
          perPage: perPage,
          inPage: inPage,
          // pending     : false
        },
      },
    }));
    getstatementTable(inPage, perPage);
  };

  //get history tabla data
  const getHistoryTable = (
    inPage = state.DataTables.history.inPage,
    perPage = state.DataTables.history.perPage,
    direction = "",
    startDate = moment().startOf("month").format("MMMM D, YYYY"),
    endDate = moment().format("MMMM D, YYYY"),
    order = "",
    keys = ""
  ) => {
    let start = (inPage - 1) * perPage;
    service
      .history(start, perPage, direction, startDate, endDate, order, keys)
      .then((res) => {
        if (res.status) {
          setLoader({
            EarningsTable: {
              search: false,
              reset: false,
              excel: false,
              csv: false,
              print: false,
            },
            HistoryTable: {
              search: false,
              reset: false,
            },
          });
          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              history: {
                ...prevState.DataTables.history,
                totalRows: res.data.count,
                data: res.data.table_data,
                pending: false,
              },
            },
          }));
        } else {
          props.history.push("/logout");
        }
      });
  };

  //change table data
  const changeHistoryTable = (
    inPage,
    perPage,
    start,
    end,
    dierection,
    colKey,
    key
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        history: {
          ...prevState.DataTables.history,
          perPage: perPage,
          inPage: inPage,
          // pending     : true,
          startDate: start,
          endDate: end,
          direction: dierection,
        },
      },
    }));
    getHistoryTable(inPage, perPage, dierection, start, end, colKey, key);
  };

  //wallet table
  const getWalletTable = (
    inPage = state.DataTables.wallet.inPage,
    perPage = state.DataTables.wallet.perPage
  ) => {
    let start = (inPage - 1) * perPage;
    service.wallet(start, perPage).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          DataTables: {
            ...prevState.DataTables,
            wallet: {
              ...prevState.DataTables.wallet,
              totalRows: res.data.count,
              data: res.data.table_data,
              pending: false,
            },
          },
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  //change pagination on wallet table
  const changeWalletTable = (inPage, perPage) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        wallet: {
          ...prevState.DataTables.wallet,
          perPage: perPage,
          inPage: inPage,
          // pending     : true
        },
      },
    }));
    getWalletTable(inPage, perPage);
  };

  //get the userearnings table
  const getUserEarningsTable = (
    
    inPage = state.DataTables.earnings.inPage,
    perPage = state.DataTables.earnings.perPage,
    direction = "",
    startDate = moment().startOf("month").format("MMMM D, YYYY"),
    endDate = moment().format("MMMM D, YYYY"),
    order = "",
    keys = "",
    periods = period
  ) => {
    console.log("period",period)
    let start = (inPage - 1) * perPage;
    service
      .commission_preview(
        start,
        perPage,
        direction,
        startDate,
        endDate,
        order,
        keys,
        periods
      )
      .then((res) => {
        if (res.status) {
          setSerachLoader(false)
          setLoader({
            EarningsTable: {
              search: false,
              reset: false,
              excel: false,
              csv: false,
              print: false,
            },
            HistoryTable: {
              search: false,
              reset: false,
            },
          });
          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              earnings: {
                ...prevState.DataTables.earnings,
                totalRows: res.data.count,
                data: res.data.table_data,

                pending: false,
                category: res.data.category,
              },
            },
            tilesData: res.data.tiles,
            loading: false,
            filterPeriods: res.data.periods,
          }));
          
          
        } else {
          // props.history.push("/logout");
        }
      });
  };

  //change table data
  const changeEarningsTable = (
    inPage,
    perPage,
    start,
    end,
    dierection,
    colKey,
    key
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        earnings: {
          ...prevState.DataTables.earnings,
          perPage: perPage,
          inPage: inPage,
          // pending     : true,
          startDate: start,
          endDate: end,
          direction: dierection,
        },
      },
    }));
    getUserEarningsTable(inPage, perPage, dierection, start, end, colKey, key);
  };

  //open function in ewallet fund transfer modal
  const purchaseWallet = () => {
    setState((prevState) => ({
      ...prevState,
      Modal: {
        ...prevState.Modal,
        addWallet: true,
      },
    }));
  };

  //add purchase wallet modal open
  const fundTransferModal = () => {
    setState((prevState) => ({
      ...prevState,
      Modal: {
        ...prevState.Modal,
        fundTransfer: true,
      },
    }));
  };

  //modal close
  const handleClose = () => {
    setCheckout(false);
    setEwalletAmount((prevState) => ({
      ...prevState,
      amount: "",
    }));
    setState((prevState) => ({
      ...prevState,
      Modal: {
        ...prevState.Modal,
        fundTransfer: false,
        addWallet: false,
      },
    }));
  };
  const Translation = (data) => {
    let newData = data.map((item) => {
      if (item) {
        return {
          ...item,
          category: t("ewallet." + item.category),
          total_amount: `${Currency.currentCurr}${(
            parseFloat(item.total_amount) * Currency.value
          ).toFixed(Currency.precision)}`,
          service_charge: `${Currency.currentCurr}${(
            parseFloat(item.service_charge) * Currency.value
          ).toFixed(Currency.precision)}`,
          tax: `${Currency.currentCurr}${(
            parseFloat(item.tax) * Currency.value
          ).toFixed(Currency.precision)}`,
          amount_payable: `${Currency.currentCurr}${(
            parseFloat(item.amount_payable) * Currency.value
          ).toFixed(Currency.precision)}`,
        };
      } else return item;
    });

    return newData;
  };

  const ExportToExcel = (apiData, fileName, type) => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let fileExtension = ".xlsx";

    if (type === "excel") {
      // const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(apiData.Data);

      ws["!merges"] = [{ s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }];

      // Set background color for header cells

      const customMergeHeaders = ["FROM ID", "PV", "%", "EARNED"];
      XLSX.utils.sheet_add_aoa(ws, [customMergeHeaders, [apiData.Data]], {
        origin: "A1",
      });

      //XLSX.utils.sheet_add_aoa(ws, [[customMergeHeaders, customHeader]]);

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: false,
          csv: false,
          print: false,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
      // };
    } else if (type === "csv") {
      fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(Translation(apiData));
      XLSX.utils.sheet_add_aoa(ws, [
        ["from_id", "personal_pv", "amount", "percentage"],
      ]);
      const csvOutput = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: false,
          csv: false,
          print: false,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
    } else if (type === "print") {
      //  let printView = <UserEarningsPrintView
      //      data={apiData}
      // />
    }
  };

  // Export Data
  const exportData = (startdate = "", enddate = "", categories = "", type) => {
    let keys = categories
      .map((key, idx) => {
        return "categories[" + idx + "]=" + key.value;
      })
      .join("&");

    if (type === "excel") {
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: true,
          csv: false,
          print: false,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
    }
    if (type === "print") {
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: false,
          csv: false,
          print: true,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
    }
    if (type === "csv") {
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: false,
          csv: true,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
    }
    service.exportData(startdate, enddate, keys, type).then((res) => {
      if (type === "excel") {
        // let data = {
        //   overridingBonus: [
        //     {
        //       from_id: "user02",
        //       personal_pv: 20,
        //       amount: 0,
        //       percentage: 0,
        //     },
        //   ],
        // };
        let TableData = {
          Heads: ["OverRiding Bonus", "Basic"],
          Data: [
            {
              from_id: "user02",
              personal_pv: 20,
              amount: 0,
              percentage: 0,
            },
            {
              from_id: "user02",
              personal_pv: 20,
              amount: 0,
              percentage: 0,
            },
          ],
        };

        ExportToExcel(TableData, "User Earnigs Report", type);
      }
      if (type === "csv") {
        ExportToExcel(res.data, "User Earnigs Report", type);
      }

      if (type === "print") {
        ExportToExcel(res.data, "User Earnigs Report", type);
      }

      // if(type==='excel'){
      //     fileDownload(res, 'User Earnigs Report.xlsx');
      // }
    });
  };

  const onPeriodChanges = (event) => {
    setPeriods(event.target.value);
    //getUserEarningsTable();
  };
  const submitSearch = () => {
    setSerachLoader(true)
    getUserEarningsTable();
  };

  //tab change function(ddn't added this)
  const tabChangeHandler = () => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        statement: {
          ...prevState.DataTables.statement,
          perPage: 10,
          inPage: 1,
        },
        history: {
          ...prevState.DataTables.history,
          perPage: 10,
          inPage: 1,
        },
        wallet: {
          ...prevState.DataTables.wallet,
          perPage: 10,
          inPage: 1,
        },
        earnings: {
          ...prevState.DataTables.earnings,
          perPage: 10,
          inPage: 1,
        },
      },
    }));

    // getstatementTable();
    // getHistoryTable();
    // getWalletTable();
    getUserEarningsTable();
  };

  const repurchase = () => {
    return state.purchase_wallet === "yes" ? true : false;
  };

  const ButtonGroup = [
    {
      name: "ewalletFundTransfer",
      click: fundTransferModal,
    },
    ...(repurchase()
      ? [
          {
            name: "AddPurchaseWalletFund",
            click: purchaseWallet,
          },
        ]
      : []),
  ];

  const paginationHandler = {
    statement: changeStatementTable,
    history: changeHistoryTable,
    wallet: changeWalletTable,
    earnings: changeEarningsTable,
  };
  const resetSearch =()=>{
    loadData();
  }
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.ewallet-preview")}
        </title>
        <script
          src={`https://www.paypal.com/sdk/js?client-id=${clientSecret}&currency=USD`}
        ></script>
      </Helmet>
      <PageTitle title="ewallet-preview" buttonOn={false} group={ButtonGroup} />
      {state.loading ? (
        <Spinner />
      ) : (
        <div className={style.MainContainer}>
          {state.tilesData && (
            <Tiles
              tilesData={state.tilesData}
              repurchase_status={state.purchase_wallet}
            />
          )}
          <Content
            tableData={state.DataTables}
            changeHandler={tabChangeHandler}
            paginationHandler={paginationHandler}
            repurchase_status={state.purchase_wallet}
            exportData={exportData}
            loader={loader}
            filterDates={state.filterDates}
            tableModalData={state.tableData}
            filterPeriods={state.filterPeriods}
            onPeriodChanges={onPeriodChanges}
            submitSearch={submitSearch}
            serachLoder={serachLoder}
            resetSearch={resetSearch}
            
          />
          <Modal
            show={state.Modal.fundTransfer}
            onHide={handleClose}
            dialogClassName={`${
              style.ModalDialog
            } animate__animated animate__fadeInRight ${
              state.Modal.fundTransfer
                ? "animate__fadeInRight"
                : "animate__fadeOutRight"
            } FundTransferModal`}
          >
            <Modal.Header closeButton bsPrefix={style.ModalHeader}>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={style.ModalBodyHeader}>
                <h3>{t("ewallet.EwalletFundTransfer")}</h3>
              </div>
              <ModalForms
                balance={state.balanceAmount}
                transFee={state.TransactionFee}
                submitSuccess={loadData}
                handleClose={handleClose}
              />
            </Modal.Body>
          </Modal>
          {/* Add purchse wallet modal */}
          <Modal
            show={state.Modal.addWallet}
            onHide={handleClose}
            dialogClassName={`${
              style.ModalDialog
            } FundTransferModal animate__animated ${
              state.Modal.addWallet
                ? "animate__fadeInRight"
                : "animate__fadeOutRight"
            }  `}
          >
            <Modal.Header closeButton bsPrefix={style.ModalHeader}>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={style.ModalBodyHeader}>
                <h3>{t("ewallet.AddPurchaseWalletFund")}</h3>
              </div>
              <FormInputControl
                label={t("Common.amount")}
                required={true}
                error={{
                  error: EwalletAmount.FormError.amount,
                }}
                // amountAdded = {value => setEwalletAmount(value)}
                checkout={checkout}
                inputProps={{
                  name: "amount",
                  type: "number",
                  placeHolder: t("Common.amount"),
                  onChange: (e) => {
                    changeHandler(e);
                  },
                }}
              />
              {!checkout && (
                <div className="p-2">
                  <pre className={`alert alert-info`}>
                    {t("Common.pwpaypal")}
                  </pre>
                  <div className={style.Buttons}>
                    <Button onClick={checkAvailable} variant="primary">
                      {t("Button.pwpypl")}
                    </Button>
                  </div>
                </div>
              )}
              {checkout && (
                <div className="payment-div">
                  <PaypalPayment
                    amount={EwalletAmount.amount}
                    checkout={(value) => setCheckout(value)}
                    //  getPaypalData={props.getPaypalData}
                  />
                </div>
              )}{" "}
              {/* {  checkin && <div>{paymentPaypal()}</div>} */}
              {/* <ModalForms /> */}
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(connect(mapStateToProps)(withRouter(Ewallet)));
