import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import style from "../../components/tree/tree.module.scss";

import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import _ from "lodash";
import { Button } from "react-bootstrap";
import TreeViewService from "../../../service/tree/treeView";
import { PageTitle } from "../../components/common";
import { TitleHeaders } from "../../components/tree";
import companyName from "../../../store/mobxStore/companyName";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";

const DownlineRank = () => {
  const { t } = useTranslation();
  const curr = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new TreeViewService();
  const [userData, setUserData] = useState();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          // mode: currentTheme=="theme-light"?"light":"dark", //let's use the same dark/light mode as the global theme
          // primary: currentTheme=="theme-light"?"light":"dark", //swap in the secondary color as the primary for the table
          info: {
            main: "#14243a", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default: currentTheme === "theme-dark" ? "#14243a99" : "#fff",
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [currentTheme]
  );

  useEffect(() => {
    const fetchData = async () => {
      const start = pagination.pageIndex * pagination.pageSize;
      const length = pagination.pageSize;
      const columnFilter = JSON.stringify(columnFilters) ?? "[]";
      const sortFilter = JSON.stringify(sorting) ?? "[]";

      service
        .downlinerank(start, length, columnFilter, sortFilter)
        .then((res) => {
          setUserData(res.data);
        });
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "id", //normal accessorKey
        header: " ID",
        size: 100,
      },
      {
        accessorKey: "first_name", //access nested data with dot notation
        header: "FIRST NAME",
        size: 150,
      },
      {
        accessorKey: "last_name",
        header: "LAST NAME",
        size: 150,
      },
      {
        accessorKey: "rank_name",
        header: "RANK",
        size: 150,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color: cell.row.original.rank_color
                ? cell.row.original.rank_color
                : "black",
              maxWidth: "9ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()?.toLocaleString?.("en-US", {})}
          </Box>
        ),
      },

      {
        accessorKey: "status",
        header: "STATUS",
        size: 150,
        enableColumnFilter: false,

        enableSorting: false,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
                cell.getValue() == "Terminated"
                  ? theme.palette.error.dark
                  : cell.getValue() == "Blocked"
                  ? theme.palette.warning.dark
                  : theme.palette.success.dark,
              borderRadius: "0.25rem",
              color: "#fff",
              maxWidth: "9ch",
              p: "0.25rem",
            })}
          >
            {cell.getValue()?.toLocaleString?.("en-US", {})}
          </Box>
        ),
      },
    ],
    []
  );
  const handleExportData = (type) => {
    if (type == "excel") {
      const worksheet = XLSX.utils.json_to_sheet(userData.result);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      worksheet["!cols"] = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
      ];

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [["ID", "FIRST NAME", "LAST NAME", "STATUS", "RANK NAME"]],
        { origin: "A1" }
      );
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "My_downline_ranks.xlsx");
    } else {
      var copiedData = _.cloneDeep(userData.result);

      const fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(copiedData);
      XLSX.utils.sheet_add_aoa(
        ws,
        [["ID", "FIRST NAME", "LAST NAME", "STATUS", "RANK NAME"]],
        { origin: "A1" }
      );

      XLSX.utils.sheet_add_aoa(ws, [[]]);
      const csvOutput = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, "My_downline_ranks" + fileExtension);
    }
  };
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.Downline Rank")}
        </title>
      </Helmet>
      {/* <div > */}

      <PageTitle title="Downline Rank" buttonOn={false} />
      <div className={style.DownlineMembers}>
        {
          userData && (
            <>
              <TitleHeaders
                tilesvalue="total_downline_customers"
                count={userData.totalCount}
              />

              <div className="material-table">
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable
                    columns={columns}
                    data={userData.result}
                    enableHiding={false}
                    enableGlobalFilter={false}
                    muiTableHeadCellColumnActionsButtonProps={{
                      sx: {
                        color:
                          currentTheme === "theme-dark" ? "#fff " : "#14243a",
                      },
                    }}
                    muiTableBodyProps={{
                      sx: {
                        background:
                          currentTheme === "theme-dark" ? "#14243a" : "#fff80",
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:
                          currentTheme === "theme-dark" ? "#fff " : "#14243a",
                        borderBottom:
                          currentTheme === "theme-dark"
                            ? ".5px solid #47556560"
                            : "#00090",
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        color:
                          currentTheme === "theme-dark" ? "#fff " : "#14243a",
                      },
                    }}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    manualPagination={true}
                    onSortingChange={setSorting}
                    manualFiltering={true}
                    manualSorting={true}
                    rowCount={userData.count}
                    state={{
                      columnFilters,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                    }}
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "1rem",
                          p: "0.5rem",
                          flexWrap: "wrap",
                        }}
                      >
                        <div className={style.exportButton}>
                          <Button
                            onClick={() => {
                              handleExportData("excel");
                            }}
                          >
                            <i className="fa fa-file-excel-o"></i>
                            Excel
                          </Button>

                          <Button
                            onClick={() => {
                              handleExportData("csv");
                            }}
                          >
                            <i className="fa fa-file-text-o"></i>
                            Csv
                          </Button>
                        </div>
                      </Box>
                    )}
                  />
                </ThemeProvider>
              </div>
            </>
          )
          //  <MaterialReactTable

          //  columns={columns} data={userData} />
        }
      </div>
    </div>
  );
};

export default DownlineRank;
