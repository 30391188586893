import React, { useState } from "react";
import {
  PersonalDetials,
  ContactDetails,
  BankDetails,
  PaymentDetails,
  Settings,
  DocumentDetails,
  CardDetails
} from "./";
import style from "./profile.module.scss";
import AlertMessage from "../common/AlertMessage";
import { useTranslation } from "react-i18next";
function EditContent(props) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    EditPerosal: true,
    EditContact: true,
    EditBankDetails: true,
    EditPaymentDetials: true,
    EditSettingsDetails: true,
    EditDocumentDetails: true,
    EditCardDetails:true,
    loader: false,
  });
  const notifyInintialState = {
    show: false,
    message: "",
    type: "",
    header: "",
  };
  const [notify, setNotify] = useState(notifyInintialState);
  const {
    EditPerosal,
    EditContact,
    EditBankDetails,
    EditPaymentDetials,
    EditSettingsDetails,
    EditDocumentDetails,
    EditCardDetails
  } = state;
  const editButtonClick = (key) => {
    cancelbtn();
    setState((prev) => ({
      ...prev,
      [key]: false,
    }));
  };
  //cancel button click
  const cancelbtn = () => {
    Object.keys(state).forEach((i) => {
      setState((prev) => ({
        ...prev,
        [i]: true,
      }));
    });
  };
  const notifyDissmissed = () => {
    setNotify((prev) => ({
      ...prev,
      show: false,
    }));
  };
  const showNotify = (type, header, message) => {
    console.log("showNotify")
    setNotify((prev) => ({
      ...prev,
      show: true,
      type: type,
      header: t("Common." + header),
      message: t(message),
    }));
  };
  // cancelbtn();
  return (
    <div className={style.EditContent}>
      <PersonalDetials
        status={"EditPerosal"}
        change={editButtonClick}
        isEditable={EditPerosal}
        cancelbtn={cancelbtn}
        data={props.editData.personal_details.fields}
        updateSuccess={props.updateSuccess}
        stateKey="personal_details"
        showNotify={showNotify}
        getData={props.getData}
      />
      <ContactDetails
        status={"EditContact"}
        change={editButtonClick}
        isEditable={EditContact}
        cancelbtn={cancelbtn}
        data={props.editData.contact_details.fields}
        updateSuccess={props.updateSuccess}
        stateKey="contact_details"
        showNotify={showNotify}
        getData={props.getData}
      />
    
        <CardDetails
        status={"EditCardDetails"}
        change={editButtonClick}
        isEditable={EditCardDetails}
        cancelbtn={cancelbtn}
        data={props.editData.card_deatils.fields}
        updateSuccess={props.updateSuccess}
        stateKey="card_details"
        showNotify={showNotify}
        getData={props.getData}
      />

      {props.editData?.documents_deatails?.fields.length>0 && (
        <DocumentDetails
          status={"EditDocumentDetails"}
          change={editButtonClick}
          isEditable={EditDocumentDetails}
          cancelbtn={cancelbtn}
          data={props.editData.documents_deatails.fields}
          updateSuccess={props.updateSuccess}
          stateKey="documents_deatails"
          showNotify={showNotify}
          getData={props.getData}
        />
      )}

      {/* { props.editData?.payout_deatils?.fields  &&  
                <PaymentDetails 
                    status={'EditPaymentDetials'}
                    change={editButtonClick}
                    isEditable={false}
                    cancelbtn={cancelbtn}
                    data={props.editData.payout_deatils.fields}
                    updateSuccess={props.updateSuccess}
                    stateKey="payout_deatils"
                    showNotify={showNotify}
                    getData={props.getData}
                />
                }  */}
      {/* {props.editData.settings_details.fields && (
        <Settings
          status={"EditSettingsDetails"}
          change={editButtonClick}
          isEditable={EditSettingsDetails}
          cancelbtn={cancelbtn}
          data={props.editData.settings_details.fields}
          updateSuccess={props.updateSuccess}
          stateKey="settings_details"
          showNotify={showNotify}
          getData={props.getData}
        />
      )} */}
      {notify.show && (
        <div>
          <AlertMessage
            message={notify.message}
            dismiss={notifyDissmissed}
            type={notify.type}
            header={notify.header}
            show={notify.show}
          />
        </div>
      )}
    </div>
  );
}

export default EditContent;
