import React from "react";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
//components
import PageTitle from "../../components/common/pageTitle";
// import MainContent from "../../components/dashboard/MainContent";
//service
import DashboardService from "../../../service/dashboard/Dashboard";
import AppinfoService from "../../../service/common/Appinfo";
import Spinner from "../../shared/Spinner";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import UserStore from "../../../store/UserStore";
import Container from "react-bootstrap/Container";
import style from "../../components/dashboard/dashboard.module.scss";

const Unsubscribe = (props) => {
  const cplan = useSelector((state) => state.ProjectConfig.mlmPlan);
  const curr = useSelector((state) => state.curr);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    memberStatus: false,
    loading: true,
    alert: {
      show: false,
      varient: "",
      message: "",
    },
    modal: {
      title: "",
      show: false,
      data: {},
      id: "",
    },
    continue: false,
    visitorid: "",
  });

  const service = new DashboardService();
  const commonService = new AppinfoService();
  const { t } = props;

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  //upload image
  const uploadImgae = (file, username, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("username", username ?? "");
    formData.append("type", type ?? "subscription_renewal");

    service.upload_reciept(formData).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          enableFinish: true,
          showAlert: true,
          alertType: "success",
          AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
          imageUploaded: true,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          enableFinish: false,
          showAlert: true,
          alertType: "danger",
          AlertMessage: t("Common.error_file_upload"),
        }));
      }
    });
  };

  useEffect(() => {
    const service = new DashboardService();
    const { state } = props.location;

    // if (localStorage.getItem("visitorid")) {
    //   setState((prevState) => ({
    //     ...prevState,
    //     modal: {
    //       title: "Enter OTP sent to your email",
    //       show: true,
    //       data: {},
    //       id: "",
    //     },
    //     // visitorid : res.visitor_id,
    //     continue: true,
    //   }));
    // }

    // var userEmail = getCookie("visitor");
    // if (state) {
    //   if (state.error) {
    //     setState((prevstate) => ({
    //       ...prevstate,
    //       alert: {
    //         show: true,
    //         varient: "danger",
    //         message: "validation." + state.message,
    //       },
    //     }));
    //   }
    // }
    // commonService.checkDemoUsers().then((res) => {
    //   if (res.data.is_preset_demo === "yes" && userEmail == null) {
    //     setState((prevState) => ({
    //       ...prevState,
    //       modal: {
    //         title: "Please fill in your details to continue",
    //         show: true,
    //         data: {},
    //         id: "",
    //       },
    //     }));
    //   } else {
    //     // props.history.push('/dashboard');
    //   }
    // });
    service.UnapprovedDashboardLoad().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          ...res.data,
        }));
      } else {
        // props.history.push("/logout");
      }
    });
    // },[])
  }, [props.history, props.location, curr]);

  const FiterGraph = (range) => {
    service.JoiningGraphFilter(range).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          joining_graph_data_new: res.data.joining_graph_data_new,
        }));
      } else {
        //props.history.push("/logout");
      }
    });
  };

  const TilesFitler = (type, range) => {
    service.TileFilter(type, range).then((res) => {
      if (res.status) {
        let newData = state.tiles.map((object) => {
          if (object.text === type) {
            return res.data;
          } else {
            return object;
          }
        });
        setState((prevState) => ({
          ...prevState,
          tiles: newData,
        }));
      } else {
        // props.history.push("/logout");
      }
    });
  };

  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };

  return (
    <div>
      <React.Suspense fallback={<Spinner />}>
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.Dashboard")}
          </title>
        </Helmet>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <PageTitle title="Dashboard" buttonOn={false} />
        </div>
        <Container fluid className={`m-10`} style={props?.style}>
          {/* <NewProfile profile={props.profile}/> */}
          <div className={style.left_navigation_fold}>
            <div className={style.main_contant_sec}>
              <section className={style.contant_sec}>
                <div className={style.cntr_main_cnt_sc}>
                  <div className={style.dashboard_main_cnt}>
                    {true && (
                      <div
                        className={style.dashboard_pannel_box}
                        style={{
                          border: "2px solid #F2C200",
                          textAlign: "center",
                        }}
                      >
                        <i
                          class="fa fa-warning"
                          style={{ margin: "0 5px", color: "#F2C200" }}
                        ></i>
                        We regret to inform you that your subscription payment
                        is currently overdue. As a result, access to certain
                        features and services has been temporarily restricted.
                        To regain full access and continue enjoying our
                        platform's benefits, we kindly ask you to settle your
                        outstanding payment at your earliest convenience.
                        <div
                          style={{
                            lineHeight: "20px",
                            fontWeight: "bold",
                            padding: "10px",
                          }}
                        >
                         following link: <a href="/pay-now">Subscribe Now</a>


                        </div>
                        Once the payment is successfully processed, your account will be reactivated, and you'll regain access to all features and services.

If you have any questions or need assistance with the payment process, please don't hesitate to contact our support team. We're here to help.
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Container>
      </React.Suspense>
      {/* )} */}
    </div>
  );
};
export default withTranslation()(withRouter(Unsubscribe));
