import React from 'react';
import style from './tree.module.scss';
import {Col, Row} from 'react-bootstrap';
import TreeViewNode from './TreeViewNode';
function TreeviewContent(props) {
    const getToolTipIndex = (element,id) =>element.user_name === id
    return (
        <div className={style.TreeViewContainer}>
            <legend>
                <span>
                [{props.userName}]
                </span>
            </legend>
            <Row>
                <Col md={12} sm={12} lg={12} >
                    <div className={`${style.treeview}`}>
                        <ul className={`${style.listGroup} ${style.listGroupCollapse}`}>
                            {
                                props.data.map((item,index)=>{
                                    const toolTip = props.tooltip_array.findIndex(element=>{
                                        return getToolTipIndex(element,item.id)
                                    })
                                    return <TreeViewNode
                                        key={index}
                                        toolTipConfig={props.tooltip_config}
                                        {...props.tooltip_array[toolTip]}
                                        {...item} 
                                    />
                                })
                            }
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TreeviewContent
