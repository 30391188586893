import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";

export default class TreeViewService {
  key = localStorage.getItem("apiKey");
  //get the referral members
  async getTreeViewData(username) {
    const response = await fetch(
      BASE_URL + "tree/tree_view?user_name=" + username,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }
  async downlinewall(start, length, cfilter = "", sortFilter = "") {
    const response = await fetch(
      BASE_URL +
        "tree/my_customer?limit=" +
        length +
        "&offset=" +
        start +
        "&cfilter=" +
        cfilter +
        "&sortFilter=" +
        sortFilter,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }
  // http://localhost:5000/api/web/tree/my_Ibos?offset=1&limit=1

  async iboslist(
    start,
    length,
    start_date,
    end_date,
    country,
    columnFilter = "",
    sortFilter = ""
  ) {
    const response = await fetch(
      BASE_URL +
        "tree/my_ibos?limit=" +
        length +
        "&offset=" +
        start +
        "&start_date=" +
        start_date +
        "&end_date=" +
        end_date +
        "&country=" +
        country +
        "&cfilter=" +
        columnFilter +
        "&sortFilter=" +
        sortFilter,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }
  // tree/my_downlineRank?offset=0&limit=10

  async downlinerank(start, length, columnFilter, sortFilter) {
    const response = await fetch(
      BASE_URL +
        "tree/my_downlineRank?limit=" +
        length +
        "&offset=" +
        start +
        "&cfilter=" +
        columnFilter +
        "&sortFilter=" +
        sortFilter,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }

  async mydownlinenextRank(start, length, columnFilter, sortFilter) {
    const response = await fetch(
      BASE_URL +
        "tree/my_downlineNextRank?limit=" +
        length +
        "&offset=" +
        start +
        "&cfilter=" +
        columnFilter +
        "&sortFilter=" +
        sortFilter,

      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }
  async myvolumes(start, length, columnFilter, sortFilter) {
    const response = await fetch(
      BASE_URL +
        "tree/myVolumes?limit=" +
        length +
        "&offset=" +
        start +
        "" +
        "&cfilter=" +
        columnFilter +
        "&sortFilter=" +
        sortFilter,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }

  async PersonalcustWall(start, length, type, start_date, end_date) {
    const response = await fetch(
      `${BASE_URL}tree/my_personalCustomers?limit=${length}&offset=${start}&type=${type.value}&start_date=${start_date}&end_date=${end_date}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );

    const result = await response.json();
    return result;
  }

  async getPvData(start, length) {
    const response = await fetch(
      BASE_URL + "tree/pv_histories?limit=" + length + "&offset=" + start + "",
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }
}
