import {React,useState} from "react";
import style from "./party.module.scss";
import { StepFive, StepThree, StepFour, StepOne, StepTwo } from ".";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import CustomButton from "../../components/common/CustomButton";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
function GuestContent(props) {
  const { t } = useTranslation();
  const history = useHistory();
  var selectedRows = [];
  const [rows, setRows] = useState([])
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      //   width : "30%"
    },
    {
      name: "firstName",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{row.name}</span>,
    },
    {
      name: "lastName",
      selector: (row) => row.last_name,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{row.last_name}</span>,
    },
    {
      name: "phone",
      selector: (row) => row.phone,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{row.phone}</span>,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{row.email}</span>,
    },
    {
      name: "address",
      selector: (row) => row.address,
      sortable: true,
      cell: (row) => <span className={`${style.badge}`}>{row.address}</span>,
    },
    {
      name: "action",
      cell: (row) => (
        <i
          className={`${style.text_primary} fa fa-edit`}
          onClick={() => history.push(`/add_guest/${row.id}`)}
        ></i>
      ),
    },
  ];
  const cancelPopup = () => {
    //props.cancelPopupHandler([],true)
  };
  const selectedRowChange = (row) => {
    selectedRows = row.selectedRows.map((items) => {
      return items.id;
    });
    setRows(selectedRows)
  
  };
 

  return (
    <div className={`${style.container}`}>
      <div className={`${style.addbutton}`}>
        <CustomButton
          onClick={() => {
            history.push("/add_guest");
          }}
          variant="primary"
        >
          {t("Button.addGuest")}
        </CustomButton>
      </div>
      <div className={`${style.datatable}`}>
        <DataTable
          columns={columns}
          data={props.data.guest}
          highlightOnHover
          //progressPending={props.ContentData.pending}
          progressComponent={<div>Loading</div>}
          persistTableHead
          noHeader
          responsive
          selectableRows
          noDataComponent={t("Common.noDataRecordsToDisplay")}
          onSelectedRowsChange={selectedRowChange}
        />
      </div>
      {rows.length > 0 && (
        <div className={style.popupButtonArea}>
          <ul>
            <li>
              <span onClick={() => props.deleteGuest(rows)}>
                <i className="fa fa-times"></i>
              </span>
            </li>
            <li>
              <span className={style.CountRound}>{rows.length}</span>
              <span className={style.spanText}>
                {t("payout.itemsSelected")}
              </span>
            </li>
            <li>
              <Button
                variant="danger"
                className={style.CancelButton}
                onClick={() => props.deleteGuest(rows)}
              >
                <i className="fa fa-trash-o"></i>
                {t("Button.delete")}
              </Button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default GuestContent;
