import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import style from "./Resources.module.scss";
import { styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadService from "../../../service/tools/download";
import Filter from "../../components/ewallet/Filter";
import { Multiselect } from "multiselect-react-dropdown";
import { Button, Form } from "react-bootstrap";
import AppInfoservice from "../../../service/common/Appinfo";
import { useTranslation } from "react-i18next";

function Content() {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [categories, setcategories] = useState();
  const [categorydeatil, setcategorydetail] = useState([]);
  const [countries, setCountries] = useState();
  const [selectedcountry, setSelectedCountry] = useState("");
  const [id, setID] = useState("");
  const [lang, setlang] = useState("");
  const [keyword, setkeyword] = useState("");
  const [languages, setLanguages] = useState();
  const { t } = useTranslation();
  const service = new DownloadService();
  useEffect(() => {
    service.getCatgry().then((res) => {
      let data = [
        {
          id: "",
          type: "All",
        },
      ];

      setcategories([...data, ...res.data.categories]);
    });

    const commonService = new AppInfoservice();

    commonService.getCountry().then((res) => {
      const newArray = res?.data?.country?.map((item) => ({
        value: item.value,
        key: item.title,
      }));

      setCountries(newArray);
    });

    service.getLanguages().then((res) => {
      const newArray = res?.data?.languages?.map((item) => ({
        value: item.id,
        key: item.name,
      }));
      setLanguages(newArray);
    });

    //     service.getLanguages().then((res)=>{
    //   console.log("popopio",res);
    // })
  }, []);
  // multi select css components
  let styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      minWidth: "15%",
      maxWidth: "max-content",
      display: "inline-block",
    },
    inputField: {
      // To change input field position or margin
      margin: "5px",
      width: "40%",
    },
    chips: {
      // To change css chips(Selected options)
      background: "rgb(228, 228, 228)",
      color: "rgb(88, 102, 110)",
      borderRadius: "10px",
      height: "20px",
    },
    optionContainer: {
      // To change css for option container
      boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
      border: "none",
    },
    searchBox: {
      // To change search box element look
      padding: 0,
      marginRight: "5px",
      paddingLeft: "5px",
    },
    option: {
      // To change css for dropdown options
      color: "black",
    },
  };

  const StyledTabs = styled((props) => (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
      // backgroundColor: '#635ee7',
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),

      color: currentTheme == "theme-dark" ? "#868686" : "#00090",
      // background: currentTheme == "theme-dark" ? "" : "#E5E5E550",
      "&.Mui-selected": {
        color: currentTheme == "theme-dark" ? "#fff" : "rgb(88, 147, 241)",
        backgroundColor: currentTheme != "theme-dark" ? "#ffffff" : "",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isTabChanging, setIsTabChanging] = useState(false);

  const handleChange = (event, newValue) => {
    setIsTabChanging(true);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 400);
    setSelectedTab(newValue);
    setID(categories[newValue]?.id);
    loadData(categories[newValue]?.id, lang, selectedcountry, keyword);
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (id = "", lang = "", selectedcountry = "", keyword = "") => {
    service
      .getCatgryDetails(id, lang, selectedcountry, keyword)
      .then((res) => {
        console.log(res.data.documen_data);
        setcategorydetail(res.data.documen_data);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const languageHandler = (e) => {
    console.log(e);
    setlang(e);
  };

  const keywordhandler = (e) => {
    setkeyword(e.target.value);
  };

  const resethandler = () => {
    loadData();
  };
  const submitHandler = () => {
    setcategorydetail([]);
    loadData(id, lang, selectedcountry, keyword);
  };
  const onChangeCountry = (value) => {
    setSelectedCountry(value);
  };
  const onChangeLangaue = (value) => {
    setlang(value);
  };
  return (
    <>
      <div className={style.MainContainer}>
        <div className={style.summary}>
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tabContent}`
                : `${style.tabContent}`
            }`}
          >
            <StyledTabs
              value={selectedTab}
              onChange={handleChange}
              // aria-label="styled tabs example"
              style={
                isTabChanging
                  ? { opacity: 0, transition: "opacity 0.3s ease" }
                  : {}
              }
            >
              {categories &&
                categories.map((item) => (
                  <StyledTab key={item.type} label={t(item?.type)} />
                ))}
            </StyledTabs>
            <div className={style.filterSection}>
              <Form.Group controlId="formBasicSelect">
                <Form.Control
                  as="select"
                  value={selectedcountry}
                  onChange={(e) => onChangeCountry(e.target.value)}
                >
                  <option selected>Select Country</option>
                  {countries &&
                    countries.map((cntryObj, index) => (
                      <option value={cntryObj.value} key={index}>
                        {cntryObj.key}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicSelect">
                <Form.Control
                  as="select"
                  value={lang}
                  onChange={(e) => onChangeLangaue(e.target.value)}
                >
                  <option selected>Select Language</option>
                  {languages &&
                    languages.map((cntryObj, index) => (
                      <option value={cntryObj.value} key={index}>
                        {cntryObj.key}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              {/* <Multiselect
                singleSelect={true}
                options={countries}
                className="col-md-3 col-sm-12"
                showCheckbox={true}
                style={styles}
                selectedValues={selectedcountry}
                onSelect={selecthandler}
                displayValue="key"
                avoidHighlightFirstOption={true}
                placeholder="countries"
          
              />

              <Multiselect
                singleSelect={true}
                options={languages}
                className="col-md-3 col-sm-12"
                showCheckbox={true}
                // selectedValues={lang}
                style={styles}
                onSelect={languageHandler}
                displayValue="key"
                avoidHighlightFirstOption={true}
                placeholder="languages"
      
              /> */}

              <div className={style.filterButton}>
                <Button
                  variant="info"
                  className={style.padding5}
                  onClick={() => submitHandler()}
                >
                  Search
                </Button>
                <Button className={style.padding5} onClick={resethandler}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <TableContainer
              component={Paper}
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_tabContent}`
                  : `${style.tabContent}`
              }`}
            >
              <Table
                sx={{ minWidth: 600, opacity: isTabChanging ? 0.5 : 1 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Thumbnail
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Document
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Language
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categorydeatil &&
                    categorydeatil?.map((category) => (
                      <TableRow
                        key={category.ctgry}

                        //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          align="center"
                          style={{ border: "none", width: "15%" }}
                        >
                          <div>
                            <img
                              className={style.imghover}
                              src={category?.doc_icone}
                              style={{ width: 75, height: 75 }}
                            ></img>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: "none",
                            width: "15%",
                            color:
                              currentTheme == "theme-dark" ? "white" : "black",
                          }}
                          // className={style.texthover}
                        >
                          {category?.file_title}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            border: "none",
                            width: "20%",
                            color:
                              currentTheme == "theme-dark" ? "white" : "black",
                          }}
                          // className={style.texthover}
                        >
                          {category?.doc_desc}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "none", width: "20%" }}
                          className={style.texthover}
                        >
                          <a
                            target="_blank"
                            href={category?.doc_file_name}
                            download
                          >
                            View
                          </a>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: "none",
                            width: "10%",
                            color:
                              currentTheme == "theme-dark" ? "white" : "black",
                          }}
                        >
                          {category?.language}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: "none",
                            width: "10%",
                            color:
                              currentTheme == "theme-dark" ? "white" : "black",
                          }}
                        >
                          {category?.uploaded_date}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
