import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class DownloadService{
    key =localStorage.getItem('apiKey')
    async getDocument(){
        const response = await fetch(BASE_URL+'tools/download_product', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }

    async getCatgry(){
        const response = await fetch(BASE_URL+'tools/all_categories', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
    async getCatgryDetails(id,language,country,keyword){
        const response = await fetch(BASE_URL+'tools/download_product?cat_id='+id+'&language='+language+'&country='+country+'&keyword='+keyword, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }

    async getLanguages(){
        const response = await fetch(BASE_URL+'tools/all_languages', {    
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        return await response.json();
    }
}