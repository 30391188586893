import React, { useState, useRef, useCallback, useMemo } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "bootstrap-daterangepicker/daterangepicker.css";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import style from "./teamcab.module.scss";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";

import ReactExport from "react-data-export";
import { AgGridReact } from "ag-grid-react";
// import "ag-grid-enterprise";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-quartz.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function PeriodFilter({
  filterHandler,
  type,
  resetHandler,
  isSingle,
  ContentData,
  loader,
  button_group,
  filterDates,
  filterPeriods,
  onPeriodChanges,
  submitSearch,

  ...props
}) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const componentRef = useRef();
  const [state, setState] = useState({
    selectedValues: [],
    start: moment().startOf("month"),
    end: moment(),
    label:
      moment().startOf("month").format("MMMM D, YYYY") +
      " - " +
      moment().format("MMMM D, YYYY"),
  });
  const { start, end, label, selectedValues } = state;
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();

  //customselect options
  const options = {
    Today: [moment().toDate(), moment().toDate()],
    "This Week": [moment().startOf("week"), moment().endOf("week").toDate()],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "This Year": [
      moment().startOf("year").toDate(),
      moment().endOf("year").toDate(),
    ],
  };

  //datepicker select
  const handleCallback = (start, end) => {
    setState((prev) => ({
      ...prev,
      start: start,
      end: end,
      label: start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"),
    }));
  };

  // multi select css components
  let styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      minWidth: "10%",
      maxWidth: "max-content",
      display: "inline-block",
    },
    inputField: {
      // To change input field position or margin
      margin: "5px",
      width: "40px",
    },
    chips: {
      // To change css chips(Selected options)
      background: "rgb(228, 228, 228)",
      color: "rgb(88, 102, 110)",
      borderRadius: "10px",
      height: "20px",
    },
    optionContainer: {
      // To change css for option container
      boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
      border: "none",
    },
    searchBox: {
      // To change search box element look
      padding: 0,
      marginRight: "5px",
      paddingLeft: "5px",
    },
    option: {
      // To change css for dropdown options
      color: "black",
    },
  };
  //on select the multi select
  const selecthandler = (e) => {
    setState((prev) => ({
      ...prev,
      selectedValues: e,
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
  };

  //remove selected items

  const removeHandler = (e) => {
    setState((prev) => ({
      ...prev,
      selectedValues: e,
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
  };

  //reset button click
  const resetValue = () => {
    setState((prev) => ({
      ...prev,
      selectedValues: [],
      start: moment().startOf("month"),
      end: moment(),
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
    resetHandler(
      start.format("YYYY-MM-DD"),
      end.format("YYYY-MM-DD"),
      selectedValues
    );
    // filterHandler(start.format('YYYY-MM-DD'),end.format('YYYY-MM-DD'),selectedValues);
  };

  const submitHandler = () => {
    submitSearch();
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Group A",
      children: [
        { field: "athlete", minWidth: 200 },
        { field: "country", minWidth: 200 },
      ],
    },
    {
      headerName: "Group B",
      children: [
        { field: "sport", minWidth: 150 },
        { field: "gold" },
        { field: "silver" },
        { field: "bronze" },
        { field: "total" },
      ],
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      minWidth: 100,
      flex: 1,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(data);
      });
  }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  return (
    <div className={style.filterSection}>
      <select
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_DateRangePicker}`
            : `${style.DateRangePicker}`
        }`}
        onChange={onPeriodChanges}
      >
        {filterPeriods &&
          filterPeriods.map((objPeriods, index) => {
            return (
              <>
                <option
                  value={objPeriods.period}
                  key={index}
                >{`${objPeriods.start_desc} -  ${objPeriods.end_desc}`}</option>
              </>
            );
          })}
      </select>
      <div className={style.filterButton}>
        <Button
          variant="info"
          className={style.padding5}
          onClick={submitHandler}
          //disabled={loader?.EarningsTable?.search}
        >
          {/* {loader?.EarningsTable?.search ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )} */}

          {t("Button.search")}
        </Button>
        <Button
          className={style.padding5}
          onClick={resetValue}
          disabled={loader?.EarningsTable.reset}
        >
          {loader && loader.EarningsTable.reset ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )}
          {t("Button.reset")}
        </Button>
      </div>
    </div>
  );
}

export default PeriodFilter;
