import React, { useEffect } from "react";
// import {AlertList} from 'react-bs-notifier';
import { createUseStyles } from "react-jss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AlertMessage({ message, type, dismiss, show }) {
  const styles = {
    danger: {
      backgroundImage: `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=)`,
      backgroundColor: "#a94442",
      color: "#f2dede",
      borderColor: "#ebccd1",
      padding: "10px 10px 10px 50px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10px",
      marginTop: "1rem",
    },
    success: {
      backgroundImage: `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==)`,
      backgroundColor: "#3c763d",
      color: "#dff0d8",
      borderColor: "#d6e9c6",
      padding: "10px 10px 10px 50px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10px",
      marginTop: "1rem",
    },
    close: {
      float: "right",
      fontSize: "1.5rem",
      fontWeight: "700",
      lineHeight: 1,
      color: "#fff",
      textShadow: "0 1px 0 #fff",
      opacity: ".5",
      appearance: "none",
      padding: 0,
      backgroundColor: "transparent",
      border: 0,
    },
  };

  useEffect(() => {
    setTimeout(function () {
      dismiss();
    }, 4000);
    if (show) {
      toast.configure();
      if (type == "danger") {
        toast.error(message, {
          autoClose: 4000,
          pauseOnFocusLoss: false,
        });
      } else if (type == "success") {
        toast.success(message, {
          autoClose: 4000,
          pauseOnFocusLoss: false,
        });
      } else {
        toast.error(message, {
          autoClose: 4000,
          pauseOnFocusLoss: false,
        });
      }
    }
  }, []);

  const useStyleOverrides = createUseStyles({
    danger: {
      backgroundImage: `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=)`,
      backgroundColor: "#a94442",
      color: "#f2dede",
      borderColor: "#ebccd1",
      padding: "10px 10px 10px 50px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10px",
      marginTop: "1rem",
    },
    success: {
      backgroundImage: `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==)`,
      backgroundColor: "#3c763d",
      color: "#dff0d8",
      borderColor: "#d6e9c6",
      padding: "10px 10px 10px 50px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "10px",
      marginTop: "1rem",
    },
    close: {
      float: "right",
      fontSize: "1.5rem",
      fontWeight: "700",
      lineHeight: 1,
      color: "#fff",
      textShadow: "0 1px 0 #fff",
      opacity: ".5",
      appearance: "none",
      padding: 0,
      backgroundColor: "transparent",
      border: 0,
    },
  });
  const classes = useStyleOverrides();
  // const [newMessage] = React.useState(
  //     message
  //     );
  //     const [alerts, setAlerts] = React.useState([]);
  //     const [alertTimeout] = React.useState(3000);
  //     useEffect(()=>{
  //         setAlerts([{
  //             id: new Date().getTime(),
  //             type: type,
  //             headline: header,
  //             message: newMessage
  //         }])
  //     },[setAlerts,newMessage,type,header])
  //     const onDismissed = React.useCallback(alert => {
  //         setAlerts(alerts => {
  //             const idx = alerts.indexOf(alert);
  //             if (idx < 0) return alerts;
  //             return [...alerts.slice(0, idx), ...alerts.slice(idx + 1)];
  //         });
  //         dismissed();
  //     }, [dismissed]);
  return (
    // <AlertList
    // 	position="top-right"
    // 	alerts={alerts}
    // 	timeout={alertTimeout}
    // 	dismissTitle="Error!"
    //     onDismiss={onDismissed}
    //     showIcon={false}
    //     classes={classes}
    // />
    <></>
    // <ToastContainer
    //   limit={1}
    //   pauseOnHover={false}
    //   pauseOnFocusLoss={false}
    //   toastClassName={classes[type]}
    //   // autoClose={false}
    //   // bodyClassName={classes.danger}
    // />
  );
}

export default AlertMessage;
