import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import style from "../resources/Resources.module.scss";
import { styled } from "@mui/material/styles";
import { Tab, Tabs } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DownloadService from "../../../service/tools/download";

import { Button } from "react-bootstrap";
import { PageTitle } from "../../components/common";

function Content() {
  const { currentTheme } = useSelector((state) => state.Theme);
  const [categorydeatil, setcategorydetail] = useState();
  const [selectedcountry, setSelectedCountry] = useState();
  const [id, setID] = useState(1);
  const [lang, setlang] = useState();
  const [keyword,setkeyword]=useState()

  const service = new DownloadService();
  useEffect(() => {
    // service.getCatgry().then((res) => {
    //   setcategories(res.data.categories);
    // });
    // service.getCatgryDetails(id).then((res) => {
    //   setcategorydetail(res.data.documen_data);
    // });
    //intial loading the country
    //get the country in inital loading
   
  }, []);
  // multi select css components
  let styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      minWidth: "15%",
      maxWidth: "max-content",
      display: "inline-block",
    },
    inputField: {
      // To change input field position or margin
      margin: "5px",
      width: "40%",
    },
    chips: {
      // To change css chips(Selected options)
      background: "rgb(228, 228, 228)",
      color: "rgb(88, 102, 110)",
      borderRadius: "10px",
      height: "20px",
    },
    optionContainer: {
      // To change css for option container
      boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
      border: "none",
    },
    searchBox: {
      // To change search box element look
      padding: 0,
      marginRight: "5px",
      paddingLeft: "5px",
    },
    option: {
      // To change css for dropdown options
      color: "black",
    },
  };

  const StyledTabs = styled((props) => (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 60,
      width: "100%",
      // backgroundColor: '#635ee7',
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),

      color: currentTheme == "theme-dark" ? "#868686" : "#00090",
      // background: currentTheme == "theme-dark" ? "" : "#E5E5E550",
      "&.Mui-selected": {
        color: currentTheme == "theme-dark" ? "#fff" : "rgb(88, 147, 241)",
        backgroundColor: currentTheme != "theme-dark" ? "#ffffff" : "",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  const options = [
    { key: "Debit", value: "user_debit" },

    { key: "Credit", value: "user_credit" },
  ];
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isTabChanging, setIsTabChanging] = useState(false);

  const handleChange = (event, newValue) => {
    setIsTabChanging(true);
    setTimeout(() => {
      setIsTabChanging(false);
    }, 400);
    setSelectedTab(newValue);
    setID(newValue + 1);
  };

  useEffect(() => {
    service
      .getCatgryDetails(id, lang, selectedcountry,keyword)
      .then((res) => {
        setcategorydetail(res.data.documen_data);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [id, selectedcountry,keyword,lang]);




  
  return (
    <>
{/* <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.iboslist")}
        </title>
      </Helmet> */}

      <div className={style.MainContainer}>
        <div className={style.summary}>
          {/* <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_tabContent}`
                : `${style.tabContent}`
            }`}
          > */}
      <PageTitle title="Subscription list" />
            
           
          {/* </div> */}
          <div style={{ marginTop: 30 }}>
            <TableContainer
              component={Paper}
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_tabContent}`
                  : `${style.tabContent}`
              }`}
            >
              <Table
                sx={{ minWidth: 600, opacity: isTabChanging ? 0.5 : 1 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Schedule
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Products
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Sub Total
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      Estimated Total
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: currentTheme == "theme-dark" ? "white" : "black",
                      }}
                    >
                      {/* Action     */}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categorydeatil?.length == 0 ?? <h2>hiiiii</h2>}
                  {categorydeatil &&
                    categorydeatil?.map((category) => (
                      <TableRow
                        key={category.ctgry}

                        //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell
                          align="center"
                          style={{ border: "none", width: "5%" }}
                        >
                        <span>
                        1
                            </span>  
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            border: "none",
                            width: "15%",
                            color:
                              currentTheme == "theme-dark" ? "white" : "black",
                          }}
                          // className={style.texthover}
                        >
            <span>
            Runs 22nd of each month
Next Run Date: 22 August 2023 (6 days)
                </span>            
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{
                            border: "none",
                            width: "20%",
                            color:
                              currentTheme == "theme-dark" ? "white" : "black",
                          }}
                          // className={style.texthover}
                        >
                     Monthly Business Support Fee
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "none", width: "20%" ,
                          color:
                          currentTheme == "theme-dark" ? "white" : "black",
                        }}
                        >
                          £17.00
                        </TableCell>
                        <TableCell
                          align="center"
                          className={style.texthover}

                          style={{
                            border: "none",
                            width: "10%",
                            color:
                              currentTheme == "theme-dark" ? "white" : "black",
                          }}
                        >
                          £17.00
                        </TableCell>
                        <TableCell
                          align="center"
                          
                          style={{
                            border: "none",
                            width: "20%",
                            // gap:10,
                            margin:10,  
                         
                            color:
                              currentTheme == "theme-dark" ? "white" : "black",
                          }}
                        >
                        <Button 
                          className={style.buttonhover}
                        
                        >Edit payment</Button>
                        <Button style={{margin:20}}
                          className={style.buttonhover}
                        
                        >Cancel payment</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default Content;
