let time = {
    time :localStorage.getItem("logouttime")?localStorage.getItem("logouttime"):"",
    status : false
  };
const LogoutTimeReducer = (state = time, action)=>{
     switch (action.type) {
         case 'ADD_LogoutTime':
             return {time : action.time ,status : action.status};
             default:
                return state;
     }
 }

 export default LogoutTimeReducer;