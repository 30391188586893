import React, { useEffect, useState } from "react";
import style from "./tree.module.scss";
import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";

import ReferralService from "../../../service/tree/referralCount";

function DetailPanelContent(props) {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });
  useEffect(() => {
    const service = new ReferralService();

    const fetchData = async () => {
      service.getPvBsedOnId(props.cutomerId).then((res) => {
        setState((prev) => ({
          ...prev,
          data: res.data.result,
          loading: false,
        }));
      });
    };
    fetchData();
  }, []);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ORDER ID</th>
          <th>SERVICE</th>
          <th>WEEK OF SERVICE</th>
          <th>PENDING BV COUNT</th>
          <th>ADDED BV</th>
          <th>PENDING BV</th>
           <th>WEEK BV</th>
        </tr>
      </thead>
      <tbody>
        {state.data.map((obj) => {
          return (
            <tr>
              <td>{"#" + obj.order_id}</td>
              <td>{obj.product_id}</td>
              <td>{obj.week_of_service}</td>
              <td>{obj.pending_pv_count}</td>
              <td>{obj.bv_added}</td>
              <td>{obj.pending_pv}</td>
              <td>{obj.active_pv}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default DetailPanelContent;
