import React from 'react'
import style from '../../../view/Rankadvancement/Rankadv.module.scss'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux';
import CircularProgressBar from '../../Circularprogrssbar/Circularprogress';
function RankModalView() {
  const { currentTheme } = useSelector((state) => state.Theme);

  return (
    <>
    
    

    <>
   
   
       {/* <div
        
         className={`${
           currentTheme == "theme-dark"
             ? `${style.subcontainercarddark}`
             : `${style.subcontainercard}`
         }`}
       > */}
         <h3 style={{fontSize:25,padding:10,}}>ExecutiveInfluencer</h3>
         <div className={style.circular}>
           <CircularProgressBar percentage="100" />
         </div>
       {/* </div> */}
    
    
    
     
    </>


  </>
  )
}

export default RankModalView