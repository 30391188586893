import React, { useEffect, useState } from "react";
import EwalletService from "../../../service/ewallet/Ewallet";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import style from "./history.module.scss";

//style
import { Row, Col } from "react-bootstrap";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.service = new EwalletService();

    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()} , ${current.getHours()}:${current.getMinutes()}`;
    // const time = current.getHours() + ':' + current.getMinutes();

    this.state = {
      earnings: {},
      loading: true,
      date: date,
    };
  }
  Bv_status = {
    0: "Pending",
    1: "Added",
    2: "Accepted",
  };

  render() {
    return (
      <div className={`${style.printArea}`}>
        <Table responsive borderless width="700" height="100">
          <tbody>
            <tr>
              <td colSpan="2">
                <div>{this.state.date}</div>
                <br />
                <h3>
                  <b>
                    {this.props.t("Common.orderId")}:
                    <font color="#7266ba">#{this.props.data.id}</font>
                  </b>
                </h3>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <b>Ordered &nbsp;</b>
                {moment(this.props.data.order_date).format("MMMM  DD , YYYY")}
              </td>
            </tr>
            {/* <tr>
              <td colSpan="2">
                <b>{this.props.t("Common.shippingMethod")} &nbsp;</b>
                {this.props.data.shipping_method}
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <b>{this.props.t("profile.paymentMethod")} &nbsp;</b>
                {this.props.data.payment_method}
              </td>
            </tr> */}
            <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  lineHeight: "18px",
                }}
              >
                <h4>Shipping Address</h4>
                <br />
                {this.props.data.address} <br />
                {this.props.data.city} <br />
                Mobile:{this.props.data.mobile}
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <h2>
                  <b>{this.props.t("Common.orderProducts")} </b>
                </h2>
                <hr />
                <Table bordered striped hover className={style.bordered}>
                  <thead>
                    <tr>
                      <th>{"Date"}</th>
                      <th>{"Product"}</th>
                      <th>{"Quantity"}</th>
                      <th>{"BV"}</th>
                      <th>{"Status"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.data.details &&
                      this.props.data.details.map((product, index) => (
                        <tr key={index}>
                          <td>
                            {moment(product.order_date).format(
                              "MMMM  DD , YYYY"
                            )}
                          </td>
                          <td>{product.pack1[0]?.name}</td>
                          <td>{1}</td>
                          <td>{product.bv}</td>
                          <td>{this.Bv_status[product.bv_status]}</td>
                        </tr>
                      ))}
                    <tr>
                      <td colSpan="6">
                        <hr />
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <br />
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <table
                  border="0"
                  width="30%"
                  height="100"
                  align="right"
                  className={style.tableBottom}
                >
                  <tbody>
                    {this.props.currency.currentCurr}
                    {(
                      parseInt(this.props.data.product_amount) *
                      this.props.currency.value
                    ).toFixed(this.props.currency.precision)}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

const OrderHistoryPrintView = React.forwardRef((props, ref) => {
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();
  const [companyDetails, setCompanyDetails] = useState({});
  return (
    <ComponentToPrint ref={ref} {...props} company={companyDetails} t={t} />
  );
});
export default OrderHistoryPrintView;
