import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import style from "../style/App.module.scss";
import { Language } from "../components/common/";
import Brand from "../components/auth/Brand";
import { useSelector } from "react-redux";
import profileImg from "../../assets/images/auth/profile-img.png";

// import { motion } from 'framer-motion/dist/framer-motion'

function AuthLayout({ children }) {
  const { currentTheme } = useSelector((state) => state.Theme);
  useEffect(() => {
    if (currentTheme !== "theme-dark") {
      document.body.style.setProperty("background", "#f8f8fb", "important");
    }
    // document.body.style.setProperty("p", "12px", "important");
    return () => {
      document.body.style.removeProperty("background");
    };
  }, []);
  return (
    <div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
      className={`${
        currentTheme == "theme-dark"
          ? `${style.dark_mode_app} pt-5`
          : `${style.app} pt-5`
      }`}
    >
      <Container
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_loginContainer}`
            : `${style.loginContainer}`
        }`}
      >
        <div className="row justify-content-center">
          <Brand />
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_style_bgPrimary}`
                : `${style.bgPrimary}`
            }`}
          >
            <h2>Sign in to Continue</h2>
          </div>
        </div>
        <div className={`${style.bgLogin}`}>{children}</div>
      </Container>
    </div>
  );
}

export default AuthLayout;
