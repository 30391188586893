import React, { useEffect, useState } from "react";
import style from "./replica.module.scss";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import styles from "../auth/auth.module.scss";
import ReplicaSiteService from "../../../service/replica/replica";
import { AlertMessage } from "../common";

function Contact(props) {
  const { t } = useTranslation();
  const service = new ReplicaSiteService();
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
    valid: true,
    user_name: props.user_name,
    FormError: {
      name: "",
      email: "",
      phone: "",
      address: "",
      message: "",
    },
    alert: {
      show: false,
      message: "",
      varient: "",
    },
  });
  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target; //get the field name
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validEmail = (value, name) => {
    const FormError = state.FormError;
    var regexPattern = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
    if (name == "email" && !regexPattern.test(value)) {
      FormError.email = "invalid";
    }
  };
  const validPhone = (value, name) => {
    const FormError = state.FormError;
    var regexPattern = new RegExp(/^[0-9-+]+$/);
    if (name == "phone" && !regexPattern.test(value)) {
      FormError.phone = "invalid";
      setState((prevState) => ({
        ...prevState,
        valid: false,
      }));
    }
  };

  const requiredValid = (value, name) => {
    const FormError = state.FormError;
    switch (name) {
      case "name":
        FormError.name = value.length === 0 ? "required" : "";
        break;
      case "email":
        FormError.email = value.length === 0 ? "required" : "";
        break;
      case "phone":
        FormError.phone = value.length === 0 ? "required" : "";
        break;
      case "address":
        FormError.address = value.length === 0 ? "required" : "";
        break;
      case "message":
        FormError.message = value.length === 0 ? "required" : "";
        break;
      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      FormError,
    }));
  };
  const formValid = (formError) => {
    let valid = true;
    Object.values(formError).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const handleClick = (e) => {
    e.preventDefault();

    requiredValid(state.name, "name");
    requiredValid(state.email, "email");
    requiredValid(state.phone, "phone");
    requiredValid(state.address, "address");
    requiredValid(state.message, "message");
    validPhone(state.phone, "phone");
    validEmail(state.email, "email");
    if (formValid(state.FormError)) {
      let formData = {
        name: state.name,
        email: state.email,
        phone: state.phone,
        address: state.address,
        message: state.message,
        submit: true,
        replica_user: state.user_name,
      };
      service.postContact(formData).then((res) => {
        if (res.status) {
          document.getElementById("create-course-form").reset();
          setState((prev) => ({
            ...prev,
            name: "",
            email: "",
            phone: "",
            address: "",
            show: true,
            type: "success",
            header: t("Replica."),
            message: t("Replica." + res.data.message),
          }));
        } else {
          setState((prev) => ({
            ...prev,
            show: true,
            type: "danger",
            header: t("Replica."),
            message: t("Replica." + res.error.description),
          }));
        }
      });
    } else {
    }
  };

  const notifyDissmissed = () => {
    setState((prev) => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <section id="contact" className="contact_section">
      {state.show && (
        <AlertMessage
          message={state.message}
          dismiss={notifyDissmissed}
          type={state.type}
          header={state.header}
          show={state.show}
        />
      )}
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact_section_head">
              <h2>{t("Replica.callus")}</h2>
              <span>{t("Replica.touch")}</span>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="contact_bx_phone">
                  <div className="contact_bx_phone_ico">
                    <i class="fa fa-solid fa-phone"></i>
                  </div>
                  <span>{t("Replica.callus")}</span>
                  <strong>
                    {props.data.header_data &&
                      props.data.header_data.content?.contact_phone}
                  </strong>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact_bx_phone">
                  <div className="contact_bx_phone_ico">
                    <i class="fa fa-solid fa-envelope"></i>
                  </div>
                  <span>{t("Replica.mailnow")}</span>
                  <strong>
                    {" "}
                    {props.data.header_data &&
                      props.data.header_data.content?.contact_mail}
                  </strong>
                </div>
              </div>
              <div className="col-md-12">
                <div className="contact_bx_phone">
                  <div className="contact_bx_phone_ico">
                    <i class="fa fa-solid fa-map"></i>
                  </div>
                  <span>{t("Replica.address")}</span>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html:
                        props.data.header_data &&
                        props.data.header_data.content?.contact_address,
                    }}
                  ></strong>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form_sec_box">
              <h4> {t("Replica.fillout")}</h4>
              <div className="form_sec_box_group">
                <form id="create-course-form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="group">
                        <label for="">{t("Replica.name")}<span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          onChange={handleChange}
                          placeholder={t("Replica.name")}
                        />
                        <span
                          className={`${
                            state.FormError.name ? styles.isInvalid : "d-none"
                          }`}
                        >
                          {t("Replica.Error." + state.FormError.name, {
                            field: "name",
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group">
                        <label for="">{t("Replica.email")}<span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          onChange={handleChange}
                          placeholder={t("Replica.email")}
                        />
                        <span
                          className={`${
                            state.FormError.email ? styles.isInvalid : "d-none"
                          }`}
                        >
                          {t("Replica.Error." + state.FormError.email, {
                            field: "email",
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group">
                        <label for="">{t("Replica.phone")}<span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="number"
                          name="phone"
                          onChange={handleChange}
                          placeholder={t("Replica.phone")}
                        />
                        <span
                          className={`${
                            state.FormError.phone ? styles.isInvalid : "d-none"
                          }`}
                        >
                          {t("Replica.Error." + state.FormError.phone, {
                            field: "phone",
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="group">
                        <label for="">{t("Replica.address")}<span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          type="text"
                          name="address"
                          onChange={handleChange}
                          placeholder={t("Replica.address")}
                        />
                        <span
                          className={`${
                            state.FormError.address
                              ? styles.isInvalid
                              : "d-none"
                          }`}
                        >
                          {t("Replica.Error." + state.FormError.address, {
                            field: "address",
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="group">
                        <label for="">{t("Replica.message")}<span className="text-danger">*</span></label>
                        <textarea
                          className="form-control"
                          name="message"
                          onChange={handleChange}
                          id=""
                          cols="30"
                          rows="5"
                        ></textarea>
                        <span
                          className={`${
                            state.FormError.message
                              ? styles.isInvalid
                              : "d-none"
                          }`}
                        >
                          {t("Replica.Error." + state.FormError.message, {
                            field: "message",
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button className="banner_button" onClick={handleClick}>
                        {t("Replica.sent")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
