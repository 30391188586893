import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./ewallet.module.scss";
import { Filter } from ".";
import moment from "moment";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";

import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import Table from "react-bootstrap/Table";

function CommissionTiles({
  ContentData,
  paginationchange,
  exportData,
  loader,
  filterDates,
  tableModalData,
}) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);

  useEffect(() => {}, []);

  return (
    <>
      <Card style={{ padding: "10px", border: "1px solid lightgrey" }}>
        <Card.Body>
          <Row>
            <Col md={4} sm={12}>
              <Card className={style.balance_tile}>
                <div className={style.metric_title}>£1,009.39</div>
                <div className={style.rank_title}>Rank : STL</div>
              </Card>
            </Col>

            <Col md={8} sm={12} className="d-flex justify-content-center">
              <div className={style.summary_deatils}></div>
              {/* <div className={style.summary_deatils}>22131232341242432235</div> */}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default CommissionTiles;
