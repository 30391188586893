import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import style from "../../components/tree/tree.module.scss";

import { Helmet } from 'react-helmet';
import { Box } from "@mui/material";
import * as XLSX from "xlsx";
import FileSaver, { saveAs } from "file-saver";
import _ from "lodash";
import { Button } from 'react-bootstrap';
import TreeViewService from '../../../service/tree/treeView';
import { PageTitle } from '../../components/common';
import { TitleHeaders } from '../../components/tree';
import companyName from '../../../store/mobxStore/companyName';

const Downlinesorder = () => {
const { t } = useTranslation();
const curr = useSelector((state) => state.curr);
const { currentTheme } = useSelector((state) => state.Theme);
const service=new TreeViewService()
const [userData,setUserData]=useState()


const [isError, setIsError] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [isRefetching, setIsRefetching] = useState(false);

//table state
const [columnFilters, setColumnFilters] = useState([]);
const [globalFilter, setGlobalFilter] = useState('');
const [sorting, setSorting] = useState([]);
const [pagination, setPagination] = useState({
  pageIndex: 0,
  pageSize: 10,
});




useEffect(()=>{
  const fetchData = async () => {
    const start = pagination.pageIndex * pagination.pageSize;
    const length=pagination.pageSize

    service.downlinerank(start,length).then((res) => {
        setUserData(res.data)
    })
  }
  fetchData();

},[     columnFilters,
  globalFilter,
  pagination.pageIndex,
  pagination.pageSize,
  sorting,])



//should be memoized or stable
const columns = useMemo(
  () => [
    {
      accessorKey: 'id', //normal accessorKey
      header: ' ID',
      size: 100,
    },
    {
      accessorKey: 'first_name', //access nested data with dot notation
      header: 'First Name',
      size: 150,
    },
    {
      accessorKey: 'last_name',
      header: 'Last Name',
      size: 150,
    },
    
    {
      accessorKey: 'status',
      header: 'Status',
      size: 150,
    },
    {
      accessorKey: 'order',
      header: 'Order',
      size: 150,
    },
    
    {
      accessorKey: 'Total',
      header: 'total',
      size: 150,
    },
    {
      accessorKey: 'BV',
      header: 'bv',
      size: 150,
    },
    {
      accessorKey: 'Date',
      header: 'date',
      size: 150,
    },
    
  
 
    
 
   
 
  ],
  [],
);
const handleExportData = (type) => {
  if(type=="excel"){
    const worksheet = XLSX.utils.json_to_sheet(userData.result);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(dataBlob, "My_downline_customers.xlsx");
  }
  else{
    var copiedData = _.cloneDeep(userData.result);

    const fileExtension = ".csv";
    let fileType = "text/csv;charset=utf-8";
    const ws = XLSX.utils.json_to_sheet(copiedData);
    XLSX.utils.sheet_add_aoa(ws, [[]]);
    const csvOutput = XLSX.utils.sheet_to_csv(ws);
    const data = new Blob([csvOutput], { type: fileType });
    FileSaver.saveAs(data, "My_downline_customers" + fileExtension);
  }
 
};
  return (
<div className={`h-100`}  >
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.downlinesorder")}
        </title>
      </Helmet>

      <PageTitle title="downlinesorder" 
                buttonOn={false}
        
        />
   <div   className={style.DownlineMembers}>

        
       
      {userData &&
      <>
      <TitleHeaders
            tilesvalue="total_downline_customers"
            count={userData.count}
        
          />
 
             <div  className="material-table">
        <MaterialReactTable
      columns={columns}
      data={userData.result}
      enableHiding={false}
      
    
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={userData.count}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            p: "0.5rem",
            flexWrap: "wrap",
          }}
        >
         <div className={style.exportButton}>
            <Button onClick={()=>{handleExportData("excel")}}>
              <i className="fa fa-file-excel-o"></i>
              Excel
            </Button>

            <Button onClick={()=>{handleExportData("csv")}}>
              <i className="fa fa-file-text-o"></i>
              Csv
            </Button>
          </div>




        </Box>
      )}
    />
</div>
</>
        //  <MaterialReactTable
 
        //  columns={columns} data={userData} />
    
       }
      
    </div>
    </div>
  )
}

export default Downlinesorder;
