import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import style from "./dashboard.module.scss";
import Profiles from "./profiles";
import Tile from "./Tile";
import EarningsTab from "./EarningsTab";
import TeamPerformance from "./TeamPerformance";
// import TeamPerformanceNew from './TeamPerformanceNew';
import Members from "./Members";
import Charts from "./charts";
import Ranks from "./Ranks";

import DashboardModel from "./DashboardModal";
import { useTranslation } from "react-i18next";

import AddForm from "./AddForm";
import VerificationForm from "./VerificationForm";

import { Dashboard } from "@mui/icons-material";
import Banner from "./Banner";
import News from "./News";
import RecentOrders from "./RecentOrders";
import DownlineWall from "./DownlineWall";
import ProfileService from "../../../service/profile";

import { AlertMessage } from "../../components/common";
function MainContent(props) {
  const { t } = useTranslation();
  const service = new ProfileService();
  const [notify, setNotify] = useState({
    show: false,
    message: "",
    header: "",
    type: "",
  });

  const notifyInintialState = {
    show: false,
    message: "",
    type: "",
    header: "",
  };

  const notifyDissmissed = () => {
    setNotify((prev) => ({
      ...prev,
      notify: notifyInintialState,
    }));
  };
  const generateIpayout = () => {
    service.ipayoutAccount().then((res) => {
      if (res.status) {
        setNotify((prev) => ({
          ...prev,
          show: true,
          type: "success",
          message: "Account activated sucessfully",
        }));
        window.location.reload();
      } else {
        setNotify((prev) => ({
          ...prev,
          show: true,
          type: "danger",
          message: res.message,
        }));
      }
    });
  };

  return (
    <Container fluid className={`p-3`} style={props?.style}>
      {notify.show && (
        <AlertMessage
          show={notify.show}
          message={notify.message}
          dismiss={notifyDissmissed}
          type={notify.type}
        />
      )}
      {/* <NewProfile profile={props.profile}/> */}
      <div className={style.left_navigation_fold}>
        <div className={style.main_contant_sec}>
          <section className={style.contant_sec}>
            <div className={style.cntr_main_cnt_sc}>
              <div className={style.dashboard_main_cnt}>
                {props?.profile?.showSubscription ? (
                  <div
                    className={style.dashboard_pannel_box}
                    style={{
                      border: "2px solid #F2C200",
                      textAlign: "center",
                    }}
                  >
                    <i
                      class="fa fa-warning"
                      style={{ margin: "0 5px", color: "#F2C200" }}
                    ></i>
                    We regret to inform you that your subscription payment is
                    currently overdue. As a result, access to certain features
                    and services has been temporarily restricted. To regain full
                    access and continue enjoying our platform's benefits, we
                    kindly ask you to settle your outstanding payment at your
                    earliest convenience.
                    <div
                      style={{
                        lineHeight: "20px",
                        fontWeight: "bold",
                        padding: "10px",
                      }}
                    >
                      Please proceed to make the payment by clicking on the
                      following link: <a href="/pay-now">Subscribe Now</a>
                    </div>
                    Once the payment is successfully processed, your account
                    will be reactivated, and you'll regain access to all
                    features and services. If you have any questions or need
                    assistance with the payment process, please don't hesitate
                    to contact our support team. We're here to help.
                  </div>
                ) : (
                  <>
                    {props?.profile?.showLegacy && (
                      <div
                        className={style.dashboard_pannel_box}
                        style={{
                          border: "2px solid #F2C200",
                          textAlign: "center",
                        }}
                      >
                        <i
                          class="fa fa-warning"
                          style={{ margin: "0 5px", color: "#F2C200" }}
                        ></i>
                        We've noticed that your Nex Pay account is not created
                        , which could affect your payout experience. To ensure
                        seamless transactions and timely payouts, we kindly ask
                        you to create account.
                        <div
                          style={{
                            lineHeight: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          <Button onClick={generateIpayout}>
                            Create Nex Pay
                          </Button>
                        </div>
                      </div>
                    )}
                    {props.profile && (
                      <Profiles
                        profile={props.profile}
                        lodervalue={props.tiles_loading}
                      />
                    )}
                    {props.extra_data && (
                      <Tile
                        pvData={props.extra_data}
                        SponserData={props.sponser_details}
                        Tiles={props.tiles}
                        filterChange={props.filterChange}
                        lodervalue={props.tiles_loading}
                      />
                    )}
                    <div className={style.dash_banner_news_sec} style={
                      {
                        marginBottom:'5px',
                        marginTop:'0px'
                      }
                    }>
                      <>
                        {props.news && (
                          <News
                            news={props.news}
                            filterChange={props.chartfilterChange}
                            lodervalue={props.joining_loading}
                          />
                        )}
                        {props.joining_graph_data_new && (
                          <Charts
                            data={props.joining_graph_data_new}
                            filterChange={props.chartfilterChange}
                            lodervalue={props.joining_loading}
                          />
                        )}
                      </>
                    </div>
                    <div className={style.dash_banner_news_sec}>
                      <>
                        {props.rank && <Ranks rank={props.rank} />}
                        <DownlineWall customers={props.customers} />
                      </>
                    </div>

                    <div className={style.dash_member_join_rank_sec}>
                      <>
                        {props.new_members && (
                          <Members
                            memberList={props.new_members}
                            lodervalue={props.joining_loading}
                          />
                        )}
                        {props.orders && (
                          <RecentOrders
                            orders={props.orders}
                            // filterChange={props.chartfilterChange}
                            // lodervalue={props.joining_loading}
                          />
                        )}
                      </>
                    </div>
                    <div className={style.dashbord_earning_expence}>
                      {props.team_perfomance && (
                        <TeamPerformance
                          topEarners={
                            props.team_perfomance &&
                            props.team_perfomance.top_earners
                          }
                          topRecruters={
                            props.team_perfomance &&
                            props.team_perfomance.top_recruiters
                          }
                          packageoverviews={
                            props.team_perfomance &&
                            props.team_perfomance.package_overview
                          }
                          rankOverviews={
                            props.team_perfomance &&
                            props.team_perfomance.rank_overview
                          }
                          lodervalue={props.earnings_loading}
                          data={props}
                        />
                      )}

                      {props.earnings_nd_expenses && (
                        <EarningsTab
                          lodervalue={props.earnings_loading}
                          income={
                            props.earnings_nd_expenses &&
                            props.earnings_nd_expenses.incomes
                          }
                          expenses={
                            props.earnings_nd_expenses &&
                            props.earnings_nd_expenses.expenses
                          }
                          payoutstatus={
                            props.earnings_nd_expenses &&
                            props.earnings_nd_expenses.payout_statuses
                          }
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <DashboardModel show={props.modal.show} title={props.modal.title}>
        {!props.continue && <AddForm {...props}></AddForm>}
        {props.continue && <VerificationForm {...props}></VerificationForm>}
      </DashboardModel>
    </Container>
  );
}

export default MainContent;
