import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import style from "./payment.module.scss";
import ReplicaSiteService from "../../../service/replica/replica";

export default function NowPaymentSuccess() {

  const { t } = useTranslation()
  const history = useHistory();
  const params = useParams();
  const { orderId } = params;

  const [status, setStatus] = useState(true)
  const [loader, setLoader] = useState(true)
  const [retryCount, setRetryCount] = useState(5)
  const [message, setMessage] = useState((t("nowPayment.transaction_pending")))

  const service = new ReplicaSiteService()

  const checkStatus = () => {
    if (retryCount > 0) {
      service.getNowPaymentStatus(orderId).then((res) => {
        if (res.status) {
          if (res.data.status == 1) {
            handleSuccess()
          } else if (res.data.status == 0) {
            setTimeout(() => {
              setRetryCount(prev => prev - 1)
            }, 2000);
          } else
            if (res.data.status == "invalid") {
              setStatus(false)
              handleFailure("nowPayment.invalid_order_id")
            }
        }
      })
    } else {
      handleFailure("nowPayment.limit_exceeded")
    }

  }
  const handleFailure = (reason) => {
    setMessage(t(reason))
    setLoader(false)
  }
  const handleSuccess = () => {
    setMessage(t("nowPayment.transaction_completed"))
    setStatus(true)
    setLoader(false)
    setTimeout(() => {
      history.push('/dashboard')
    }, 3000);
  }
  const resetRetry = () => {
    setRetryCount(5)
    setMessage(t("nowPayment.transaction_pending"))
    setLoader(true)
  }

  useEffect(() => {
    checkStatus()
  }, [retryCount])

  return (
    <>
      {" "}
      <div className={`h-100`}>
        <div className={style.MainContainer}>
          <Card style={{ padding: "10px", height: "300px" }}>
            <div style={{ textAlign: "center", padding: "30px" }}>
              <div style={{ color: status ? "green" : "red" }}>
                <h3>{status ? t("nowPayment.payment_complete") : t("nowPayment.payment_invalid")}</h3>{" "}
              </div>
              <br />
              <strong>
                <div>{`Order Id : ${orderId}`}</div>
              </strong>
              <br />
              <div>{loader && <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />} {message}</div><br />
              {!status && retryCount <= 0 && <Button
                color="primary"
                className={`btn-pill ${style.NextButton}`}
                onClick={resetRetry}
              >
                {t("nowPayment.check_button")}
              </Button>}
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
