import React from 'react';
import style from './notfount.module.scss';
import {Link} from 'react-router-dom';
function NotFount() {
    return (
        <div className={style.notFount}>
            <div className={style.notFountContent}>
                <div className={style.notfount404}>
                    {/* <h1>404</h1> */}
                </div>
                <h2>We are sorry, Page not found <span>!</span> </h2>
                <p>The page you are looking for might have been removed or is temporarily unavailable.</p>
                <Link to="/dashboard" className={style.backToDashboard}>
                    <i className="fa fa-backward"></i>
                      &nbsp; Back To Dashboard
                </Link>
            </div>
        </div>
    )
}

export default NotFount
