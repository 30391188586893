import { useEffect ,useState} from 'react';
import Helmet from 'react-helmet';
import { withTranslation }  from 'react-i18next';
import { withRouter } from 'react-router';
import PageTitle from '../../components/common/pageTitle';
import InviteService from '../../../service/tools/invites';
import InviteContent from '../../components/tools/InviteContent';
import companyName from '../../../store/mobxStore/companyName';

const FieldSet = [
    {
        code : 'to',
        value : '',
        type : 'text',
        required : true,
        fieldname : 'to'
    },
    {
        code : 'subject',
        value : '',
        type : 'text',
        required : true,
        fieldname : 'subject'
    },
    {
        code : 'message',
        value : '',
        type : 'textarea',
        required : true,
        fieldname : 'message'
    },
  
]


function EditInvite(props){
    let service = new InviteService();

    const [state, setState] = useState({

        field : FieldSet,
        alert : {
            show : false,
            variant : '',
            message : ''
        },
        buttonloder:false
 
    })
    useEffect(() => {
        // const {state} = props.location;
        const {id} = props.match.params;
           service.getSocialEmails()
            .then(res => {
                if(res.status) {

                    const data = res.data.social_emails.find(element=> element.id ==id );
                    const TempData = state.field.map(item=>{
                        if(item.code=='subject'){
                            return {
                                ...item,
                                value:data.subject
                            }

                        }
                        if(item.code=='message'){
                            return {
                                ...item,
                                value:data.content
                            }

                        }
                        return {
                            ...item
                        }
                })
                setState(prev=>({
                    ...prev,
                    field  : TempData
                }))
            
                }else{
                if(res.error.code === 1002){
                    props.history.push('/logout')
                }else if(res.error.code === 1057){
                    props.history.push({
                    pathname: '/dashboard',
                    state: {
                        error: true,
                        message : 'permissionDenied'
                    }
                    })
                }
                }
            });

    }, []);

    //onChange handler 
    const  chacngeHandler = (e) =>{
        const {name,value} = e.target;
        const TempData = state.field.map(item=>{
            if(item.code === name){
                return{
                    ...item,
                    value : value,
                    error : null
                }
            }
            return {
                ...item
            }
        });
        setState(prev=>({
            ...prev,
            field  : TempData
        }))
     }
 

    const submit = (e) =>{
        e.preventDefault();
        let isvalid = true;
        const validate = state.field.map((item,index)=>{
            if(!item.value){
                isvalid = false;
                return {
                    ...item,
                    error : {
                        error : 'validation.required',
                        field : {
                            field : item.code
                        }
                    }
                }
            }
            if(item.code=='to'){
                var regexPattern=new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
                var result = item.value.replace(/\s/g, "").split(/,|;/);
                for(var i = 0;i < result.length;i++) {
                    if(!regexPattern.test(result[i])) {  
                    isvalid = false;
                    return {
                        ...item,
                        error : {
                            error : 'validation.invalidEmail',
                            field : {
                                field : item.code
                            }
                        }
                    }
                        
                }
            }

            }
            
          
            return{
                ...item
            }


            
        });

        if(isvalid){

                const formdata = {};
                state.field.map((item)=>{
                    if(item.fieldname=='to'){
                        formdata['to_mail_id'] = item.value;
                    }
                    else {
                        formdata[item.fieldname] = item.value;
                    }
           
                    return true
                })
                setState(prev=>({
                    ...prev,
                    buttonloder:true,
                }))
                service.editInvite(formdata).then(res=>{
                    if(res.status){
                        setState(prev=>({
                            ...prev,
                            alert : {
                                show : true,
                                message : 'validation.invitaionSendSuccessfully',
                                variant : 'success',
                                error:false
                            },
                            buttonloder:false,
                        }))
                    }else{
                        if(res.error.code === 1002){
                            props.history.push('/logout')
                        }else if(res.error.code === 1060){
                            setState(prev=>({
                                ...prev,
                                alert : {
                                    show : true,
                                    message : 'validation.unableToSendInvitation',
                                    variant : 'danger',
                                    error:true
                                },
                                buttonloder:false,
                            }))
                           
                        }
                    }
                })
             
            
           

        }else{
            setState(prev=>({
                ...prev,
                field : validate,
                alert : {
                    show : true,
                    message : 'validation.checkValueYouHaveSubmitted',
                    variant : 'danger'
                },
                buttonloder:false
            }))
        }
       
    }
    const closeAlert = () =>{
        setState(prev=>({
            ...prev,
            alert : {
                show : false,
                message : '',
                variant : ''
            }
        }))
    }

     
         const {t} = props;
         return (
             <div className="h-100">
                 <Helmet>
                     <title>{companyName.companyName} | {t('Sidemenu.emailInvite')}</title>
                 </Helmet>
                 <PageTitle 
                     title="emailInvite"  
                     buttonOn={false}  
                 />
                 <InviteContent 
                     {...state}
                     chacngeHandler={chacngeHandler}
                     formSubmit={submit}
                     closeAlert={closeAlert}
                 />
             </div>
         )
}

         
export default withTranslation()(withRouter(EditInvite))