import React, { useState, useRef, useCallback, useMemo } from "react";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "bootstrap-daterangepicker/daterangepicker.css";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
import style from "./ewallet.module.scss";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import UserEarningsPrintView from "./UserEarningsPrintView";
import ReactExport from "react-data-export";
import { AgGridReact } from "ag-grid-react";
// import "ag-grid-enterprise";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-quartz.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function PeriodFilter({
  filterHandler,
  type,
  resetHandler,
  isSingle,
  ContentData,
  loader,
  button_group,
  filterDates,
  filterPeriods,
  onPeriodChanges,
  submitSearch,
  serachLoder,
  resetSearch,

  ...props
}) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const componentRef = useRef();
  const [state, setState] = useState({
    selectedValues: [],
    start: moment().startOf("month"),
    end: moment(),
    label:
      moment().startOf("month").format("MMMM D, YYYY") +
      " - " +
      moment().format("MMMM D, YYYY"),
  });
  const { start, end, label, selectedValues } = state;
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState();

  //customselect options
  const options = {
    Today: [moment().toDate(), moment().toDate()],
    "This Week": [moment().startOf("week"), moment().endOf("week").toDate()],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "This Year": [
      moment().startOf("year").toDate(),
      moment().endOf("year").toDate(),
    ],
  };

  //datepicker select
  const handleCallback = (start, end) => {
    setState((prev) => ({
      ...prev,
      start: start,
      end: end,
      label: start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"),
    }));
  };

  // multi select css components
  let styles = {
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      minWidth: "10%",
      maxWidth: "max-content",
      display: "inline-block",
    },
    inputField: {
      // To change input field position or margin
      margin: "5px",
      width: "40px",
    },
    chips: {
      // To change css chips(Selected options)
      background: "rgb(228, 228, 228)",
      color: "rgb(88, 102, 110)",
      borderRadius: "10px",
      height: "20px",
    },
    optionContainer: {
      // To change css for option container
      boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
      border: "none",
    },
    searchBox: {
      // To change search box element look
      padding: 0,
      marginRight: "5px",
      paddingLeft: "5px",
    },
    option: {
      // To change css for dropdown options
      color: "black",
    },
  };
  //on select the multi select
  const selecthandler = (e) => {
    setState((prev) => ({
      ...prev,
      selectedValues: e,
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
  };

  //remove selected items

  const removeHandler = (e) => {
    setState((prev) => ({
      ...prev,
      selectedValues: e,
      label:
        moment().startOf("month").format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY"),
    }));
  };

  //reset button click
  const resetValue = () => {
  resetSearch();
    
  };

  const submitHandler = () => {
    submitSearch();
  };
  const multiDataSet1 = [
    {
      columns: [
        { title: "Headings", width: { wpx: 80 } },
        { title: "Text Style", width: { wch: 40 } },
        { title: "Colors", width: { wpx: 90 } },
      ],
      data: [
        [
          { value: "H1", style: { font: { sz: "24", bold: true } } },
          { value: "Bold", style: { font: { bold: true } } },
          {
            value: "Red",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
        ],
        [
          { value: "H2", style: { font: { sz: "18", bold: true } } },
          { value: "underline", style: { font: { underline: true } } },
          {
            value: "Blue",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } },
            },
          },
        ],
        [
          { value: "H3", style: { font: { sz: "14", bold: true } } },
          { value: "italic", style: { font: { italic: true } } },
          {
            value: "Green",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } },
            },
          },
        ],
        [
          { value: "H4", style: { font: { sz: "12", bold: true } } },
          { value: "strike", style: { font: { strike: true } } },
          {
            value: "Orange",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFF86B00" } },
            },
          },
        ],
        [
          { value: "H5", style: { font: { sz: "10.5", bold: true } } },
          { value: "outline", style: { font: { outline: true } } },
          {
            value: "Yellow",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
            },
          },
        ],
        [
          { value: "H6", style: { font: { sz: "7.5", bold: true } } },
          { value: "shadow", style: { font: { shadow: true } } },
          {
            value: "Light Blue",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
            },
          },
        ],
        // Add an extra row with an empty label to merge the entire row
        [
          { value: "", style: {} },
          { value: "", style: {} },
          { value: "", style: {} },
        ],
      ],
    },
  ];
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Group A",
      children: [
        { field: "athlete", minWidth: 200 },
        { field: "country", minWidth: 200 },
      ],
    },
    {
      headerName: "Group B",
      children: [
        { field: "sport", minWidth: 150 },
        { field: "gold" },
        { field: "silver" },
        { field: "bronze" },
        { field: "total" },
      ],
    },
  ]);
  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      minWidth: 100,
      flex: 1,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        setRowData(data);
      });
  }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  return (
    <div className={style.filterSection}>
      <select
        className={`${
          currentTheme == "theme-dark"
            ? `${style.dark_mode_DateRangePicker}`
            : `${style.DateRangePicker}`
        }`}
        onChange={onPeriodChanges}
      >
        {filterPeriods &&
          filterPeriods.map((objPeriods, index) => {
            return (
              <>
                <option
                  value={objPeriods.period}
                  key={index}
                >{`${objPeriods.start_desc} -  ${objPeriods.end_desc}`}</option>
              </>
            );
          })}
      </select>

      <div className={style.filterButton}>
        <Button
          variant="info"
          className={style.padding5}
          onClick={submitHandler}
          disabled={serachLoder}
        >
          {serachLoder ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )}

          {t("Button.search")}
        </Button>
        <Button
          className={style.padding5}
          onClick={resetValue}
          disabled={loader?.EarningsTable.reset}
        >
          {loader && loader.EarningsTable.reset ? (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          ) : (
            ""
          )}
          {t("Button.reset")}
        </Button>
      </div>

      <div className={style.exportButton}>
        {/* <div>
          <button
            onClick={onBtExport}
            style={{ marginBottom: "5px", fontWeight: "bold" }}
          >
            Export to Excel
          </button>
        </div> */}
        <div className="grid-wrapper">
          <div style={gridStyle} className={"ag-theme-quartz-dark"}>
            {/* <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
            /> */}
          </div>
        </div>
        {/* <div>
          <ExcelFile element={<button>Download Data With Styles</button>}>
            <ExcelSheet dataSet={multiDataSet1} name="Organization">
              <ExcelColumn label="" value="0" mergeAcross={3} />
            </ExcelSheet>
          </ExcelFile>
        </div> */}

        {/* <Button
       
          onClick={() => {
            props.exportData(
              start.format("YYYY-MM-DD"),
              end.format("YYYY-MM-DD"),
              selectedValues,
              "excel"
            );
          }}
        >
   

          {t("Button.excel")}
        </Button> */}
        {/* <Button

          onClick={() => {
            props.exportData(
              start.format("YYYY-MM-DD"),
              end.format("YYYY-MM-DD"),
              selectedValues,
              "csv"
            );
          }}
        >

          {t("Button.CSV")}
        </Button> */}
        {/* <ReactToPrint
          trigger={() => <Button>{t("Button.print")}</Button>}
          content={() => componentRef.current}
        /> */}

        <div style={{ display: "none" }}>
          <UserEarningsPrintView
            ref={componentRef}
            ContentData={ContentData}
            startDate={start.format("YYYY-MM-DD")}
            endDate={end.format("YYYY-MM-DD")}
            filterValues={selectedValues}
          />
        </div>
      </div>
    </div>
  );
}

export default PeriodFilter;
