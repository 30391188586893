import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import AppInfoservice from "../../../service/common/Appinfo";
import Spinner from "../../shared/Spinner";
import { useParams } from "react-router";
import { useHistory } from "react-router";

const DthreeView = () => {

  const {type} = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    loader: true,
  });
  useEffect(() => {
    const service = new AppInfoservice();
    service.getStoreUrl(type).then((res)=>{
      if(res.status){
        setState((prevState) => ({
          ...prevState,
          loader: false,
        }));
        window.location.href = res.data.url;
      }
      else{

      }
    })
   
  }, []);

  return (
    <div>
  {state.loading ? (
        <Spinner />
      ) : (<></>)}
    </div>
  );
};

export default withTranslation()(withRouter(DthreeView));

// didnt done with previous state (state update)
