import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import style from "./party.module.scss";
import commonStyle from "../common/Common.module.scss";
import { useTranslation } from "react-i18next";
// import { IMG_URL } from '../../../configuration/apiconfig';
function StepThree(props) {
  const { t } = useTranslation();
  return (
    <Card className={`${style.Card}`}>
      <Card.Body className={`${style.CardBody}`}>
        <legend>
          <span>{t("Common.stepThree_EnterTheDateAndTimeOftheParty")}</span>
        </legend>
      </Card.Body>
      <Row style={{ padding: "15px" }}>
        <Col sm={3}>
          <Form.Group>
            <Form.Label className={`${commonStyle.required}`}>
              {t("Common.Start_date")}
            </Form.Label>
            <span className={style.datePicker}>
              <Form.Control
                type="date"
                name="from"
                className={`${style.datePickerInput} ${commonStyle.formsInputField}`}
                value={props.partyContent.from}
                onChange={props.changeHandler}
              />
            </span>
            {props.partyContent.FormError.from && (
              <span
                className={`${
                  props.partyContent.FormError.from.error
                    ? style.isInvalid
                    : "d-none"
                }`}
              >
                {t(props.partyContent.FormError.from.error, {
                  field: "Start_date",
                })}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label className={`${commonStyle.required}`}>
              {t("Common.Start_time")}
            </Form.Label>
            <span className={style.datePicker}>
              <Form.Control
                type="time"
                name="startTime"
                className={`${style.datePickerInput} ${commonStyle.formsInputField}`}
                value={props.partyContent.startTime}
                onChange={props.changeHandler}
                min="00:00"
                max="23:59"
                step="60"
              />
              {props.partyContent.FormError.startTime && (
                <span
                  className={`${
                    props.partyContent.FormError.startTime.error
                      ? style.isInvalid
                      : "d-none"
                  }`}
                >
                  {t(props.partyContent.FormError.startTime.error, {
                    field: "Start_date",
                  })}
                </span>
              )}
            </span>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label className={`${commonStyle.required}`}>
              {t("Common.End_date")}
            </Form.Label>
            <span className={style.datePicker}>
              <Form.Control
                type="date"
                name="to"
                className={`${style.datePickerInput} ${commonStyle.formsInputField}`}
                value={props.partyContent.to}
                onChange={props.changeHandler}
              />
            </span>
            {props.partyContent.FormError.to && (
              <span
                className={`${
                  props.partyContent.FormError.to.error
                    ? style.isInvalid
                    : "d-none"
                }`}
              >
                {t(props.partyContent.FormError.to.error, {
                  field: "End_date",
                })}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label className={`${commonStyle.required}`}>
              {t("Common.End_time")}
            </Form.Label>
            <span className={style.datePicker}>
              <Form.Control
                type="time"
                name="endTime"
                className={`${style.datePickerInput} ${commonStyle.formsInputField}`}
                value={props.partyContent.endTime}
                onChange={props.changeHandler}
                min="00:00"
                max="23:59"
                step="60"
              />
            </span>
            {props.partyContent.FormError.endTime && (
              <span
                className={`${
                  props.partyContent.FormError.endTime.error
                    ? style.isInvalid
                    : "d-none"
                }`}
              >
                {t(props.partyContent.FormError.endTime.error, {
                  field: "End_time",
                })}
              </span>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
}

export default StepThree;
