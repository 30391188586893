import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import NewsTicker from "react-advanced-news-ticker";
import Image from "../../../assets/images/nophoto/no_photo.jpg";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Modal, Typography } from "@mui/material";
import NewmembersModal from "./NewmembersModal";
function DownlineWall(props) {
  const curr = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);
  const navigation = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const onclick = () => {
    setOpen(true);
  };
  return (
    <div className={style.dashboard_new_member_sec}>
      <div
        className={`${
          currentTheme == "theme-dark"
            ? style.dark_mode_dashboard_pannel_box
            : style.dashboard_pannel_box
        }`}
      >
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className={`${
            currentTheme == "theme-dark"
              ? style.dark_mode_dashboard_new_member_head
              : style.dashboard_new_member_head
          }`}
        >
          {t("Dashboard.downlinecustomerwall")}

          <button
            className={style.readmore_btn}
            style={{ background: "none" }}
            onClick={() => {
              navigation.push("/my-customers");
            }}
          >
            <span>View all </span>
          </button>
        </div>

        <NewmembersModal show={open} />

        <div>
          {props.customers?.length === 0 ? (
            <div
              className={`${style.NodataImage}`}
              style={{ textAlign: "center" }}
            >
              <img src={nodata} alt="nodata" style={{ width: "50%" }} />
            </div>
          ) : (
            props.customers?.map((item) => (
              <div className={style.dash_order} key={item.id}>
                <div className={style.iconorder}>
                  <img
                    src={Image}
                    alt="No Image"
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "40%",
                    }}
                    className={style.imgpointer}
                    onClick={onclick}
                  />
                  {/* <i className="fa fa-user" aria-hidden="true" style={{ color: "#89CFF0" }}></i> */}
                </div>
                <div className={style.orderContent}>
                  <h3>{item.first_name}</h3>
                  <span>
                    {moment(item.date_of_purchase).format(
                      "MMMM DD, YYYY, hh:mm a"
                    )}
                  </span>
                </div>
                <div className={style.orderprice}>
                  <h3 style={{ color: "#006A4E" }}>{item.bv} PBV </h3>
                </div>
              </div>
            ))
          )}
        </div>
        {/* <button
          style={{
            borderRadius: "0 0 20px 20px",
            color: currentTheme == "theme-dark" ? "#ffffff" : "#3B444B",

            width: "100%",
            height: "auto",
            float: "left",
            position: "absolute",
            left: "0",
            bottom: "0",
            borderRadius: "10px",
            padding: "5px",
            transition: "background 0.3s ease",
            cursor: "pointer",
            border: "none",
            background: currentTheme == "theme-dark" ? "#112237" : "#ffff",
            boxShadow: "0px 9px 21px rgba(0, 0, 0, 0.03)",
          }}
          onClick={() => {
            navigation.push("/my-customer-wall");
          }}
        >
          <i
            style={{ fontSize: 20 }}
            class="fa fa-angle-double-down"
            aria-hidden="true"
          ></i>
        </button> */}
      </div>
    </div>
  );
}

export default DownlineWall;
