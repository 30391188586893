import React from 'react';
import {Card,Table,Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import style from './cart.module.scss';
import {useSelector} from 'react-redux';

function Summary(props) {
    const {t} = useTranslation();
    const currency = useSelector(state=>state.curr)
    //calculate the total amount
    const calcTotal = (amount,quantity) =>{
        return (currency.value * amount ).toFixed(currency.precision)
    }
    const TotalAmount =()=> {
        let totalPrice = props.data.reduce(function (accumulator, item) {
            return accumulator + (item.price);
          }, 0);
          return (totalPrice * currency.value).toFixed(currency.precision)
    }
    return (
        <Card className={style.PackageCard}>
            <Card.Body className={style.PackageCardBody}>
                <Table responsive striped bordered >
                    <thead>
                        <tr>
                            <th className={`${style.w3}`}>
                                #
                            </th>
                            <th>
                                {t('profile.product')}
                            </th>
                            <th>
                                {t('Common.quantity')}
                            </th>
                            <th>
                                {t('Common.total')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.data && props.data.map((items,index)=>(
                                <tr key={index}>
                                    <td>
                                        {index+1}
                                    </td>
                                    <td>
                                        <span>{items.name}</span>
                                    </td>
                                    <td>
                                        {items.qty}
                                    </td>
                                    <td>
                                        {currency.currentCurr} {calcTotal(items.price,items.qty)}
                                    </td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td colSpan="3" className={`text-right`}>
                                <b>{t('Common.total')}</b>
                            </td>
                            <td>
                                <b>{currency.currentCurr} {TotalAmount()}</b>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
            {
                props.FooterBtn&&
                <Card.Footer>
                <div className={style.wizardButton}>
                    <Button   style={{marginRight:'10px'}}
                        className={`${style.btnAddonInfo}`}
                        onClick={props.prev}
                        >
                        {t('Button.back')}
                    </Button>
                    <Button 
                        className={` ${style.btnPrimary}`} 
                        onClick={props.next}
                        >
                        {t('Button.continue')}
                    </Button>
                </div>
                </Card.Footer>
            }
        </Card>   
    )
}

export default Summary
 